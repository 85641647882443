(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.SearchPhoneModalController
     *
     * @description
     * Contoller - SearchPhoneModalController
     * 
     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
     */
    angular
        .module("locksmithManager")
        .controller('SearchPhoneModalController', SearchPhoneModalController);
    /**
     * $injector
     * lets inject dependecies
     * @type {Array}
     */
    SearchPhoneModalController.$injector = ['toaster', '$modalInstance', 'PhoneService', 'STATES', 'ModalFactory', 'Spinner'];
    /**
     * SearchPhoneModalController Create a class to encapsulation
     */
    function SearchPhoneModalController(toaster, $modalInstance, PhoneService, STATES, ModalFactory, Spinner) {
        /**
         * self Holds current instance of Class
         * @private {[Object]}
         */
        var self = this,
            FILTER = {
                'zipcode': '',
                'state': '',
                'contains': '',
                'areacode': ''
            };

        self.searchCriteria = angular.copy(FILTER);
        self.states = angular.copy(STATES);
        self.phoneNumbers = [];
        self.closeModal = closeModal;
        self.cancelModal = cancelModal;
        self.searchPhoneNumbers = searchPhoneNumbers;
        self.clearFilter = clearFilter;
        self.confirmBuy = confirmBuy;

        constructor();
        /**
         * constructor function which is called before class is intantiated
         */
        function constructor() {}

        function closeModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancelModal');
        }

        function clearFilter() {
            angular.extend(self.searchCriteria, angular.copy(FILTER));
        }

        function searchPhoneNumbers() {
            Spinner.start();
            PhoneService.searchPhoneNumbers(self.searchCriteria).then(function(response) {
                self.phoneNumbers.length = 0;
                Array.prototype.push.apply(self.phoneNumbers, response.data);
                Spinner.stop();
            }, function(error) {
                toaster.pop('error', "Error", 'Some error found while searching phone number');
                Spinner.stop();
            });
        }

        function confirmBuy(phone) {
            var options = {
                'locale': {
                    'header': 'Please confirm',
                    'body': 'Are you sure you want to buy ' + phone.phone_number + ' charges may apply',
                    'yes': '<i class="fa fa-shopping-cart fa-fw"></i> Yes, want to buy',
                    'cancel': 'No'
                }
            };
            ModalFactory.confirm(options).then(function(successData) {
                Spinner.start();
                PhoneService.buyPoolNumberForPeople({
                    phonenumber: phone.phone_number
                }).then(function(response) {
                    toaster.pop('success', "Success", phone.phone_number + ' was purchased successfully');
                    closeModal();
                    Spinner.stop();
                }, function(error) {
                    toaster.pop('error', "Error", 'Some error found while buying phone number');
                    Spinner.stop();
                });
            });
        }
    }
})();
