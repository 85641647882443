(function () {
  "use strict";
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.AuthService
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('AuthService', [
      '$http', 'store', 'jwtHelper',
      function ($http, store, jwtHelper) {
        var model = this,
          setUser,
          resetUser,
          setToken,
          getToken,
          tokenRetrival = false,
          resetToken;

        model.roles = {
          ADMIN_ROLE: 0,
          TECH_ROLE: 1,
          SALES_ROLE: 2,
          DISPATCH_ROLE: 3
        };

        model.token_obj = {
          token: "",
          token_exp: ""
        };

        model.user = {
          id: "",
          name: "",
          phone: "",
          email: "",
          role: "",
          active: "",
          agent_status: "",
          limited_user: 0
        };

        model.getToken = function () {

          if (model.token_obj.token) {
            return model.token_obj.token;
          }
          else {
            return store.get('lsmToken');
          }
        };

        model.getUser = function () {

          if (model.user.id) {
            return model.user;
          }
          else {
            model.user = store.get('lsmUser') || model.user;
            return model.user;
          }
        };

        model.setUser = function (user) {
          model.user.id = user.id;
          model.user.name = user.name;
          model.user.phone = user.phone;
          model.user.email = user.email;
          model.user.role = user.role;
          model.user.active = user.active;
          model.user.agent_status = user.agent_status;
          store.set('lsmUser', user);
        };

        model.resetUser = function () {
          model.user.id = "";
          model.user.name = "";
          model.user.phone = "";
          model.user.email = "";
          model.user.role = "";
          model.user.active = "";
          model.user.agent_status = "";
          store.remove('lsmUser');
        };

        model.setToken = function (token) {
          if (!token) {
            return;
          }
          model.token_obj.token = token;
          model.token_obj.token_exp = jwtHelper.decodeToken(token);
          store.set('lsmToken', token);

        };

        model.resetToken = function () {
          model.token_obj.token = "";
          model.token_obj.token_exp = "";
          store.remove('lsmToken');
        };

        model.authenticate = function (auth_obj) {
          return $http({
            'method': "POST",
            'url': "/auth/login",
            'data': auth_obj,
            'skipAuthorization': true
          }).then(function (data) {
            model.setToken(data.data.token);
            model.setUser(data.data.user);
          });
        };

        model.getUserFromToken = function (token) {
          setToken(token);
          var req = {
            method: "GET",
            url: "/auth/user",
            headers: {
              'Authorization': 'Bearer ' + token
            },
            data: {}
          };
          return $http(req).then(function (data) {
            model.setUser(data.data.user);
          });
        };

        model.refreshToken = function (token) {
          tokenRetrival = true;
          var req = {
            method: 'POST',
            url: '/auth/refresh',
            headers: {
              'Authorization': 'Bearer ' + token
            },
            'skipAuthorization': true,
            data: {}
          };

          return $http(req).then(function (data) {
            model.setToken(data.data.token);
            tokenRetrival = false;
          });
        };

        model.logout = function () {

          var req = {
            method: "POST",
            url: "/auth/logout",
            headers: {
              Authorization: "Bearer " + model.getToken()
            },
            skipAuthorization: true,
            data: {}
          };

          if (model.isLoggedIn()) {
            $http(req);
          }

          model.resetUser();
          model.resetToken();

          window.location.reload(true);

        };


        model.isLoggedIn = function () {

          var token = model.getToken();
          if (token == null) {
            return false;
          }

          // check if token is expired
          if (jwtHelper.isTokenExpired(token)) {
            return false;
          }

          return true;
        };
        /**
         * isAdmin Returns true flag for Administartor user
         * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype ::
         *   mohan.singh42 )
         * @return {Boolean}
         */
        model.isAdmin = function () {
          var loggedUser = store.get("lsmUser");
          return (loggedUser !== null) ? (loggedUser.role === model.roles.ADMIN_ROLE) : false;
        };

        model.isLimitedSales = function () {
          var loggedUser = store.get("lsmUser");
          return (loggedUser !== null) ? (loggedUser.limited_user === 1) : false;
        };

        model.isTechnician = function () {
          var loggedUser = store.get("lsmUser");
          return (loggedUser !== null) ? (loggedUser.role === model.roles.TECH_ROLE) : false;
        };

        model.isSales = function () {
          var loggedUser = store.get("lsmUser");
          return (loggedUser !== null) ? (loggedUser.role === model.roles.SALES_ROLE) : false;
        };

        model.isDispatcher = function () {
          var loggedUser = store.get("lsmUser");
          return (loggedUser !== null) ? (loggedUser.role === model.roles.DISPATCH_ROLE) : false;
        };

      }
    ]);
})();
