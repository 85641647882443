(function () {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('CallbackNotesController', notesController);

  notesController.$inject = ['NotesModel', '$stateParams', '$pusher', 'AuthService', 'toaster', '$scope'];

  /* @ngInject */
  function notesController(NotesModel, $stateParams, $pusher, AuthService, toaster, $scope) {
    /* jshint validthis: true */
    var vm = this,
      id = $stateParams.callbackId;

    var client = new Pusher(window.lm.pusher, {
      authEndpoint: '/pusher/auth?token=' + AuthService.token_obj.token
    });
    var pusher = $pusher(client);


    vm.newNoteText = '';

    vm.activate = activate;
    vm.addNote = addNote;

    ////////////////

    function activate(notes) {
      vm.notes = notes;
    }

    function addNote() {
      if(vm.newNoteText === '') return;
      vm.sendNote = NotesModel.createNoteForCallback(
        id, {
          note: vm.newNoteText
        }
      ).then(function () {
          vm.newNoteText = '';
          NotesModel.getNotesForCallback(id);
        });
    }

    // Pusher Notifications
    var job_notifications_channel = pusher.subscribe('callback_notifications_' + id);
    job_notifications_channel.bind_all(function (event, data) {
      // Listen to any push regards a job, and whenever it comes refresh the history.

      switch(event) {
        case 'note-posted':
          // only pop a notification if the current user is not the one who
          // created the new message.
          if(AuthService.user.id !== data.user_id) {
            vm.notes.data.unshift(data);
            toaster.pop('success', "New Note", data.note+", Scroll down to see more information.", 10000);
          }
          break;
      }
    });

    // Cleanup stuff...
    $scope.$on('$destroy', function () {
      pusher.unsubscribe('callback_notifications_' + id);
    });
  }
})();