(function () {
  'use strict';
  /**
   * @ngdoc Directive
   * @name locksmithManager.Directive.ngSpinnerBar
   *
   * @description
   * Route State Load Spinner(used on page or content load)
   */
  angular
    .module("locksmithManager")
    .directive('ngSpinnerBar', [
      '$rootScope',
      function ($rootScope) {
        return {
          link: function (scope, element, attrs) {
            // by defult hide the spinner bar
            // element.addClass('hide'); // hide spinner bar by default
            $rootScope.loadingSpinner = false;

            // display the spinner bar whenever the route changes(the content part started loading)
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
              if (fromState.name == "login" || toState.name == "login") {
                return false;
              }
              // element.removeClass('hide'); // show spinner bar
              $rootScope.loadingSpinner = true;
            });

            // hide the spinner bar on rounte change success(after the content loaded)
            $rootScope.$on('$stateChangeSuccess', function () {
              // element.addClass('hide'); // hide spinner bar
              $rootScope.loadingSpinner = false;
              $('body').removeClass('page-on-load'); // remove page loading indicator
              Layout.setSidebarMenuActiveLink('match'); // activate selected link in the sidebar menu

              // auto scorll to page top
              // setTimeout(function() {
              //   Metronic.scrollTop(); // scroll to the top on content load
              // }, $rootScope.settings.layout.pageAutoScrollOnLoad);
            });

            // handle errors
            $rootScope.$on('$stateNotFound', function () {
              // element.addClass('hide'); // hide spinner bar
              $rootScope.loadingSpinner = false;
            });

            // handle errors
            $rootScope.$on('$stateChangeError', function () {
              // element.addClass('hide'); // hide spinner bar
              $rootScope.loadingSpinner = false;
            });
          }
        };
      }
    ]);

  /**
   * @ngdoc Directive
   * @name locksmithManager.Directive.a
   *
   * @description
   * Handle global LINK click
   */
  angular
    .module("locksmithManager")
    .directive('a', function () {
      return {
        restrict: 'E',
        link: function (scope, elem, attrs) {
          if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
            elem.on('click', function (e) {
              e.preventDefault(); // prevent link click for above criteria
            });
          }
        }
      };
    });

  /**
   * @ngdoc Directive
   * @name locksmithManager.Directive.dropdownMenuHover
   *
   * @description
   * Handle Dropdown Hover Plugin Integration
   */
  angular
    .module("locksmithManager")
    .directive('dropdownMenuHover', function () {
      return {
        link: function (scope, elem) {
          elem.dropdownHover();
        }
      };
    });

})();