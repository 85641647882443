(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewSettingsController
   *
   * @description
   * Contoller - NewSettingsController
   */
  angular
    .module("locksmithManager")
    .controller('NewSettingsController', [
      '$scope', 'LeadsModel',
      function ($scope, LeadsModel) {
        var newCtr = this;

        newCtr.newLead = {};

        newCtr.create = function () {
          LeadsModel.createLead({
            'lead_title': newCtr.newLead.lead_title,
            'lead_description': newCtr.newLead.lead_description || null
          }).then(function (data) {
            $scope.leadCtr.init();
          });
        };
      }
    ]);
})();