/**
 * Created by nikolay on 3/6/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.PaymentTypesService
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('PaymentTypesModel', [
      '$http', 'toaster',
      function ($http, toaster) {
        var model = this,
          URLS = {
            FETCH: '/api/payment-methods',
            SHOW: '/api/payment-methods/:id',
            CREATE: '/api/payment-methods',
            UPDATE: '/api/payment-methods/:id',
            DELETE: '/api/payment-methods/:id'
          };

        model.paymentTypes = {
          data: []
        };

        model.activePaymentType = {
          id: null,
          name: null,
          processing_percentage_fee: null,
          processing_flat_fee: null
        };

        function throwError(message) {
          toaster.pop('error', "Error", message);
        }

        function extract(result) {
          return result.data;
        }

        function cachePaymentTypes(result) {
          model.paymentTypes.data = extract(result);

          return model.paymentTypes;
        }

        model.setActivePaymentType = function (data) {
          model.activePaymentType.id = data.id;
          model.activePaymentType.name = data.name;
          model.activePaymentType.processing_percentage_fee = data.processing_percentage_fee;
          model.activePaymentType.processing_flat_fee = data.processing_flat_fee;
        };

        model.resetActivePaymentType = function () {
          model.activePaymentType.id = null;
          model.activePaymentType.name = null;
          model.activePaymentType.processing_percentage_fee = null;
          model.activePaymentType.processing_flat_fee = null;
        };

        model.createPaymentType = function (paymentType) {
          return $http.post(URLS.CREATE, paymentType).then(function (data) {
            model.getPaymentTypes();

            toaster.pop('success', "Success", "Payment method Created", 2000);

          }, function error(err, data) {
            throwError('Unable to create a new payment method at this time, please try again later');
          });
        };

        model.updatePaymentType = function (editPaymentType) {
          var payment_type_id = editPaymentType.id;
          return $http.put(URLS.UPDATE.replace(':id', payment_type_id), editPaymentType).then(function (data) {
            model.getPaymentTypes();

            toaster.pop('success', "Success", "Payment method updated", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to update the payment method at this time, please try again later');

          });
        };

        model.deletePaymentType = function (payment_type_id) {
          return $http.delete(URLS.DELETE.replace(':id', payment_type_id)).then(function (data) {

            model.getPaymentTypes();

            toaster.pop('success', "Success", "Payment method deleted", 2000);

          }, function error(err) {
            throwError(extract(err));
          });
        };

        model.getPaymentTypes = function () {
          return $http.get(URLS.FETCH).then(cachePaymentTypes);
        };

        model.getPaymentType = function (payment_type_id) {
          return $http.get(URLS.SHOW.replace(':id', payment_type_id)).then(extract);
        };
      }
    ]);
})();