(function () {
  'use strict';
  angular
    .module("locksmithManager")
    .config([
      '$stateProvider',
      function ($stateProvider) {
        /**
         * Register parent state of settings module
         * Which loads all routes and initial dependencies
         * @True {string}
         */
        $stateProvider

          .state('settings', {
            templateUrl: 'app/views/settings/settings.html',
            data: {
              requiresLogin: true,
              acceptRole: [0]
            },
            abstract: true
          })


          .state('settings.base', {
            url: "/settings/base",
            parents: 'settings',
            templateUrl: "app/views/settings/base/base.html",
            controller: 'BaseSettingsController',
            resolve: {}
          })
          .state('settings.base.leads', {
            url: "/leads",
            parents: 'settings.base',
            templateUrl: "app/views/settings/base/leads.html",
            controller: 'LeadsSettingsController as leadCtr',
            resolve: {}
          })
          .state('settings.base.leads.new', {
            url: "/new",
            parents: 'settings.base.leads',
            templateUrl: "app/views/settings/base/leads/new.html",
            controller: 'NewSettingsController as newCtr',
            resolve: {}
          })
          .state('settings.base.leads.view', {
            url: "/:lead_id",
            parents: 'settings.base.leads',
            templateUrl: "app/views/settings/base/leads/view.html",
            controller: 'ViewSettingsController as viewCtr',
            resolve: {}
          })
          .state('settings.base.leads.edit', {
            url: "/:lead_id/edit",
            parents: 'settings.base.leads.view',
            templateUrl: "app/views/settings/base/leads/edit.html",
            controller: 'EditSettingsController as editCtr',
            resolve: {}
          })

          .state('settings.base.services', {
            url: "/services",
            parents: 'settings.base',
            templateUrl: "app/views/settings/base/services.html",
            controller: 'ServicesController as srvcCtr',
            resolve: {}
          })
          .state('settings.base.services.new', {
            url: "/new",
            parents: 'settings.base.services',
            templateUrl: "app/views/settings/base/services/new.html",
            controller: 'NewController as newCtr',
            resolve: {}
          })
          .state('settings.base.services.view', {
            url: "/:service_id",
            parents: 'settings.base.services',
            templateUrl: "app/views/settings/base/services/view.html",
            controller: 'ViewController as viewCtr',
            resolve: {}
          })
          .state('settings.base.services.type', {
            url: "/:service_id/type/:service_type_id",
            parents: 'settings.base.services',
            templateUrl: "app/views/settings/base/services/service_types/view.html",
            controller: 'TypeController as typeCtr',
            resolve: {}
          })
          .state('settings.base.services.newType', {
            url: "/:service_id/newType",
            parents: 'settings.base.services',
            templateUrl: "app/views/settings/base/services/service_types/new.html",
            controller: 'NewTypeController as newCtr',
            resolve: {}
          })
          .state('settings.base.services.editType', {
            url: "/:service_id/editType/:service_type_id",
            parents: 'settings.base.services',
            templateUrl: "app/views/settings/base/services/service_types/edit.html",
            controller: 'EditTypeController as editCtr',
            resolve: {}
          })
          .state('settings.base.services.edit', {
            url: "/:service_id/edit",
            parents: 'settings.base.services',
            templateUrl: "app/views/settings/base/services/edit.html",
            controller: 'EditController as editCtr',
            resolve: {}
          })

          .state('settings.base.payment_types', {
            url: "/payment_types",
            parents: 'settings.base',
            templateUrl: "app/views/settings/base/payment_types.html",
            controller: 'PaymentTypesController as pmntTpCtr',
            resolve: {}
          })
          .state('settings.base.payment_types.new', {
            url: "/new",
            parents: 'settings.base.payment_types',
            templateUrl: "app/views/settings/base/payment_types/new.html",
            controller: 'NewPaymentTypesController as newCtr',
            resolve: {}
          })
          .state('settings.base.payment_types.view', {
            url: "/:payment_type_id",
            parents: 'settings.base.payment_types',
            templateUrl: "app/views/settings/base/payment_types/view.html",
            controller: 'ViewPaymentTypesController as viewCtr',
            resolve: {}
          })
          .state('settings.base.payment_types.edit', {
            url: "/:payment_type_id/edit",
            parents: 'settings.base.payment_types.view',
            templateUrl: "app/views/settings/base/payment_types/edit.html",
            controller: 'EditPaymentTypesController as editCtr',
            resolve: {}
          })

          .state('settings.base.service_areas', {
            url: "/service_areas",
            parents: 'settings.base',
            templateUrl: "app/views/settings/base/service_areas.html",
            controller: 'ServiceAreasController as srvAreaCtr',
            resolve: {}
          })
          .state('settings.base.service_areas.new', {
            url: "/new",
            parents: 'settings.base.service_areas',
            templateUrl: "app/views/settings/base/service_areas/new.html",
            controller: 'NewServiceAreaController as newCtr',
            resolve: {}
          })
          .state('settings.base.service_areas.view', {
            url: "/:service_area_id",
            parents: 'settings.base.service_areas',
            templateUrl: "app/views/settings/base/service_areas/view.html",
            controller: 'ViewServiceAreasController as viewCtr',
            resolve: {}
          })
          .state('settings.base.service_areas.edit', {
            url: "/:service_area_id/edit",
            parents: 'settings.base.service_areas.view',
            templateUrl: "app/views/settings/base/service_areas/edit.html",
            controller: 'EditServiceAreaController as editCtr',
            resolve: {}
          })

          .state('settings.phone', {
            url: "/settings/phone",
            parents: 'settings',
            templateUrl: "app/views/settings/phone/phone.html",
            controller: 'PhoneSettingsController as phoneSetCtr',
            resolve: {}
          })
          .state('settings.phone.view', {
            url: "/:group_id",
            parents: 'settings.phone',
            templateUrl: "app/views/settings/phone/view.html",
            controller: 'ViewPhoneSettingsController as phoneViewCtr',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([{
                    name: 'xeditable',
                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                    files: [
                      "../components/angular-xeditable/dist/css/xeditable.css",
                      "../components/angular-xeditable/dist/js/xeditable.js"
                    ]
                  }]);
                }
              ]
            }
          })


          .state('settings.phone.rules', {
            url: "/rules",
            parents: 'settings',
            templateUrl: "app/views/settings/phone/settings/phone-number-rules.html",
            controller: 'PhoneNumberRulesController as phoneRulesCtrl',
            resolve: {}
          })
          .state('settings.phone.recorded_calls_view', {
            url: "/settings/phone/recordedcalls",
            parents: 'settings.phone',
            templateUrl: "app/views/settings/phone/recorded_calls_view.html",
            controller: 'RecordedCallsController as recordedCallsCtrl',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'datatables',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        // Dependancies:
                        //'../css/dataTable/dataTables.bootstrap.css'
                      ]
                    },
                    {
                      name: 'datatables.bootstrap',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                        '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                        '../components/datatables/media/css/jquery.dataTables.css',
                        '../css/dataTable/dataTables.custom.css'
                      ]
                    },
                    {
                      name: 'daterangepicker',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        "../components/momentjs/moment.js",
                        "../components/angular-moment/angular-moment.min.js",
                        "../components/bootstrap-daterangepicker/daterangepicker.js",
                        "../components/angular-daterangepicker/js/angular-daterangepicker.min.js",
                        "../components/bootstrap-daterangepicker/daterangepicker.css"
                      ]
                    }
                  ]);
                }]
            }
          })


          .state('phone-numbers', {

            url: "/phone-numbers",
            template: "<ui-view></ui-view>",
            data: {
              pageTitle: "Phone Number Management",
              requiresLogin: true,
              acceptRole: [0]
            },
            abstract: true,
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'datatables',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        // Dependancies:
                        //'../css/dataTable/dataTables.bootstrap.css'
                      ]
                    },
                    {
                      name: 'datatables.bootstrap',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                        '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                        '../components/datatables/media/css/jquery.dataTables.css',
                        '../css/dataTable/dataTables.custom.css'
                      ]
                    },
                    {
                      name: 'daterangepicker',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        "../components/momentjs/moment.js",
                        "../components/angular-moment/angular-moment.min.js",
                        "../components/bootstrap-daterangepicker/daterangepicker.js",
                        "../components/angular-daterangepicker/js/angular-daterangepicker.min.js",
                        "../components/bootstrap-daterangepicker/daterangepicker.css"
                      ]
                    }
                  ]);
                }]
            }
          })

          .state('phone-numbers.manage', {
            url: '/manage',
            parents: 'phone-numbers',
            templateUrl: 'app/views/settings/phone/numbers.html',
            controller: 'PhoneNumberManagerController as PhoneNumberManagerCtrl',
            resolve: {}
          })

          .state('phone-numbers.blacklist', {
            url: '/blacklist',
            parents: 'phone-numbers',
            templateUrl: 'app/views/settings/phone/numbers.blacklist.html',
            controller: 'BlockedNumbersManagerController as $ctrl',
            resolve: {
              blockedNumbers: [
                'BlackListModel',
                function(BlackListModel) {
                  return BlackListModel.getAll();
                }
              ]
            }
          })

          .state('phone-numbers.history', {
            url: '/history',
            parents: 'phone-numbers',
            templateUrl: 'app/views/settings/phone/numbers.history.html',
            controller: 'PhoneNumberManagerController as PhoneNumberManagerCtrl',
            resolve: {}
          })

          .state('phone-numbers.recordings', {
            url: '/recordings',
            parents: 'phone-numbers',
            templateUrl: 'app/views/settings/phone/numbers.recordings.html',
            controller: 'RecordedCallsController as $ctrl',
            resolve: {

            }

          })


          .state('settings.adwords', {
            url: "/settings/adwords",
            parents: 'settings',
            templateUrl: "app/views/settings/adwords/campaigns.html",
            controller: 'AdwordsController as adwordsCtrl',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'datatables',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        // Dependancies:
                        //'../css/dataTable/dataTables.bootstrap.css'
                      ]
                    },
                    {
                      name: 'datatables.bootstrap',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                        '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                        '../components/datatables/media/css/jquery.dataTables.css',
                        '../css/dataTable/dataTables.custom.css'
                      ]
                    }
                  ]);
                }]
            }
          })
          .state('settings.email', {
            url: "/settings/email",
            parents: 'settings',
            templateUrl: "app/views/settings/email/email.html",
            controller: 'EmailSettingsController as emailSetCtr',
            resolve: {}
          })
          .state('settings.email.view', {
            url: "/:group_name",
            parents: 'settings.email',
            templateUrl: "app/views/settings/email/view.html",
            controller: 'ViewEmailSettingsController as emailViewCtr',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([{
                    name: 'xeditable',
                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                    files: [
                      "../components/angular-xeditable/dist/css/xeditable.css",
                      "../components/angular-xeditable/dist/js/xeditable.js"
                    ]
                  }, {
                    name: 'ui.codemirror',
                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                    files: [
                      "../components/codemirror/lib/codemirror.css",
                      "../components/codemirror/theme/mdn-like.css",
                      "../components/codemirror/mode/xml/xml.js",
                      "../components/angular-ui-codemirror/ui-codemirror.js"
                    ]
                  }, {
                    name: 'textAngular',
                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                    files: [
                      '../components/textAngular/src/textAngular.css',
                      '../components/textAngular/dist/textAngular-rangy.min.js',
                      '../components/textAngular/dist/textAngular-sanitize.min.js',
                      '../components/textAngular/dist/textAngular.min.js',
                      '../css/custom.css'
                    ]
                  }]);
                }
              ]
            }
          });
      }
    ]);
})();
