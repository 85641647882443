(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.JobDetailController
   *
   * @description
   * Controller - JobDetailController
   */
  angular
    .module("locksmithManager")
    .controller('JobDetailController', JobDetailController);

  JobDetailController.$inject = [
    '$scope',
    'job',
    'notes',
    'history',
    'AuthService',
    'JobsModel',
    '$modal',
    'PaymentsModel',
    '$pusher',
    'PaymentTypesModel',
    '$q',
    'NotesModel',
    'toaster',
    'taOptions',
    'uiGmapIsReady', 
    'LeadsModel',
    'UsersModel',
    'PhoneService'
  ];

  function JobDetailController($scope, job, notes, history, AuthService, JobsModel, $modal, PaymentsModel, $pusher, PaymentTypesModel, $q, NotesModel, toaster, taOptions, uiGmapIsReady, LeadsModel, UsersModel, PhoneService) {


    var client = new Pusher(window.lm.pusher, {
      authEndpoint: '/pusher/auth?token=' + AuthService.token_obj.token
    });

    var pusher = $pusher(client);

    var jobDetailCtrl = this;

    // Getting jobs information from route resolve.
    jobDetailCtrl.job = job;
    jobDetailCtrl.notes = notes;
    jobDetailCtrl.payments = PaymentsModel.payments;
    jobDetailCtrl.history = history;
    jobDetailCtrl.availableTechnicians = [];
    jobDetailCtrl.removePayment = removePayment;
    jobDetailCtrl.userHasRight = false;
    jobDetailCtrl.isSales = false;
    jobDetailCtrl.pendingAssignment = {comments: ''};
    jobDetailCtrl.newNoteText = '';
    jobDetailCtrl.setForm = setJobWorkFlowForm;
    jobDetailCtrl.lead = {};
    jobDetailCtrl.user = {};
    jobDetailCtrl.satisfactionCall= false; //aca tendria que traer los datos de la tabla
    jobDetailCtrl.close_note = ""; // creo que va acá, por las dudas no uso el notes de arriba.

    jobDetailCtrl.map = {
      center: {
        latitude: 38.825568,
        longitude: -77.810027
      },
      control: {},
      options: {
        scrollwheel: false
      },
      zoom: 8
    };

    jobDetailCtrl.addressMarker = {
      id: 0,
      coords: {
        latitude: 0,
        longitude: 0
      },
      options: {
        draggable: false
      }
    };
    function setJobWorkFlowForm(_form) {
      jobDetailCtrl.jobWorkFlowForm = _form;
    }

    function setLeadInfo(lead_info){
      jobDetailCtrl.lead = lead_info;
    }

    function setUserInfo(user_info){
      jobDetailCtrl.user = user_info;
    }

    init();

    function init() {
      var compiledAddress,
        jobRecord = jobDetailCtrl.job;

      getPayments();
      LeadsModel.getLead(jobDetailCtrl.job.lead_id).then(function(params) {
        setLeadInfo(params);
      });

      UsersModel.getUser(jobDetailCtrl.job.user_id).then(function(params) {
        setUserInfo(params);
      });

      jobDetailCtrl.userHasRight = AuthService.isAdmin() || AuthService.isDispatcher();
      jobDetailCtrl.isSales = AuthService.isSales();
      jobDetailCtrl.isAdmin = AuthService.isAdmin();

      if (jobDetailCtrl.userHasRight) {
        // Getting Avaialable Techs
        JobsModel.getAvailableTechnicians().then(function (techs) {
          jobDetailCtrl.availableTechnicians = techs;
        });
      }

      //Can Customizate text-angular
      taOptions.toolbar = [
        [
          'bold',
          'italics',
          'underline',
          'strikeThrough',
          'ul',
          'ol',
          'redo',
          'undo',
          'clear'
        ],
        [
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'indent',
          'outdent'
        ]
      ];

      // Map
      // Check for address for the job record, then geocode it.
      compiledAddress = jobRecord.street_number + " " + jobRecord.street_name + " " + jobRecord.city + " " + jobRecord.state + " " + jobRecord.postal_code;

      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({"address": compiledAddress}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
          jobDetailCtrl.map.center.latitude = results[0].geometry.location.lat();
          jobDetailCtrl.map.center.longitude = results[0].geometry.location.lng();

          jobDetailCtrl.addressMarker.coords.latitude = results[0].geometry.location.lat();
          jobDetailCtrl.addressMarker.coords.longitude = results[0].geometry.location.lng();
        }

        uiGmapIsReady.promise(1).then(function (instances) {
          instances.forEach(function (inst) {
            var map = inst.map;
            var uuid = map.uiGmap_id;
            var mapInstanceNumber = inst.instance; // Starts at 1.

            if(typeof(jobDetailCtrl.map.control.refresh)=='function') {
              jobDetailCtrl.map.control.refresh();
            }

          });
        });
      });

    }

    jobDetailCtrl.assignISP = function(id) 
    {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/jobs/partials/assingISPJobModal.html',
        controller: 'AssingISPJobModalController',
        controllerAs: 'assingJMC',
        /*//TO PASS PARAMS TO THE DIALOG
        resolve: {
          object: function () {
            return {
              'id': id
            };
          }
        }  */   
      });

      modalInstance.result.then(function (modalData) 
      {
          $scope.jobCtrl.showSpinner();

          JobsModel.assignISP(id,modalData.technicianId)
          .then(
          function (data){
            //jobDetailCtrl.job.assigned_technician_id = data.assigned_technician_id;
            JobsModel.show(jobDetailCtrl.job.id).then(function (data) {
              jobDetailCtrl.job = data;
            });
            $scope.jobCtrl.alertSuccess('The ISP was assigned');
            $scope.jobCtrl.hideSpinner();
          },
          //Function when something wrong happened
          function (data) {
            $scope.jobCtrl.alertError(data.error);
            $scope.jobCtrl.hideSpinner();
          });
      });
    };


    /**
     * [assignToTechnician description]
     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
     * @return {[type]} [description]
     */
    jobDetailCtrl.assignToTechnician = function () {
      var _form = jobDetailCtrl.jobWorkFlowForm;
      if (_form.$invalid) {
        return;
      }
      var fieldsToUpdate = {
        // We are only updating stuff that is allowed to change
        'assigned_technician_id': jobDetailCtrl.assignedTechnician,
        'assign_comment': jobDetailCtrl.pendingAssignment.comments
      };
      $scope.jobCtrl.showSpinner();
      JobsModel.update(jobDetailCtrl.job.id, fieldsToUpdate)
        .then(function () {
          $scope.jobCtrl.alertSuccess('The tech assigned');
          jobDetailCtrl.job.assigned_technician_id = jobDetailCtrl.assignedTechnician;
          jobDetailCtrl.assignedTechnician = undefined;
          jobDetailCtrl.jobWorkFlowForm.$submitted = false;
        })
        .catch(function () {
          $scope.jobCtrl.alertError('The tech not assigned');
        })
        .finally($scope.jobCtrl.hideSpinner);
        //$scope.jobCtrl.hideSpinner();
    };

    // Pusher Notifications
    var job_notifications_channel = pusher.subscribe('job_notifications_' + jobDetailCtrl.job.id);
    job_notifications_channel.bind_all(function (event, data) {
      // Listen to any push regards a job, and whenever it comes refresh the history.

      switch (event) {
        case 'payment-posted':
          JobsModel.history(jobDetailCtrl.job.id).then(function (data) {
            jobDetailCtrl.history = data;
          });
          break;

        case 'note-posted':
          // only pop a notification if the current user is not the one who
          // created the new message.

          if (AuthService.user.id !== data.user_id) {
            jobDetailCtrl.notes.data.unshift(data);
            toaster.pop('success', "New Note", data.note + ", Scroll down to see more information.", 10000);
          }

          break;

      }

      JobsModel.show(jobDetailCtrl.job.id).then(function (data) {
        jobDetailCtrl.job = data;
      });
      JobsModel.history(jobDetailCtrl.job.id).then(function (data) {
        jobDetailCtrl.history = data;
      });


    });

    // user role 1 is a tech user. they shouldn't be able to edit jobs.
    jobDetailCtrl.canEdit = AuthService.user.role !== 1;
    jobDetailCtrl.canDeletePayment = AuthService.user.role === 0;

    // Methods
    jobDetailCtrl.updateUserDetailsInfo = function () {

      var fieldsToUpdate = {
        // We are only updating stuff that is allowed to change
        'name': jobDetailCtrl.job.name,
        'phone': jobDetailCtrl.job.phone,

        'street_number': jobDetailCtrl.job.street_number,
        'street_name': jobDetailCtrl.job.street_name,
        'unit_number': jobDetailCtrl.job.unit_number,
        'city': jobDetailCtrl.job.city,
        'state': jobDetailCtrl.job.state,
        'postal_code': jobDetailCtrl.job.postal_code,

        'comments': jobDetailCtrl.job.comments,
        'address_notes': jobDetailCtrl.job.address_notes
      };

      JobsModel.update(jobDetailCtrl.job.id, fieldsToUpdate);

      // Map
      // Check for address for the job record, then geocode it.
      var compiledAddress = fieldsToUpdate.street_number + " " + fieldsToUpdate.street_name + " " + fieldsToUpdate.city + " " + fieldsToUpdate.state + " " + fieldsToUpdate.postal_code;

      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({"address": compiledAddress}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
          jobDetailCtrl.map.center.latitude = results[0].geometry.location.lat();
          jobDetailCtrl.map.center.longitude = results[0].geometry.location.lng();

          jobDetailCtrl.addressMarker.coords.latitude = results[0].geometry.location.lat();
          jobDetailCtrl.addressMarker.coords.longitude = results[0].geometry.location.lng();
        }

        uiGmapIsReady.promise(1).then(function (instances) {
          instances.forEach(function (inst) {
            var map = inst.map;
            var uuid = map.uiGmap_id;
            var mapInstanceNumber = inst.instance; // Starts at 1.

            jobDetailCtrl.map.control.refresh();

          });
        });
      });


    };

    jobDetailCtrl.parsePartialInvoiceHistory = function(description) {
      return angular.fromJson(description);
    };

    jobDetailCtrl.showPartialInvoiceHistory = function(description) {
       var modalInstance = $modal.open({
        templateUrl: 'app/views/jobs/partials/showPartialInvoiceDetail.html',
        controller: 'PartialInvoiceController',
        controllerAs: 'newPartialInvoiceCtrl',
        size: 'md',
        resolve: {
          description: function () {
            return description;
          }
        }
      });

      modalInstance.result.then(function (data) {
        getPayments();//jobDetailCtrl.payments.data.push(data);
        jobDetailCtrl.job.job_total += parseFloat(data.amount);
      });
    };
     


    jobDetailCtrl.reOpenJob = function () {
      $scope.jobCtrl.showSpinner();
      JobsModel.re_open(jobDetailCtrl.job.id)
        .then(function () {
          $scope.jobCtrl.alertSuccess('The job re-opened');
        })
        .catch(function () {
          $scope.jobCtrl.alertError('The job not re-opened');
        })
        .finally($scope.jobCtrl.hideSpinner);
    };

    function getPayments() {
      PaymentsModel.getAll(jobDetailCtrl.job.id);
    }

    jobDetailCtrl.showPaymentForm = function () {

      var modalInstance = $modal.open({
        templateUrl: 'app/views/jobs/partials/addPayment.html',
        controller: 'NewPaymentController',
        controllerAs: 'newPaymentCtrl',
        size: 'md',
        resolve: {
          jobId: function () {
            return jobDetailCtrl.job.id;
          },
          paymentTypes: [
            'PaymentTypesModel',
            function (PaymentTypesModel) {
              return PaymentTypesModel.getPaymentTypes();
            }
          ]
        }
      });

      modalInstance.result.then(function (data) {
        getPayments();//jobDetailCtrl.payments.data.push(data);
        jobDetailCtrl.job.job_total += parseFloat(data.amount);
      });
    };


    // Job Status Methods used to display/hide elements
    jobDetailCtrl.status = {};
    jobDetailCtrl.status.isClosed = function () {
      return +jobDetailCtrl.job.status === 3; // Status number 3 is closed.
    };
    jobDetailCtrl.status.isOpen = function () {
      return +jobDetailCtrl.job.status === 0;
    };
    jobDetailCtrl.status.isInProgress = function () {
      return +jobDetailCtrl.job.status === 2;
    };
    jobDetailCtrl.status.isCancel = function () {
      return +jobDetailCtrl.job.status === 5;
    };
    jobDetailCtrl.status.isPending = function () {
      return +jobDetailCtrl.job.status === 4;
    };

    jobDetailCtrl.status.hasSatisfactionCall = function (){
      return jobDetailCtrl.satisfactionCall;
    };

    jobDetailCtrl.makeSatisfactionCall = function (number){
      PhoneService.dial(number,"+18555454999");                                 
    };

    jobDetailCtrl.getPaymentAmount = function (paymentType){
      //1 = card
      //2 = cash
      //3 = check
      var amount = 0;
      jobDetailCtrl.payments.data.forEach(function(payment) {
        if (payment.payment_type_id ==paymentType){
          amount += payment.amount;
        }
        
      });
      return amount;
    };

    // Helper methods to show alerts
    jobDetailCtrl.outstanding = function (minutes, since_last_update) {

      var
        time_diff_in_minutes = moment().diff(moment(jobDetailCtrl.job.created_at), 'minutes');

      if (since_last_update) {
        time_diff_in_minutes = moment().diff(moment(jobDetailCtrl.job.updated_at), 'minutes');
      }
      return time_diff_in_minutes > minutes;

    };

    // Cleanup stuff...
    $scope.$on('$destroy', function () {
      pusher.unsubscribe('job_notifications_' + jobDetailCtrl.job.id);
    });

    jobDetailCtrl.closingInformation = {
      payments: [
        {
          payment_method: null,
          amount: ''
        }
      ],
      company_parts: '',
      technician_parts: '',
      tax: '',
      closed_note: '',

      setForm: function (form) {
        $scope.closeJobForm = form;
      },
      close: function () {
        $scope.closeJobForm.$submitted = true;
        /**
         * Do nothing if form is invaid
         */
        if ($scope.closeJobForm.$invalid) {
          return;
        }
        $scope.jobCtrl.showSpinner();
        var promises = [];
        for (var i = 0; i < this.payments.length; i++) {
          var promise = PaymentsModel.create({
            payment_type_id: this.payments[i].payment_method.id,
            amount: this.payments[i].amount,
            job_id: jobDetailCtrl.job.id
          });
          promises.push(promise);
        }

        $q.all(promises).then(function () {

          JobsModel.close(jobDetailCtrl.job.id, {
            company_parts: jobDetailCtrl.job.company_parts,
            technician_parts: jobDetailCtrl.job.technician_parts,
            tax: jobDetailCtrl.job.tax,
            closed_note: jobDetailCtrl.job.closed_note
          })
            .then(function () {
              $scope.jobCtrl.alertSuccess('The job closed');
            })
            .catch(function () {
              $scope.jobCtrl.alertError('An error has occured. Unable to close job.');
            })
            .finally($scope.jobCtrl.hideSpinner);
        })
          .catch(function () {
            $scope.jobCtrl.hideSpinner();
            $scope.jobCtrl.alertError('Unable to create a payment');
          });
      },
      closeJob: function () {
        // lo dejo por las dudas
        //$scope.closeJobForm.$submitted = true; lo quito por undefined
        /**
         * Do nothing if form is invaid, lo saco porque no valido nada
        if ($scope.closeJobForm.$invalid) {
          return;
        }
         */
        $scope.jobCtrl.showSpinner();
     
    
          JobsModel.close(jobDetailCtrl.job.id,jobDetailCtrl.close_note) //ojo que llegó un objeto :s
             //closed_note: 'nota de prueba' //ojo que llegó un objeto :s
          
            .then(function () {
              $scope.jobCtrl.alertSuccess('The job closed');
            })
            .catch(function () {
              $scope.jobCtrl.alertError('An error has occured. Unable to close job.');
            })
            .finally($scope.jobCtrl.hideSpinner);
        /*
          .catch(function () {
            $scope.jobCtrl.hideSpinner();
            $scope.jobCtrl.alertError('Unable to create a payment');
          });
          */
      },
      addAnotherPayment: function () {
        jobDetailCtrl.closingInformation.payments.push({
          payment_method: null,
          amount: ''
        });
      },
        remove: function (array, index) {
            array.splice(index, 1);
        }
    };

    PaymentTypesModel.getPaymentTypes().then(function (data) {
      jobDetailCtrl.paymentTypes = data;
    });


    // Notes:
    jobDetailCtrl.addNote = function () {
      if (jobDetailCtrl.newNoteText === '') {
        return;
      }
      jobDetailCtrl.sendNote = NotesModel.createNoteForJob(
        jobDetailCtrl.job.id, {
          note: jobDetailCtrl.newNoteText
        }
      ).then(function (data) {
          jobDetailCtrl.newNoteText = '';
          NotesModel.getNotesForJob(jobDetailCtrl.job.id);
        });
    };

    jobDetailCtrl.cancel = function () {
      $scope.jobCtrl.showSpinner();
      JobsModel.cancel(jobDetailCtrl.job.id)
        .then(function () {
          $scope.jobCtrl.alertSuccess('Job Cancelled');
        })
        .catch(function () {
          $scope.jobCtrl.alertError('Unable to cancel this job, please contact support.');
        })
        .finally($scope.jobCtrl.hideSpinner);
    };

    jobDetailCtrl.confirmAssignedTech = function () {
      $scope.jobCtrl.showSpinner();
      JobsModel.confirmAssignedTech(jobDetailCtrl.job.id)
        .then(function () {
          $scope.jobCtrl.alertSuccess('The tech was confirm');
        })
        .catch(function () {
          $scope.jobCtrl.alertError('The tech not confirm');
        })
        .finally($scope.jobCtrl.hideSpinner);
    };

    function removePayment(id) {
      $scope.jobCtrl.showSpinner();
      PaymentsModel.remove(id)
        .then(function () {
          $scope.jobCtrl.alertSuccess("Payment deleted");
          getPayments();
        })
        .catch(function () {
          $scope.jobCtrl.alertError("Unable to delete a payment at this time, please try again later");
        })
        .finally($scope.jobCtrl.hideSpinner);
    }

    jobDetailCtrl.sendCallLink = function () {
        $scope.jobCtrl.showSpinner();
        JobsModel.sendCallLink(jobDetailCtrl.job.id)
            .then(function (result) {
                if(result.data.isLinkGenerated){
                   $scope.jobCtrl.alertSuccess(result.data.message);
                }else{
                   $scope.jobCtrl.alertError(result.data.message);
                }
            })
            .catch(function () {
                $scope.jobCtrl.alertError('The send call link could not be generated');
            })
            .finally($scope.jobCtrl.hideSpinner);
      };
  }
})();
