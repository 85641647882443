(function () {
  'use strict';

  angular
    .module('locksmithManager')
    .config([
      '$stateProvider',
      function ($stateProvider) {

        $stateProvider
        /**
         * Jobs
         */
          .state('jobs', {
            url: "/jobs",
            templateUrl: 'app/views/jobs/jobs.html',
            data: {
              requiresLogin: true
            },
            controller: 'JobController as jobCtrl',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'ui.slimscroll',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        "../components/angular-slimscroll/angular-slimscroll.js"
                      ]
                    },
                    {
                      name: 'datatables',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        // Dependancies:
                        //'../css/dataTable/dataTables.bootstrap.css'
                      ]
                    },
                    {
                      name: 'datatables.bootstrap',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                        '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                        '../components/datatables/media/css/jquery.dataTables.css',
                        '../css/dataTable/dataTables.custom.css'
                      ]
                    }
                  ]);
                }
              ],
              statistics: ['JobsModel', function (JobsModel) {
                return JobsModel.getStatistics();
              }],
              tables: ['JobsModel', function (JobsModel) {
                return JobsModel.getDashboardTables();
              }],
              history: ['JobsModel', function (JobsModel) {
                return JobsModel.getDashboardHistory();
              }]
            }

          })
          .state('jobs.new', {
            url: "/new",
            parents: 'jobs',
            templateUrl: "app/views/jobs/new.html",
            controller: 'NewJobController as newJobCtrl',
            data: {
              pageTitle: 'New Job'
            },
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'ngAutocomplete',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        '../components/ngAutocomplete/src/ngAutocomplete.js'
                      ]
                    },
                    {
                      name: 'textAngular',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        '../components/textAngular/src/textAngular.css',
                        '../components/textAngular/dist/textAngular-rangy.min.js',
                        '../components/textAngular/dist/textAngular-sanitize.min.js',
                        '../components/textAngular/dist/textAngular.min.js',
                        '../css/custom.css'
                      ]
                    }
                  ]);
                }
              ]
            }
          })
          .state('jobs.all', {
            url: "/all",
            parents: 'jobs',
            templateUrl: "app/views/jobs/all.html",
            data: {
              pageTitle: 'All Jobs'
            },
            controller: 'AllJobController as allJobCtrl',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'locksmithManager',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        // Dependancies:
                        '../app/views/common/modal/submit-remove.html',
                        '../css/modal.css'
                      ]
                    }
                  ]);
                }
              ]
            }
          })
          .state('jobs.search', {
            url: "/search?status",
            parents: 'jobs',
            templateUrl: "app/views/jobs/search.html",
            data: {
              pageTitle: 'Search Jobs'
            },
            controller: 'SearchJobController as searchJobCtrl',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'locksmithManager',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        // Dependancies:
                        '../app/views/common/modal/submit-remove.html',
                        '../css/modal.css',
                        '../css/pages/search.css'
                      ]
                    },
                    {
                      name: 'daterangepicker',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                          "../components/momentjs/moment.js",
                          "../components/angular-moment/angular-moment.min.js",
                          "../components/bootstrap-daterangepicker/daterangepicker.js",
                          "../components/angular-daterangepicker/js/angular-daterangepicker.min.js",
                          "../components/bootstrap-daterangepicker/daterangepicker.css"
                      ]
                    }
                  ]);
                }
              ]
            }
          })
          .state('jobs.detail', {
            url: "/:jobId", // detail should be replaced with job number
            parents: 'jobs',
            templateUrl: "app/views/jobs/job_details.html",
            data: {
              pageTitle: 'Job detail'
            },
            controller: 'JobDetailController as jobDetailCtrl',
            resolve: {
              deps: [
                '$ocLazyLoad',
                function ($ocLazyLoad) {
                  return $ocLazyLoad.load([
                    {
                      name: 'mentio',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        "../components/ment.io/dist/mentio.min.js"
                      ]
                    },
                    {
                      name: 'angular-md5',
                      insertBefore: '#ng_load_plugins_before',
                      files: [
                        "../components/angular-md5/angular-md5.min.js"
                      ]

                    },
                    {
                      name: 'textAngular',
                      insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                      files: [
                        '../components/textAngular/src/textAngular.css',
                        '../components/textAngular/dist/textAngular-rangy.min.js',
                        '../components/textAngular/dist/textAngular-sanitize.min.js',
                        '../components/textAngular/dist/textAngular.min.js',
                        '../css/custom.css'
                      ]
                    }

                  ]);
                }
              ],
              job: [
                '$stateParams',
                'JobsModel',
                function ($stateParams, JobsModel) {
                  return JobsModel.show($stateParams.jobId);
                }
              ],
              notes: [
                '$stateParams',
                'NotesModel',
                function ($stateParams, NotesModel) {
                  return NotesModel.getNotesForJob($stateParams.jobId);
                }
              ],
              history: [
                '$stateParams',
                'JobsModel',
                function ($stateParams, JobsModel) {
                  return JobsModel.history($stateParams.jobId);
                }
              ]

            }
          })


        ;

      }
    ]); // End of config.
})();