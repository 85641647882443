(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.SearchGrqController
   *
   * @description
   * Controller - SearchGrqController
   */
  angular
    .module("locksmithManager")
    .controller('SearchGrqController', SearchGrqController);

  SearchGrqController.$inject = ['DTOptionsBuilder', 'DTColumnBuilder', '$scope', 'DTInstances', '$stateParams'];


      function SearchGrqController(DTOptionsBuilder, DTColumnBuilder, $scope, DTInstances, $stateParams) {
        var searchGrqCtrl = this;

        searchGrqCtrl.search = search;
        searchGrqCtrl.phrase = "";

        init();

        searchGrqCtrl.dtOptions = DTOptionsBuilder.newOptions()
          .withOption('ajax', {
            url: '/api/general-requests',
            type: 'GET',
            beforeSend: $scope.grqCtrl.authHeader,
            data: function (data) {
              data.search.value = searchGrqCtrl.phrase;
              if($stateParams.status !== undefined && Number.isInteger(+$stateParams.status)) {
                data.status = $stateParams.status;
              }
            }
          })
          .withDataProp('data')
          .withOption('processing', true)
          .withOption('serverSide', true)
          .withOption('bFilter', false)
          .withOption('createdRow', $scope.grqCtrl.createdRow)
          .withPaginationType('simple_numbers')
          .withBootstrap()
          .withOption('order', [1, 'asc'])
          .withLanguage({
            "sProcessing": $scope.mainCtr.processingHtml
          });

        searchGrqCtrl.dtColumns = [
          DTColumnBuilder.newColumn('record_number').withTitle('Record number')
            .notSortable().withClass('record-number'),

          DTColumnBuilder.newColumn('name').withTitle('Name'),

          DTColumnBuilder.newColumn('phone').withTitle('Phone')
            .renderWith($scope.grqCtrl.phoneFilter).notSortable().withClass('phone'),

          DTColumnBuilder.newColumn('comments').withTitle('Short Description')
            .notSortable().renderWith($scope.grqCtrl.charactersFilter),

          DTColumnBuilder.newColumn(null).withTitle('')
            .notSortable().renderWith($scope.grqCtrl.actionsHtml).withClass('actions')
        ];

        function init() {
        }

        function search(phrase) {
          DTInstances.getLast().then(function(dtInstance) {
            dtInstance.reloadData();
          });
        }
      }
})();