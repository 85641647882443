/**
 * Created by nikolay on 3/11/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.PaymentsModel
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('BlackListModel', BlackListModel);

  BlackListModel.$inject = ['$resource','$http'];
      function BlackListModel($resource,$http) {
        var model = this,
          URLS = {
            BLACKLIST: '/api/blacklist/:id'
          },

          BlackList = $resource(URLS.BLACKLIST);

        model.data = [];

        function cacheBlacklist(data) {
          model.data = data;
          return model.data;
        }

        model.getAll = function (jobId) {
           return BlackList.query();
        };

        model.create = function (blacklist) {
          return BlackList.save(blacklist).$promise;
        };

        model.delete = function (blacklist) {
          return BlackList.remove({id: blacklist.id}).$promise;
        };

      }
})();
