(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.EditTypeController
   *
   * @description
   * Contoller - Edit Type Controller
   */
  angular
    .module("locksmithManager")
    .controller('EditTypeController', [
      '$scope', '$state', '$stateParams', 'ServicesModel',
      function ($scope, $state, $stateParams, ServicesModel) {
        var editCtr = this;

        $scope.srvcCtr.setActiveService($stateParams.service_id).then(function () {
          $scope.srvcCtr.setActiveServiceType($stateParams.service_type_id).then(function () {
            editCtr.editServiceType = angular.copy(ServicesModel.activeServiceType);
          });
        });

        editCtr.update = function () {
          ServicesModel.updateServiceType(editCtr.editServiceType).then(function () {
            $state.go('settings.base.services.view', {
              service_id: $stateParams.service_id
            });
          });
        };
      }
    ]);
})();