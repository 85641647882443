(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.AdwordsController
     *
     * @description
     * Contoller - AdwordsController
     * 
     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
     */
    angular
        .module("locksmithManager")
        .controller('AdwordsController', AdwordsController);
    /**
     * $injector
     * lets inject dependecies
     * @type {Array}
     */
   AdwordsController.$injector = ['$rootScope',
    '$modal',
    '$q',
    'toaster',
    'PhoneService',
    'Spinner',
    'UsersModel'
    ];
    /**
     * AdwordsController Create a class to encapsulation
     */
    function AdwordsController($rootScope,
        $modal,
        $q,
        toaster,
        PhoneService,
        Spinner,
        UsersModel) {
        /**
         * self Holds current instance of Class
         * @private {[Object]}
         */
        var self = this;
        /*
        self.rulesList = PhoneService.phoneRules;
        self.usersList = UsersModel.availableUsersForRules;
        self.availablePhones = PhoneService.availableNumberPhonesForRules;
        */

         /**
         * constructor function which is called before class is intantiated
         */
        function constructor() {
            $rootScope.pageTitle = 'Adwords Settings';

            //PhoneService.getAvailableRules();

            //UsersModel.getAvailableUsersForRules();
            //self.getNumbers();
        }

        
    

        constructor();
    }


})();
