(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.HeaderController
   *
   * @description
   * Setup Layout Part - Header
   */
  angular
    .module("locksmithManager")
    .controller('HeaderController', [
      '$scope',
      function ($scope) {
        $scope.$on('$includeContentLoaded', function () {
          // init header
          Layout.initHeader();
        });
      }
    ]);
})();