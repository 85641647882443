(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.AllCbkController
   *
   * @description
   * Controller - AllCbkController
   */
  angular
    .module("locksmithManager")
    .controller("AllCbkController", AllCbkController);

  AllCbkController.$inject = ['CallbacksModel', 'DTOptionsBuilder', 'DTColumnBuilder', '$scope'];

  function AllCbkController(CallbacksModel, DTOptionsBuilder, DTColumnBuilder, $scope) {
    var allCbkCtrl = this;

    allCbkCtrl.statistics = CallbacksModel.statistics;

    init();

    allCbkCtrl.dtOptions = DTOptionsBuilder.newOptions()
      .withOption('ajax', {
        url: '/api/callbacks',
        type: 'GET',
        beforeSend: $scope.cbkCtrl.authHeader
      })
      .withDataProp('data')
      .withOption('processing', true)
      .withOption('serverSide', true)
      .withOption('bFilter', false)
      .withOption('createdRow', $scope.cbkCtrl.createdRow)
      .withPaginationType('simple_numbers')
      .withBootstrap()
      .withOption('order', [3, 'desc'])
      .withLanguage({
        "sProcessing": $scope.mainCtr.processingHtml
      });

    allCbkCtrl.dtColumns = [
      DTColumnBuilder.newColumn('record_number').withTitle('Record number')
        .notSortable().withClass('record-number'),

      DTColumnBuilder.newColumn('name').withTitle('Name'),

      DTColumnBuilder.newColumn('phone').withTitle('Phone')
        .renderWith($scope.cbkCtrl.phoneFilter).notSortable().withClass('phone'),

      DTColumnBuilder.newColumn('created_at').withTitle('Opened')
        .renderWith($scope.cbkCtrl.amCalendarFilter),

      DTColumnBuilder.newColumn('updated_at').withTitle('Updated')
        .renderWith($scope.cbkCtrl.amCalendarFilter),

      DTColumnBuilder.newColumn('status').withTitle('Status')
        .renderWith($scope.cbkCtrl.statusHtml).withClass('status'),

      DTColumnBuilder.newColumn(null).withTitle('')
        .notSortable().renderWith($scope.cbkCtrl.actionsHtml).withClass('actions')
    ];

    /////////////////////////

    function init() {
      CallbacksModel.getStatistics();
    }
  }
})();