(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.TechReportViewController
     *
     * @description
     * Contoller - TechReportViewController
     */
    angular
        .module("locksmithManager")
        .controller("TechReportViewController", [
            "$scope", "$stateParams", 'ReportsModel', 'toaster', 'Spinner','JobsModel','PaymentsModel',
            function($scope, $stateParams, ReportsModel, toaster, Spinner,JobsModel,PaymentsModel) {

                var self = this;

                self.filter = {
                    user_id: $stateParams.user_id || null,
                    start_date: $stateParams.start_date || null
                };
                self.report = {
                    creation_date: null,
                    jobs_closed: {},
                    payment_types: [],
                    totals: {},
                    user: {}
                };
                self.getPaymentValue = getPaymentValue;

                init();

                function init() {
                    getReport();
                }

                function getReport() {
                    Spinner.start();
                    var params = angular.copy(self.filter);
                    ReportsModel.get(params).then(function(reports) {
                        angular.extend(self.report, reports.data);
                        Spinner.stop();
                    }, throwError);
                }

                function throwError(errorResponse) {
                    toaster.pop('error', "Error", errorResponse);
                    Spinner.stop();
                }

                function getPaymentValue(type, payments){
                    return payments[type];
                }

                this.updateJobData = function(jobId,field,value) {
                    Spinner.start();
                    
                    var data = {};
                    data[field] = value;

                    JobsModel.update(jobId,data)
                    .then(
                        //Function when all is ok
                        function (data) 
                        {
                          getReport();
                        },
                        //Function when something wrong happened
                        function (data) {
                          Spinner.stop();
                        }
                    );
                };

                this.updatePaymentData = function(job,paymentTypeid,paymentName) {
                    Spinner.start();
                    
                    var data = {
                        payment_type_id: paymentTypeid,
                        job_id: job.job_id,
                        amount: getPaymentValue(paymentName, job.payments)
                    };
                    

                    PaymentsModel.updateByJob(data)
                    .then(
                        //Function when all is ok
                        function (data) 
                        {
                          getReport();
                        },
                        //Function when something wrong happened
                        function (data) {
                          Spinner.stop();
                        }
                    );
                };
            }
        ]);
})();
