(function() {
    "use strict";
    /**
     * @ngdoc Service
     * @name locksmithManager.service.JobsModel
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .service("AdWordsService", AdWordsService);

    AdWordsService.$inject = ["$http"];

    // TODO: Add error handling.

    function AdWordsService($http) {

        this.getAllCampaigns = function() {

            return $http.get('api/adwords/campaigns')
                .then(function success(data) {
                    return data.data;
                }, function error(data) {

                });

        };


        // We don't need to do anything here... it's read only to the front end....
        // this.updateCampaign = function(campaign_id, $status) {
        //
        //     var request = $http({
        //       method: 'POST',
        //       data: {
        //           'status': $status
        //       },
        //       url: "api/adwords/campaigns/"+campaign_id,
        //     });
        //
        //     return  request.success(
        //         function (requestResponse) {
        //           return requestResponse.data;
        //
        //     });
        //
        // };




    }
})();