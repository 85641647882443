/**
 * Created by nikolay on 3/6/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.PaymentTypesController
   *
   * @description
   * Controller - Services Controller
   */
  angular
    .module("locksmithManager")
    .controller('PaymentTypesController', PaymentTypesController);

  PaymentTypesController.$inject = [
    '$q',
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    'PaymentTypesModel',
    'AuthService'];

  function PaymentTypesController($q, $scope, $state, $stateParams, $timeout, PaymentTypesModel, AuthService) {

    var pmntTpCtr = this;

    $scope.$on('$stateChangeSuccess',
      function (event, toState, toParams, fromState, fromParams) {
        if (angular.isUndefined($stateParams.payment_type_id)) {
          pmntTpCtr.loadingBaseState = false;
        }
        PaymentTypesModel.resetActivePaymentType();
      });

    pmntTpCtr.init = function () {
      pmntTpCtr.loadingBaseState = false;
      pmntTpCtr.paymentTypes = PaymentTypesModel.paymentTypes;

      PaymentTypesModel.getPaymentTypes();
      PaymentTypesModel.resetActivePaymentType();
      if ($state.current.name != "settings.base.payment_types") {
        pmntTpCtr.loadingBaseState = true;
        $state.go('settings.base.payment_types');
      }
    };

    pmntTpCtr.isAdmin = function () {
      var user = AuthService.user;
      if (user.active) {
        return user.role === AuthService.roles.ADMIN_ROLE;
      }
      else {
        return false;
      }
    };

    pmntTpCtr.setActivePaymentType = function (payment_type_id) {
      if (pmntTpCtr.loadingBaseState) {
        return false;
      }

      var deffered = $q.defer();
      PaymentTypesModel.resetActivePaymentType();

      PaymentTypesModel.getPaymentType(payment_type_id).then(function (data) {
        $timeout(function () {
          PaymentTypesModel.setActivePaymentType(data);
          pmntTpCtr.activePaymentType = PaymentTypesModel.activePaymentType;
          deffered.resolve(true);
        }, 10);
      }, function (data) {
        pmntTpCtr.init();
      });

      return deffered.promise;
    };

    pmntTpCtr.delete = function (payment_type_id) {
      PaymentTypesModel.deletePaymentType(payment_type_id);
      pmntTpCtr.init();
    };

    pmntTpCtr.init();
  }
})();
