(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.LoginController
   *
   * @description
   * Controller - LoginController
   */
  angular
    .module("locksmithManager")
    .controller('LoginController', [
      '$rootScope',
      '$timeout',
      '$scope',
      '$state',
      'store',
      'AuthService',
      '$modal',
      'Idle',
      function ($rootScope, $timeout, $scope, $state, store, AuthService, $modal,Idle) {

        var loginCtr = this;

        loginCtr.auth_obj = {};

        loginCtr.recoveryPassword = {
          show: false
        };

        loginCtr.init = function () {
          var user_name = store.get('u_name');
          if (user_name) {
            loginCtr.auth_obj.email = user_name;
            loginCtr.isChecked = true;
          }
        };

        loginCtr.login = function () {
          loginCtr.recoveryPassword.show = false;
          loginCtr.error = false;
          $rootScope.loadingSpinner = true;
          if (loginCtr.isChecked) {
            store.set('u_name', loginCtr.auth_obj.email);
          }
          else {
            store.remove('u_name');
          }
          AuthService.authenticate(loginCtr.auth_obj).then(function (data) {

            // Need to check if the idle watch is on, if it's not lets turn it on.
            if(!Idle.running()) {
              Idle.watch();
            }

            // Do some magic to navigate the user back to the page where he was
            // signed out of, or the page that was tried to access when he wasn't
            //  logged in.
            var state = store.get('back_state'),
              stateParams = store.get('back_state_params');

            if (state !== null && stateParams !== null) {
              $state.go(state.name, stateParams);

              store.remove('back_state');
              store.remove('back_state_params');
            } else {
              $state.go("jobs.search");
            }
          }, function (error) {
            loginCtr.error = true;
            $rootScope.loadingSpinner = false;
          });
        };

        loginCtr.open = function () {
          loginCtr.error = false;

          var modalInstance = $modal.open({
            templateUrl: 'recoveryPasswordModal.html',
            controller: 'ModalRecoveryPasswordController',
            controllerAs: 'recoveryCtr',
            size: 'sm',
            resolve: {
              email: function () {
                return loginCtr.auth_obj.email;
              }
            }
          });

          modalInstance.result.then(function (data) {
            //show message from modal on login form
            loginCtr.recoveryPassword.message = data.message;
            loginCtr.recoveryPassword.class = 'alert-success';
            loginCtr.recoveryPassword.show = true;

            loginCtr.auth_obj.email = data.email;
          });
        };

        loginCtr.init();
      }
    ]);
})();