(function () {
  'use strict';

  angular
    .module("locksmithManager")
    .controller('ViewServiceAreasController', ViewServiceAreasController);

  ViewServiceAreasController.$inject = ['$scope', '$stateParams'];

  function ViewServiceAreasController($scope, $stateParams) {
    var viewCtr = this;

    $scope.srvAreaCtr.setActiveServiceArea($stateParams.service_area_id);
  }
})();