(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.GrqController
   *
   * @description
   * Controller - GrqController
   */
  angular
    .module("locksmithManager")
    .controller('GrqController', GrqController);

  GrqController.$inject = ['$scope', 'AuthService', 'toaster', 'GeneralRequestsModel',
    'DTInstances', '$state', '$filter', '$compile', 'store', '$modal', 'statusesEntity', 'tables', 'history'];

  function GrqController($scope, AuthService, toaster, GeneralRequestsModel,
                         DTInstances, $state, $filter, $compile, store, $modal, statusesEntity, tables, history) {
    var grqCtrl = this;

    grqCtrl.statistics = GeneralRequestsModel.statistics;
    grqCtrl.statuses = statusesEntity;
    grqCtrl.tables = tables.data;
    grqCtrl.history = history.data;

    grqCtrl.remove = remove;
    grqCtrl.viewGeneralRequest = viewGeneralRequest;
    grqCtrl.isAdmin = AuthService.isAdmin;
    grqCtrl.isNotTech = !AuthService.isTechnician();

    grqCtrl.authHeader = authHeader;
    grqCtrl.createdRow = createdRow;
    grqCtrl.actionsHtml = actionsHtml;
    grqCtrl.phoneFilter = phoneFilter;
    grqCtrl.charactersFilter = charactersFilter;
    grqCtrl.amCalendarFilter = amCalendarFilter;
    grqCtrl.statusHtml = statusHtml;

    ///////////////////////////////////////////////////////////////////////

    function createdRow(row) {
      // Recompiling so we can bind Angular directive to the DT
      $compile(angular.element(row).contents())($scope);
    }

    function actionsHtml(data) {
      var html = '<div class="actions">' +
        '<a class="btn btn-xs default btn-editable" data-ng-click="grqCtrl.viewGeneralRequest(' +
        data.id + ')"><i class="fa fa-search"></i> View</a>';
      if (grqCtrl.isAdmin()) {
        html += '<a data-ng-show="grqCtrl.isAdmin()" class="btn btn-xs red filter-cancel" ' +
        'data-ng-click="grqCtrl.remove(' + data.id + ')"><i class="fa fa-times"></i>Delete</a>';
      }
      html += '</div>';
      return html;
    }

    function statusHtml(data, type) {
      return '<span data-entity-status ' +
        'data-entity-id=' + data + ' ' +
        'data-entity-type="general_request">' +
        '</span>';
    }

    function phoneFilter(data) {
      return $filter('phone')(data);
    }

    function charactersFilter(data) {
      return $filter('characters')(data, 120);
    }

    function amCalendarFilter(data) {
      return $filter('amCalendar')(data);
    }

    function authHeader(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + store.get('lsmToken'));
    }

    function alertSuccess(message) {
      toaster.success("Success", message, 2000);
    }

    function alertError(message) {
      toaster.error("Error", message, 2000);
    }

    function remove(id) {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'removeModal.html',
        controller: 'ModalRemove',
        controllerAs: 'vm',
        resolve: {
          object: function () {
            return {
              name: 'complain'
            };
          }
        }
      });

      modalInstance.result.then(function () {
      GeneralRequestsModel.deleteGeneralRequest(id)
        .success(function (data, status) {
          if (status === 200) {
            alertSuccess('General request was deleted');
            update();
          }
        })
        .error(function (data, status) {
          if (status === 500) {
            alertError('We have some problem on server');
          }
          else {
            if (status === 401) {
              alertError(data.error);
            }
          }
        });
      });
    }

    function update() {
      DTInstances.getLast().then(function (dtInstance) {
        dtInstance.reloadData();
      });
      GeneralRequestsModel.getStatistics();
    }

    function viewGeneralRequest(id) {
      $state.go('general_requests.detail', {generalRequestId: id});
    }
  }
})();