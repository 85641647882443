(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.EditSettingsController
   *
   * @description
   * Contoller - EditSettingsController
   */
  angular
    .module("locksmithManager")
    .controller('EditSettingsController', [
      '$scope', '$stateParams', 'LeadsModel',
      function ($scope, $stateParams, LeadsModel) {
        var editCtr = this;

        $scope.leadCtr.setActiveLead($stateParams.lead_id).then(function () {
          editCtr.editLead = angular.copy(LeadsModel.activeLead);
        });

        editCtr.update = function () {
          LeadsModel.updateLead(editCtr.editLead).then(function () {
            $scope.leadCtr.init();
          });
        };
      }
    ]);
})();