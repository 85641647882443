(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ReleasePhoneNumberController
   *
   * @description
   * Contoller - SearchPhoneModalController
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   */
  angular
    .module("locksmithManager")
    .controller('ReleasePhoneNumberController', ReleasePhoneNumberController);
  /**
   * $injector
   * lets inject dependecies
   * @type {Array}
   */
  ReleasePhoneNumberController.$injector = ['toaster', '$modalInstance', 'ModalFactory', 'Spinner', 'phoneNumber', 'PhoneNumberService'];

  /**
   * SearchPhoneModalController Create a class to encapsulation
   */
  function ReleasePhoneNumberController(toaster, $modalInstance, ModalFactory, Spinner, phoneNumber, PhoneNumberService) {
    /**
     * self Holds current instance of Class
     * @private {[Object]}
     */
    var self = this;

    self.phoneNumber = phoneNumber;

    self.cancel = function () {
      $modalInstance.dismiss('cancelModal');
    };

    self.release = function(){
      Spinner.start();

      PhoneNumberService.release(phoneNumber).then(function () {
        Spinner.stop();
        toaster.pop('success', "Success", self.phoneNumber.phone + ' was successfully released');
        $modalInstance.close();
      });
    };

    constructor();

    /**
     * constructor function which is called before class is intantiated
     */
    function constructor() {
    }


    function searchPhoneNumbers() {
      Spinner.start();
      PhoneService.searchPhoneNumbers(self.searchCriteria).then(function (response) {
        self.phoneNumbers.length = 0;
        Array.prototype.push.apply(self.phoneNumbers, response.data);
        Spinner.stop();
      }, function (error) {
        toaster.pop('error', "Error", 'Some error found while searching phone number');
        Spinner.stop();
      });
    }

    function confirmBuy(phone) {
      var options = {
        'locale': {
          'header': 'Please confirm',
          'body': 'Are you sure you want to buy ' + phone.phone_number + ' charges may apply',
          'yes': '<i class="fa fa-shopping-cart fa-fw"></i> Yes, want to buy',
          'cancel': 'No'
        }
      };
      ModalFactory.confirm(options).then(function (successData) {
        Spinner.start();
        PhoneService.buyPoolNumberForPeople({
          phonenumber: phone.phone_number
        }).then(function (response) {
          toaster.pop('success', "Success", phone.phone_number + ' was purchased successfully');
          closeModal();
          Spinner.stop();
        }, function (error) {
          toaster.pop('error', "Error", 'Some error found while buying phone number');
          Spinner.stop();
        });
      });
    }
  }
})();
