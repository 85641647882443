(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.PhoneNumberRulesController
     *
     * @description
     * Contoller - PhoneNumberRulesController
     * 
     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
     */
    angular
        .module("locksmithManager")
        .controller('PhoneNumberRulesController', PhoneNumberRulesController);
    /**
     * $injector
     * lets inject dependecies
     * @type {Array}
     */
    PhoneNumberRulesController.$injector = ['$rootScope',
    '$modal',
    '$q',
    'toaster',
    'PhoneService',
    'Spinner',
    'UsersModel'
    ];
    /**
     * PhoneNumberRulesController Create a class to encapsulation
     */
    function PhoneNumberRulesController($rootScope,
        $modal,
        $q,
        toaster,
        PhoneService,
        Spinner,
        UsersModel) {
        /**
         * self Holds current instance of Class
         * @private {[Object]}
         */
        var self = this;
        self.rulesList = PhoneService.phoneRules;
        self.usersList = UsersModel.availableUsersForRules;
        self.availablePhones = PhoneService.availableNumberPhonesForRules;
        
        

        /**
         * constructor function which is called before class is intantiated
         */
        function constructor() {
            $rootScope.pageTitle = 'Phone number rules';

            PhoneService.getAvailableRules();

            //UsersModel.getAvailableUsersForRules();
            //self.getNumbers();
        }

        self.getNumbers = function() {

            
            PhoneService.getAvailableNumbersForRules(false,null)
            .then(function(list) {
                self.availablePhones.data = list;

            },function(error) {

            });

        };

        /**
         * showSearchPhoneModal
         * open modal to search phone number based on some parameters
         * 
         * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
         * @return {void}
         */
        self.showNewPhoneRuleModal = function(isEdit,index) {
                
            var rule = {};

            if(isEdit)
            {
                rule = self.rulesList.data[index];
            }

            var modalInstance = $modal.open({
                animation: true,
                templateUrl: 'app/views/settings/phone/settings/new-rule-modal.html',
                controller: 'PhoneRulesModalController',
                controllerAs: 'rulesModalCtrl',
                size: 'md',
                resolve: {
                    'ruleToEdit': function() {        
                        rule.user_id = ""+rule.user_id;
                        return rule;
                    },
                    'availablePhonesList': function() {
                        return self.availablePhones;
                    },
                    'availableUsers': function() {
                        return self.usersList;
                    },
                    'getNumbers':function() {
                        self.getNumbers();
                        return true;
                    }
                    /*deps: [
                        '$ocLazyLoad',
                        function ($ocLazyLoad) {
                          return $ocLazyLoad.load([
                            {
                              name: 'Timepicker',
                              insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                              files: [
                                  "../components/momentjs/moment.js",
                                  "../components/bootstrap-timepicker/js/bootstrap-timepicker.min.js",
                                  "../components/bootstrap-timepicker/css/bootstrap-timepicker.min.css"
                              ]
                            }
                          ]);
                        }
                      ]*/
                } 
            });

            modalInstance.result.then(
                function(response) {

                },
                function(error) {
            });
        };

        self.deleteRule = function(id,index) {

            Spinner.start();

            PhoneService.deletePhoneRule(id)
            .then(function(data) {

                Spinner.stop();

                if(data.result == 'ok')
                {
                    if(self.rulesList.data!==null)
                    {
                        self.rulesList.data.splice(index,1);
                    }
                }

            },
            function(error) {
                Spinner.stop();
            });
        };

        self.saveRuleOrder = function() {
            //Spinner.start();

            PhoneService.saveRulesOrder()
            .then(function(data) {
                               
                //Spinner.stop();
            },
            function(error) {
                //Spinner.stop();
            });
        };

        self.changeRuleOrder = function(action,index) {

            //console.log('pe');

            var currentOrder = self.rulesList.data[index].order;

            if(action=='down')
            {
                self.rulesList.data[index].order = self.rulesList.data[index+1].order;
                self.rulesList.data[index+1].order = currentOrder;
                
                self.rulesList.data[index].dirty = true;
                self.rulesList.data[index+1].dirty = true;

                self.moveItems(self.rulesList.data,index,index+1);
            }
            else
            {
                self.rulesList.data[index].order = self.rulesList.data[index-1].order;
                self.rulesList.data[index-1].order = currentOrder;

                self.rulesList.data[index].dirty = true;
                self.rulesList.data[index-1].dirty = true;

                self.moveItems(self.rulesList.data,index,index-1);
            }

            if (self.updateRulesTimer) {
                clearTimeout(self.updateRulesTimer); //cancel the previous timer.
            }

            self.updateRulesTimer = setTimeout(self.saveRuleOrder,1000);
        };

        self.moveItems = function (array,old_index, new_index) {
            
            while (old_index < 0) {
                old_index += array.length;
            }
            
            while (new_index < 0) {
                new_index += array.length;
            }

            if (new_index >= array.length) {
                var k = new_index - array.length;
                while ((k--) + 1) {
                    array.push(undefined);
                }
            }

            array.splice(new_index, 0, array.splice(old_index, 1)[0]);
            
            //return this; // for testing purposes
        };

        self.activateRule = function(id,active,index) {
            //Spinner.start();
            self.rulesList.data[index].active = active;

            PhoneService.activateRule(id,active)
            .then(function(data) {
                                
                //Spinner.stop();
            },
            function(error) {
                //Spinner.stop();
            });
        };

        constructor();
    }


})();
