(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.SaleReportsController
     *
     * @description
     * Contoller - SaleReportsController
     */
    angular
        .module("locksmithManager")
        .controller("SaleReportsController", [
            "$scope", "$stateParams", 'ReportsModel', 'toaster', 'UsersModel', 'Spinner',
            function($scope, $stateParams, ReportsModel, toaster, UsersModel, Spinner) {

                var self = this; 
                self.user = {
                    name : 'Select User',
                    id : null
                };
                self.filter = {
                    user_id: null,
                    start_date: null,
                    end_date: null
                };
                self.users = [];
                self.reports = {
                    available_reports_dates : [],
                    time_period_totals : {},
                    user : null
                };
                self.selectUser = selectUser;

                self.datePicker = {
                    date: { startDate: moment().startOf('week'), endDate: moment().endOf('week') },
                    displayString: 'Select date Range',
                    opts: {
                        locale: {
                            separator: ' - ',
                            format: 'YYYY-MM-DD'
                        },
                        ranges: {
                            //'Today': [moment(), moment()],
                            //'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            //'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            //'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            //'This Month': [moment().startOf('month'), moment().endOf('month')],
                            //'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                            'This Week': [moment().startOf('week'),moment().endOf('week')],
                            'Last Week': [moment().subtract(7, 'days'), moment()],
                            'Last Four Weeks': [moment().subtract(21, 'days'), moment()],
                        },
                        eventHandlers: {
                            'apply.daterangepicker': function(ev, picker) {
                                var model = angular.copy(ev.model),
                                    sDate = model.startDate.format(self.datePicker.opts.locale.format),
                                    eDate = model.endDate.format(self.datePicker.opts.locale.format);
                                angular.extend(self.filter, {
                                    start_date: sDate,
                                    end_date: eDate
                                });
                                self.datePicker.displayString = sDate + ' - ' + eDate;
                                if (validateFilter()) {
                                    getReports();

                                }
                            }
                        }
                    }
                };

                init();

                function init() {
                    getTechnicians();
                }

                function getReports() {
                    Spinner.start();
                    resetReport();
                    var params = angular.copy(self.filter);
                    ReportsModel.all(params).then(function(reports) {
                        angular.extend(self.reports, reports.data);
                        Spinner.stop();
                    }, throwError);
                }

                function getTechnicians() {
                    self.users.length = 0;
                    UsersModel.query({
                            filter: 'sale'
                        })
                        .then(function(data) {
                            Array.prototype.push.apply(self.users, data.list);
                        }, throwError);
                }

                function selectUser(user) {
                     self.filter.user_id = user.id;
                    if (validateFilter()) {
                        getReports();

                    }
                }

                function throwError(errorResponse) {
                    Spinner.stop();
                    if (errorResponse.status === 404) {
                        return;
                    }
                    toaster.pop('error', "Error", errorResponse);
                }

                function validateFilter() {
                    var flag = true;
                    for (var key in self.filter) {
                        if (!self.filter[key]) {
                            flag = false;
                            break;
                        }
                    }
                    return flag;
                }

                function resetReport() {
                    angular.extend(self.reports, {
                        available_reports_dates: [],
                        time_period_totals: {},
                        user: null
                    });
                }
            }
        ]);
})();
