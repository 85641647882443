(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.SearchPhoneModalController
     *
     * @description
     * Contoller - SearchPhoneModalController
     * 
     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
     */
    angular
        .module("locksmithManager")
        .controller('PhoneRulesModalController', PhoneRulesModalController);
    /**
     * $injector
     * lets inject dependecies
     * @type {Array}
     */
    PhoneRulesModalController.$injector = [
    'toaster',
    '$modalInstance',
    'PhoneService',
    'ModalFactory',
    'Spinner',
    'UsersModel',
    '$modal'];
    /**
     * PhoneRulesModalController Create a class to encapsulation
     */
    function PhoneRulesModalController(
        toaster,
        $modalInstance,
        PhoneService,
        ModalFactory,
        Spinner,
        UsersModel,
        $modal,
        ruleToEdit,
        availablePhonesList,
        availableUsers
        ) 
    {
        /**
         * self Holds current instance of Class
         * @private {[ruleToEdit]}
         */
        var self = this;
        self.rule = {};

        angular.copy(ruleToEdit,self.rule);

        self.usersList = availableUsers;
        self.filteredUserList = [];
        self.availablePhones = availablePhonesList;
        self.filteredNumberList = [];    
        self.fromAllNumbers = null;    
        self.daysArray = [];
        self.tp_init_tries = 0;

        /*self.getPickerStartDate = function() {
            var start = {};
            if (self.rule.id!==undefined && self.rule.id!==null && self.rule.start_time!==undefined && self.rule.start_time!==null){
                start = moment(self.rule.start_time,'HH:mm:ss');
            }else{
                start = moment();
            }
            return start;
        };
        
        self.getPickerEndDate = function() {
            var end = {};
            if (self.rule.id!==undefined && self.rule.id!==null && self.rule.end_time!==undefined && self.rule.end_time!==null){
                end = moment(self.rule.end_time,'HH:mm:ss');
            }else{
                end = moment();
            }
            return end;
        };

        self.initPickerDisplayString = function() {
            var string;
            if (self.rule.id!==undefined && self.rule.id!==null && self.rule.start_time!==undefined && self.rule.end_time!==null){
                string = self.rule.start_time+"-"+self.rule.end_time;
            }else{
                string = "Choose Time";
            }
            return string;
        };

        self.timePicker = {
            'time': {
                startDate: self.getPickerStartDate(),
                endDate: self.getPickerEndDate(),
            },
            displayString: self.initPickerDisplayString(),
            opts: {
                "timePicker": true,
                "timePicker24Hour": true,
                timePickerIncrement: 15,
                locale:{
                    format: 'HH:mm'
                },
                eventHandlers: {
                  'apply.daterangepicker': function(ev, picker) {
                        var stime = ev.model.startDate.format(self.timePicker.opts.locale.format);
                        var etime = ev.model.endDate.format(self.timePicker.opts.locale.format);

                        self.rule.start_time = stime;
                        self.rule.end_time = etime;
                        self.timePicker.displayString = stime+"-"+etime;
                  }
              }
            }          
        };*/




        /**
         * constructor function which is called before class is intantiated
         */
        function constructor() {

            self.fromAllNumbers = (self.rule.id!==undefined && self.rule.id!==null)?self.rule.from_number==='':true;

            self.filterAvailableUsers();

            self.onRuleTypeChange();

            self.renderSelectedDays();

            self.preprocess_rule_data();

            self.try_init_timepicker();

        } 

        self.reset_from_number = function() {
            self.rule.from_number = '';
        };

        self.preprocess_rule_data = function() {
            var start_time = self.rule.start_time;
            if (typeof start_time === 'undefined' || start_time===null || start_time.length<5){
                start_time = '00:00';
            }else{
                if (start_time.length>5){
                    start_time = start_time.substring(0, 5);
                }
            }
            var end_time = self.rule.end_time;
            if (typeof end_time === 'undefined' || end_time===null || end_time.length<5){
                end_time = '00:00';
            }else{
                if (end_time.length>5){
                    end_time = end_time.substring(0, 5);
                }
            }
            self.rule.start_time = start_time;
            self.rule.end_time = end_time;
        };

        self.try_init_timepicker = function() {
            if (self.tp_init_tries>30){
                self.tp_init_tries = 0;
                self.closeModal();
            }
            if ($('#form-modal-new-phone-rule').length>0){
                self.init_timepicker();
                self.tp_init_tries = 0;
            }else{
                self.tp_init_tries++;
                setTimeout(self.try_init_timepicker, 200);
            }
        };      

        self.init_timepicker = function() {
            $('.timepicker-24').timepicker({
                minuteStep: 5,
                showSeconds: false,
                showMeridian: false,
                //defaultTime: false
            });

            // handle input group button click
            $('.timepicker').parent('.input-group').on('click', '.input-group-btn', function(e){
                e.preventDefault();
                $(this).parent('.input-group').find('.timepicker').timepicker('showWidget');
            });
        };

        self.renderSelectedDays = function() {

            self.daysArray.SU = false;
            self.daysArray.MO = false;
            self.daysArray.TU = false;
            self.daysArray.WE = false;
            self.daysArray.TH = false;
            self.daysArray.FR = false;
            self.daysArray.SA = false;

            if(self.rule.id!==undefined && self.rule.id!==null)
            {
                if(self.rule.days_of_week!==null && self.rule.days_of_week!==undefined)
                {
                    $.each(self.rule.days_of_week.split(','),function(i,v) {
                        self.daysArray[v] = true;
                    });
                }
            }
        };

        self.closeModal = function(){
            $modalInstance.close();
        };

        self.cancelModal = function(){
            $modalInstance.dismiss('cancelModal');        
        };

        self.showSearchPhonesModal = function() {
            var newNumberModal = $modal.open({
                animation: true,
                templateUrl: 'app/views/settings/phone/settings/search-phone-modal.html',
                controller: 'SearchPhoneModalController',
                controllerAs: 'searchModalCtrl',
                size: 'lg',                
                resolve: {
                    STATES: ['PhoneService', '$q', function(PhoneService, $q) {
                        var deferred = $q.defer();
                        PhoneService.getStates().then(function(response) {
                            if (response.hasOwnProperty('data')) {
                                deferred.resolve(response.data);
                            } else {
                                deferred.resolve([]);
                            }
                        }).catch(function(error) {
                            deferred.resolve(null);
                        });
                        return deferred.promise;
                    }]
                }
            });

            newNumberModal.result.then(
                function(response) {
                    self.getNumbers();
                
                },function(error) {

            });
        };

        self.getNumbers = function() {
            PhoneService.getAvailableNumbersForRules(false,null)
            .then(function(list) {
                self.availablePhones.data = list;
                self.onRuleTypeChange();
            },function(error) {

            });
        };


        /*self.updateRedirectNumber = function() {

            if(self.rule.type == 'agent_rule' || self.rule.type == 'isp_rule')
            {
                self.rule.redirect_number = angular.fromJson(self.rule.user_id).phone;
            }
            else
            {
                self.rule.redirect_number = '';
            }
        };*/

        self.getSelectedDays = function() {

            self.rule.days_of_week = [];

            $('.day-chb').each(function(i,v) {
                if($(this).is(':checked'))
                {
                    self.rule.days_of_week.push($(this).attr('data-day'));
                }
            });
                
        };

        self.saveRule = function() {

            Spinner.start();

            if(self.rule.type == 'agent_rule' || self.rule.type == 'isp_rule')
            {
                self.rule.redirect_number = '';//we save it blank,
            }
            else
            {
                self.getSelectedDays();
                self.rule.user_id = null;
            }

            PhoneService.savePhoneRule(self.rule).then(
                function(response) {
                    Spinner.stop();
                    toaster.pop('success', "Success", 'Rule saved successfully');
                    $modalInstance.close();
                },
                function(error) {
                    Spinner.stop();
                    toaster.pop('error', "Error", error);
            });
        };

        self.cancel = function() {
            $modalInstance.dismiss('cancel');

        };

        self.onRuleTypeChange = function() {

            var forPeople = self.rule.type=="agent_rule" || self.rule.type=="isp_rule";
            
            self.filteredNumberList = [];

            $.each(self.availablePhones.data,function(i,v) {

                if(forPeople)
                {
                    if(v.user_id===null)
                    {
                        self.filteredNumberList.push(v);
                    }
                    else if(self.rule.id!==undefined && self.rule.id!==null && v.twilio_number===self.rule.to_number)
                    {
                        self.filteredNumberList.push(v);   
                    }
                }
                else
                {
                    self.filteredNumberList.push(v);
                }
            });
        };

        self.filterAvailableUsers = function() {

            angular.copy(self.usersList.data,self.filteredUserList);

            //if is editing
            if(self.rule.id!==undefined && self.rule.id!==null)
            {
                self.filteredUserList.push({
                    id: self.rule.user_id,
                    role: self.rule.user_role,
                    name: self.rule.username
                });   
            }            
        };

        constructor();

        self.renderSelectedDays();
    }
})();
