/**
 * Created by E on 07/03/2017.
 */

(function() {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ModalAddPaymentController
   *
   * @description
   * Controller - ModalAddPaymentController
   */
  angular
    .module("locksmithManager")
    .controller('PartialInvoiceController', [
      '$scope', '$modalInstance', 'description',
      function($scope, $modalInstance, description) {
        var newPartialInvoiceCtrl = this;

        newPartialInvoiceCtrl.description = angular.fromJson(description);
        
        
        /*
        newPartialInvoiceCtrl.setForm = function(form){
          $scope.paymentMethodForm = form;
        };
        newPartialInvoiceCtrl.add = function() {
          $scope.paymentMethodForm.$submitted = true;
          if ($scope.paymentMethodForm.$invalid)
            return;
          PaymentsModel.create({
            job_id: jobId,
            payment_type_id: newPartialInvoiceCtrl.paymentType.id,
            amount: newPartialInvoiceCtrl.amount
          }).then(function(data) {
            PaymentTypesModel.getPaymentType(data.payment_type_id).then(function(res) {
              data.payment_type = res;
              $modalInstance.close(data);
            });

          });
        };
        newPartialInvoiceCtrl.cancel = function() {
          $modalInstance.dismiss('cancel');
        };
        */
        /*
        app.controller('namesCtrl', function($scope) {
            $scope.names = ['Jani', 'Carl', 'Margareth', 'Hege', 'Joe', 'Gustav', 'Birgit', 'Mary', 'Kai'];
        });
        */

        newPartialInvoiceCtrl.close = function() {
          $modalInstance.dismiss('cancel');
        };

        newPartialInvoiceCtrl.beforeAuto = function(text) {
          return "hola";
        };

      }
    ])
    .filter('beforeAuto', function() {
        return function(texto) {
            var index = texto.indexOf('Automobile'); 
            if ( index !=(-1)){
              return texto.substring(0,index);
            } else {
              return texto;
            }
        };
    })
    .filter('afterAuto', function() {
        return function(texto) {
            var index = texto.indexOf('Automobile'); 
            if ( index !=(-1)){
              return texto.substring(index+11);
            } else {
              return texto;
            }
        };
    });
})();