(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.PhoneStatusController
   *
   * @description
   * This controller is responsible for displaying any queue information, and
   * to show the call status.
   */
  angular
    .module("locksmithManager")
    .controller('PhoneStatusController', [
      '$rootScope',
      'PhoneService',
      function ($rootScope, PhoneService) {

        var Ctr = this;


        Ctr.phone = PhoneService;

        Ctr.answerCall = function(sid, queue){
          console.log('sid', sid, queue);
          // check if phone phone is ready, if not, display a message
          if(!PhoneService.isAgentBusy()) {
            PhoneService.connectAgentTo(sid, queue);
          } else {
            swal('Please put current call on hold, before accepting this call');
          }
        };


      }
    ]);
})();