(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.SearchCbkController
   *
   * @description
   * Controller - SearchCbkController
   */
  angular
    .module("locksmithManager")
    .controller('SearchCbkController', SearchCbkController);

  SearchCbkController.$inject = ['DTOptionsBuilder', 'DTColumnBuilder', '$scope', 'DTInstances', '$stateParams'];

  function SearchCbkController(DTOptionsBuilder, DTColumnBuilder, $scope, DTInstances, $stateParams) {
    var searchCbkCtrl = this;

    searchCbkCtrl.search = search;
    searchCbkCtrl.phrase = "";

    init();

    searchCbkCtrl.dtOptions = DTOptionsBuilder.newOptions()
      .withOption("ajax", {
        url: "/api/callbacks",
        type: "GET",
        beforeSend: $scope.cbkCtrl.authHeader,
        data: function (data) {
          data.search.value = searchCbkCtrl.phrase;
          if($stateParams.status !== undefined && Number.isInteger(+$stateParams.status)) {
            data.status = $stateParams.status;
          }
        }
      })
      .withDataProp('data')
      .withOption('processing', true)
      .withOption('serverSide', true)
      .withOption('bFilter', false)
      .withOption('createdRow', $scope.cbkCtrl.createdRow)
      .withPaginationType('simple_numbers')
      //.withBootstrap()
      .withOption('order', [1, 'asc'])
      .withLanguage({
        "sProcessing": $scope.mainCtr.processingHtml
      });

    searchCbkCtrl.dtColumns = [
      DTColumnBuilder.newColumn('record_number').withTitle('Record number')
        .notSortable().withClass('record-number'),

      DTColumnBuilder.newColumn('name').withTitle('Name'),

      DTColumnBuilder.newColumn('phone').withTitle('Phone')
        .renderWith($scope.cbkCtrl.phoneFilter).notSortable().withClass('phone'),

      DTColumnBuilder.newColumn('comments').withTitle('Short Description')
        .notSortable().renderWith($scope.cbkCtrl.charactersFilter),

      DTColumnBuilder.newColumn(null).withTitle('')
        .notSortable().renderWith($scope.cbkCtrl.actionsHtml).withClass('actions')
    ];

    function init() {
    }

    function search(phrase) {
      DTInstances.getLast().then(function(dtInstance) {
        dtInstance.reloadData();
      });
    }
  }
})();