(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.EmailSettingModel
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('EmailSettingModel', [
      '$http', 'toaster','$q',
      function ($http, toaster, $q) {

        var model = this,
          URLS = {
            FETCH: '/api/settings',
            EDIT: '/api/settings',
            DELETE: '',
            POST: '',
            SEND_TEST_MAIL: '/api/settings/mail',
          };

        model.activeEmailGroup = {
          id: null,
          group_name: null,
          group_parent_id: null,
          settings: []
        };


        function extract(result) {
          return result.data[0];
        }

        function throwError(message) {
          toaster.pop('error', "Error", message);
        }

        model.setEmailGroup = function (data) {
          if(typeof (data) != 'undefined') {
            model.activeEmailGroup.id = data.id;
            model.activeEmailGroup.group_name = data.group_name;
            model.activeEmailGroup.group_parent_id = data.group_parent_id;
            model.activeEmailGroup.settings = data.settings;
          }
        };

        model.resetEmailGroup = function () {
          model.activeEmailGroup.id = null;
          model.activeEmailGroup.lead_title = null;
          model.activeEmailGroup.lead_description = null;
        };

        model.updateSetting = function (setting) {

          var setting_id = setting.id;
          return $http.put(URLS.EDIT + '/' + model.activeEmailGroup.id + '/' + setting_id,
            {value: setting.value})
            .then(function (data) {
              // model.getPhoneSettings();

              toaster.pop('success', "Success", "Setting updated", 2000);

            }, function error(err, data) {

              // Throw error;

              throwError('Unable to update the setting at this time, please try again later');

            });
        };

        model.getEmailGroup = function (group_id) {
          return $http.get(URLS.FETCH + '/' + group_id).then(extract);
        };

        model.sendTestMail = function (data) {
          var deferred = $q.defer();
          $http.post(URLS.SEND_TEST_MAIL, data).then(function (data) {
              deferred.resolve(data);
          }, function error(err, data) {
              deferred.reject(err);
          });
          return deferred.promise;
        };
      }
    ]);
})();