(function () {
  'use strict';
  /**
   * @ngdoc provider value
   * @name locksmithManager.provider.phoneKeyMap
   *
   * @description
   * Mapping state urls to settings id and appropriate templates
   */

  angular
    .module("locksmithManager")
    .value('phoneKeyMap', {
      "basic": {
        id: "2",
        template: "/app/views/settings/phone/settings/basic.html"
      },
      "numbers": {
        id: "3",
        template: "/app/views/settings/phone/settings/numbers.html"
      },
      "calls": {
        id: "4",
        template: "/app/views/settings/phone/settings/calls.html"
      },
      "recording": {
        id: "5",
        template: "/app/views/settings/phone/settings/recording.html"
      }
    });

  /**
   * @ngdoc Filter
   * @name component.Filter.mapPhoneKey
   *
   * @description
   * Returns an role object based on role key
   */
  angular
    .module("locksmithManager")
    .filter("mapPhoneKey", [
      'phoneKeyMap',
      function (phoneKeyMap) {

        return function (input) {
          return phoneKeyMap[input];
        };

      }
    ]);

  /**
   * @ngdoc Filter
   * @name component.Filter.dependentEnabled
   *
   * @description
   * Returns an role object based on role key
   */
  angular
    .module("locksmithManager")
    .filter("dependentEnabled", [
      '$filter',
      function ($filter) {

        return function (input, collections) {
          var setting = $filter('filter')(collections, {key: input}, true)[0];
          return setting.value === '1';
        };

      }
    ]);

})();