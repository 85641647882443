(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.DashboardController
   *
   * @description
   * Contoller - DashboardController
   */
  angular
    .module("locksmithManager")
    .controller('DashboardController', [
      '$rootScope', '$scope', '$state', '$http', '$timeout', '$ocLazyLoad',
      function ($rootScope, $scope, $state, $http, $timeout, $ocLazyLoad) {

        //Removing the Dashboard for the moment
        $state.go('jobs.search');

        $scope.$on('$viewContentLoaded', function () {
          // initialize core components
          Metronic.initAjax();
        });

        // set sidebar closed and body solid layout mode
        $rootScope.settings.layout.pageBodySolid = true;
        $rootScope.settings.layout.pageSidebarClosed = false;


        $scope.chartInterval = 'today';


        $scope.updateChart = function (value) {
          $scope.chartInterval = value;
          //console.log(value);
          switch (value) {
            case 'today':
              $scope.chart_options.data =
                [
                  {
                    period: '2011 Q1',
                    sales: 1400,
                    profit: 400
                  }, {
                  period: '2011 Q2',
                  sales: 1100,
                  profit: 600
                }, {
                  period: '2011 Q3',
                  sales: 1600,
                  profit: 500
                }, {
                  period: '2011 Q4',
                  sales: 1200,
                  profit: 400
                }, {
                  period: '2012 Q1',
                  sales: 1550,
                  profit: 800
                }
                ];
              break;

            case 'week':
              $scope.chart_options.data =
                [
                  {
                    period: '2011 Q1',
                    sales: 4000,
                    profit: 100
                  }, {
                  period: '2011 Q2',
                  sales: 110,
                  profit: 1600
                }, {
                  period: '2011 Q3',
                  sales: 1300,
                  profit: 500
                }, {
                  period: '2011 Q4',
                  sales: 1500,
                  profit: 1000
                }, {
                  period: '2012 Q1',
                  sales: 50,
                  profit: 300
                }
                ];
              break;

            case 'month':
              $scope.chart_options.data =
                [
                  {
                    period: '2011 Q1',
                    sales: 1400,
                    profit: 400
                  }, {
                  period: '2011 Q2',
                  sales: 1100,
                  profit: 600
                }, {
                  period: '2011 Q3',
                  sales: 1600,
                  profit: 500
                }, {
                  period: '2011 Q4',
                  sales: 1200,
                  profit: 400
                }, {
                  period: '2012 Q1',
                  sales: 1550,
                  profit: 800
                }
                ];
              break;
          }

        };


        // Chart?
        $scope.chart_options = {
          element: 'sales_statistics',
          padding: 0,
          behaveLikeLine: false,
          gridEnabled: false,
          gridLineColor: false,
          axes: false,
          fillOpacity: 1,
          data: [
            {
              period: '2011 Q1',
              sales: 1400,
              profit: 400
            }, {
              period: '2011 Q2',
              sales: 1100,
              profit: 600
            }, {
              period: '2011 Q3',
              sales: 1600,
              profit: 500
            }, {
              period: '2011 Q4',
              sales: 1200,
              profit: 400
            }, {
              period: '2012 Q1',
              sales: 1550,
              profit: 800
            }
          ],
          lineColors: ['#399a8c', '#92e9dc'],
          xkey: 'period',
          ykeys: ['sales', 'profit'],
          labels: ['Sales', 'Profit'],
          pointSize: 0,
          lineWidth: 0,
          hideHover: 'auto',
          resize: true
        };
      }
    ]);
})();