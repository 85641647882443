(function () {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.IndexUsersController
     *
     * @description
     * Contoller - IndexUsersController
     */
    angular
      .module("locksmithManager")
      .controller('IndexUsersController', [
          '$q', '$scope', '$stateParams', '$state', '$timeout', 'UsersModel', 'DTOptionsBuilder',
          function ($q, $scope, $stateParams, $state, $timeout, usersModel, DTOptionsBuilder) {
              /**
               * @property {Object} userIndexCtrl
               * Holds current controller instance to avoid scope
               */
              var userIndexCtrl = this;
              /**
               * [users description]
               * @True {[type]}
               */

              usersModel.getUsers().then(
                function(data) {
                    $scope.users = data.data;
                }
              );

              $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withBootstrap();

              /**
               * [init description]
               * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
               * @return {[type]} [description]
               */
              userIndexCtrl.init = function () {

              };

              /**
               * findRole Returns user's role by user's role id
               * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
               * @param  {[Interger]} roleId User's role Id
               * @access public
               * @return {[Object]}   User's role object
               */
              $scope.findRole = function (roleId) {
                  return usersModel.findRole(roleId);
              };

              /**
               * [delete description]
               * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
               * @param  {[type]} user_id [description]
               * @return {[type]}         [description]
               */
              $scope.delete = function(user_id){
                  if(confirm("Do you really want to delete this user")){
                      usersModel.deleteUser(user_id);
                  }
                  return;
              };
              /**
               * Invoke init function when controller has initialized
               */
              userIndexCtrl.init();
          }
      ]);
})();