(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.EditSettingsController
   *
   * @description
   * Controller - EditSettingsController
   */
  angular
    .module("locksmithManager")
    .controller('EditServiceAreaController', EditServiceAreaController);

  EditServiceAreaController.$inject = ['$scope', '$stateParams', 'ServiceAreaModel'];

  function EditServiceAreaController($scope, $stateParams, ServiceAreaModel) {
    var editCtr = this;

    $scope.srvAreaCtr.setActiveServiceArea($stateParams.service_area_id).then(function () {
      editCtr.editServiceArea = angular.copy(ServiceAreaModel.activeServiceArea);
    });

    editCtr.update = function () {
      ServiceAreaModel.update(editCtr.editServiceArea)
        .then(function () {
          $scope.srvAreaCtr.init();
          $scope.srvAreaCtr.alertSuccess('Service area updated');
        })
        .catch(function () {
          $scope.srvAreaCtr.alertError('Unable to update the service area this time, please try again later');
        });
    };
  }
})();