(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ModalRecoveryPasswordController
   *
   * @description
   * Contoller - ModalRecoveryPasswordController
   */
  angular
    .module("locksmithManager")
    .controller('ModalRecoveryPasswordController', [
      '$modalInstance', 'email', '$http', '$rootScope',
      function ($modalInstance, email, $http, $rootScope) {
        var recoveryCtr = this;

        recoveryCtr.email = email;
        recoveryCtr.error = false;
        recoveryCtr.message = '';

        recoveryCtr.back = function () {
          $modalInstance.dismiss('back');
        };

        recoveryCtr.submit = function () {
          recoveryCtr.error = false;
          $rootScope.loadingSpinner = true;

          //extract to service
          $http.post('/password/email', {
            'email': recoveryCtr.email
          }).
            success(function (data) {
              if (data.email !== undefined) { //-email is not found on system
                recoveryCtr.error = true;
                recoveryCtr.message = data.email;
              }
              else {
                $modalInstance.close({
                  'message': data.status,
                  'email': recoveryCtr.email
                });
              }
              $rootScope.loadingSpinner = false;

            })
            .error(function (data) {
              //invalid e-mail
              recoveryCtr.error = true;
              recoveryCtr.message = data.email[0];
              $rootScope.loadingSpinner = false;
            });
        };
      }
    ]);
})();