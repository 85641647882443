(function () {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('ServiceAreasController', ServiceAreasController);

  ServiceAreasController.$inject = ['ServiceAreaModel', '$state', '$q', 'toaster'];

  /* @ngInject */
  function ServiceAreasController(ServiceAreaModel, $state, $q, toaster) {
    /* jshint validthis: true */
    var srvAreaCtr = this;

    srvAreaCtr.init = init;
    srvAreaCtr.remove = remove;
    srvAreaCtr.setActiveServiceArea = setActiveServiceArea;
    srvAreaCtr.alertSuccess = alertSuccess;
    srvAreaCtr.alertError = alertError;

    init();

    ////////////////

    function init() {
      srvAreaCtr.loadingBaseState = false;
      srvAreaCtr.serviceAreas = ServiceAreaModel.serviceAreas;

      ServiceAreaModel.get();
      ServiceAreaModel.resetActiveServiceArea();
      if ($state.current.name != "settings.base.service_areas") {
        srvAreaCtr.loadingBaseState = true;
        $state.go('settings.base.service_areas');
      }
    }

    function remove(id) {
      ServiceAreaModel.remove(id)
        .then(function () {
          srvAreaCtr.init();
          srvAreaCtr.alertSuccess('Service area deleted');
        })
        .catch(function () {
          srvAreaCtr.alertError('Unable to delete the service area at this time, please try again later');
        });
    }

    function setActiveServiceArea(id) {
      var deffered = $q.defer();

      ServiceAreaModel.setActiveServiceArea(findServiceArea(id));
      srvAreaCtr.activeServiceArea = ServiceAreaModel.activeServiceArea;

      deffered.resolve(true);
      return deffered.promise;
    }

    function findServiceArea(id) {
      for (var i = 0; i < srvAreaCtr.serviceAreas.data.length; i++) {
        if (srvAreaCtr.serviceAreas.data[i].id === +id) {
          return srvAreaCtr.serviceAreas.data[i];
        }
      }
    }

    function alertSuccess(message) {
      toaster.success("Success", message, 2000);
    }

    function alertError(message) {
      toaster.error("Error", message, 2000);
    }
  }
})();