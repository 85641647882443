(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.AllCmplController
   *
   * @description
   * Controller - AllCmplController
   */
  angular
    .module("locksmithManager")
    .controller('DetailCmplController', DetailCmplController);

  DetailCmplController.$inject = ['ComplainsModel', '$stateParams', '$scope', 'taOptions', 'notes'];

  function DetailCmplController(ComplainsModel, $stateParams, $scope, taOptions, notes) {
    var detailCmplCtr = this,
      id = $stateParams.complainId;

    detailCmplCtr.complain = {
      data: [],
      history: []
    };

    detailCmplCtr.notes = notes;
    detailCmplCtr.cmpl = {};

    detailCmplCtr.message = '';

    // Job Status Methods used to display/hide elements
    detailCmplCtr.status = {
      isOpen: statusIsOpen,
      isResolved: statusIsResolve,
      isCancelled: statusIsCancel
    };

    detailCmplCtr.update = update;
    detailCmplCtr.resolve = resolve;
    detailCmplCtr.cancel = cancel;

    var removeInternationalCode = function (phone) {
      if (phone[0] === "+" && phone[1] === "1") {
        return phone.slice(2);
      }
    };

    function init() {

      detailCmplCtr.complain = ComplainsModel.complain;

      getComplain();

      //Can Customizate text-angular
      taOptions.toolbar = [
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
        ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
        ['html', 'insertImage','insertLink', 'insertVideo', 'wordcount', 'charcount']
      ];
    }

    function getComplain() {
      $scope.mainCtr.showSpinner();
      getHistory();
      ComplainsModel.showComplain(id).then(function (data) {
        detailCmplCtr.complain.data.phone = removeInternationalCode(detailCmplCtr.complain.data.phone);
        detailCmplCtr.complain.data.caller_id = removeInternationalCode(detailCmplCtr.complain.data.caller_id);
        angular.copy(detailCmplCtr.complain.data, detailCmplCtr.cmpl);
        $scope.mainCtr.hideSpinner();
      }, function (data) {
        $scope.mainCtr.alertError(data.data.error);
        $scope.mainCtr.hideSpinner();
      });
    }

    function update(message) {
      $scope.mainCtr.showSpinner();
      ComplainsModel.updateComplain(id, detailCmplCtr.complain.data, message)
        .success(function (data, status) {
          if (status === 200) {
            $scope.mainCtr.alertSuccess("Changes successfully applied!");
          }
          $scope.mainCtr.hideSpinner();
          return getComplain();
        })
        .error(function (data) {
          $scope.mainCtr.alertError(data.error);
          $scope.mainCtr.hideSpinner();
          return getComplain();
        });
    }

    function statusIsOpen() {
      return +detailCmplCtr.cmpl.status === 0;
    }

    function statusIsResolve() {
      return +detailCmplCtr.cmpl.status === 1;
    }

    function statusIsCancel() {
      return +detailCmplCtr.cmpl.status === 2;
    }

    function resolve() {
      detailCmplCtr.complain.data.status = 1;
      update(detailCmplCtr.message);
    }

    function cancel() {
      detailCmplCtr.complain.data.status = 2;
      update(detailCmplCtr.message);
    }

    function getHistory() {
      ComplainsModel.getHistory(id);
    }

    init();
  }
})();