/*jshint multistr: true */
(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.JobController
   *
   * @description
   * Contoller - JobController
   */
  angular
    .module("locksmithManager")
    .controller('JobController', JobController);

  JobController.$inject = [
    '$scope', '$state', 'AuthService', 'JobsModel', 'statuses',
    'statistics', 'tables', 'history', 'DTInstances', '$filter', '$compile',
    'store', '$modal','ComplainsModel','CallbacksModel'];

  function JobController($scope, $state, AuthService, JobsModel, statuses,
                        statistics, tables, history, DTInstances, $filter,
                        $compile, store, $modal,ComplainsModel,CallbacksModel) {

    var jobCtrl = this;

    jobCtrl.viewJobDetails = viewJobDetails;
    jobCtrl.viewNewJobPage = viewNewJobPage;
    jobCtrl.isAdmin = AuthService.isAdmin;
    jobCtrl.isDispatcher = AuthService.isDispatcher;
    jobCtrl.isSales = AuthService.isSales;
    jobCtrl.isTechnician = AuthService.isTechnician;
    jobCtrl.getTextStatus = getTextStatus;

    jobCtrl.alertInfo = $scope.mainCtr.alertInfo;
    jobCtrl.alertError = $scope.mainCtr.alertError;
    jobCtrl.alertSuccess = $scope.mainCtr.alertSuccess;

    jobCtrl.showSpinner = $scope.mainCtr.showSpinner;
    jobCtrl.hideSpinner = $scope.mainCtr.hideSpinner;

    jobCtrl.jobStats = [];

    jobCtrl.history = [];
    jobCtrl.recentJobs = [];
    jobCtrl.inProgressJobs = [];
    jobCtrl.outstandingJobs = [];
    jobCtrl.statuses = statuses;
    jobCtrl.statistics = statistics;
    jobCtrl.tables = tables.data;
    jobCtrl.history = history.data;

    jobCtrl.needReload = true;

    jobCtrl.remove = remove;

    jobCtrl.authHeader = authHeader;
    jobCtrl.createdRow = createdRow;
    jobCtrl.actionsHtml = actionsHtml;
    jobCtrl.phoneFilter = phoneFilter;
    jobCtrl.charactersFilter = charactersFilter;
    jobCtrl.amCalendarFilter = amCalendarFilter;
    jobCtrl.statusHtml = statusHtml;

    init();

    //////////////////////////////

    function init() {
      if ($state.is('jobs')) {
        jobCtrl.needReload = false;
      }

      $scope.$watch('$state.current', function () {
        if ($state.is('jobs')) {
          var promiseReload;

          if (jobCtrl.needReload) {
            promiseReload = $state.reload();
          } else {
            jobCtrl.needReload = true;
          }

          if (promiseReload !== undefined) {
            promiseReload.then(function () {
              $scope.$watch('::tables', function () {
                alertOutstandingJobs();
              });
            });
          } else {
            alertOutstandingJobs();
          }
        }
      });
    }

    function alertOutstandingJobs() {
      if (tables.data.outstanding.length !== 0) {
        jobCtrl.alertInfo("We have outstanding jobs!", 2000);
      }
    }

    function viewJobDetails(id) {
      $state.go('jobs.detail', {jobId: id});
    }

    function viewNewJobPage(id) {
      $state.go('jobs.new');
    }

    function getTextStatus(status) {
      return JobsModel.getTextStatus(status);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function createdRow(row) {
      // Recompiling so we can bind Angular directive to the DT
      $compile(angular.element(row).contents())($scope);
    }

    function actionsHtml(data) {
      
        var html = "";

        if(data.status==2)//in progress
        {
          html = '<div class="btn-group">\
              <button type="button" class="btn btn-fit-height grey-salt dropdown-toggle" data-toggle="dropdown"\
                      data-hover="dropdown" data-delay="1000" data-close-others="true">\
                  Actions <i class="fa fa-angle-down"></i>\
              </button>\
              <ul class="dropdown-menu pull-right" role="menu">\
                  <li>\
                      <a class="default" data-ng-click="jobCtrl.viewJobDetails(' +
        data.id + ')"><i class="fa fa-search"></i> View</a>\
                  </li>\
                  <li>\
                      <a class="list-group-item-success" data-ng-click="jobCtrl.assignISP(' +
          data.id + ')"><i class="fa fa-check"></i> Assign ISP</a>\
                  </li>\
                  <li class="">\
                    <a class="list-group-item-danger filter-cancel" data-ng-click="jobCtrl.cancelJob(' +
          data.id + ')"><i class="fa fa-times"></i> Cancel Job</a>\
                  </li>\
              </ul>\
          </div>';
        }
        else if(data.status==3)//completed
        {
          html = '<div class="btn-group">\
              <button type="button" class="btn btn-fit-height grey-salt dropdown-toggle" data-toggle="dropdown"\
                      data-hover="dropdown" data-delay="1000" data-close-others="true">\
                  Actions <i class="fa fa-angle-down"></i>\
              </button>\
              <ul class="dropdown-menu pull-right" role="menu">\
                  <li>\
                      <a class="default" data-ng-click="jobCtrl.viewJobDetails(' +
        data.id + ')"><i class="fa fa-search"></i> View</a>\
                  </li>\
                  <li>\
                      <a class="list-group-item-success" data-ng-click="jobCtrl.callback(' +
          data.id + ')"><i class="fa fa-check"></i> Call Back</a>\
                  </li>\
                  <li class="">\
                    <a class="list-group-item-danger" data-ng-click="jobCtrl.complaint(' +
          data.id + ')"><i class="fa fa-times"></i> Complaint</a>\
                  </li>\
              </ul>\
          </div>';
        }
        else
        {
          html = '<div class="actions">' +'<a class="btn btn-xs default btn-editable" data-ng-click="jobCtrl.viewJobDetails(' +
          data.id + ')"><i class="fa fa-search"></i> View</a></div>';
        }


      /*if (jobCtrl.isAdmin()) {
        html += '<a data-ng-show="jobCtrl.isAdmin()" class="btn btn-xs red filter-cancel" ' +
        'data-ng-click="jobCtrl.remove(' + data.id + ')"><i class="fa fa-times"></i>Delete</a>';
      }*/

      //html += '';
      return html;
    }

    function statusHtml(data, type) {
      return '<span data-entity-status ' +
        'data-entity-id=' + data + ' ' +
        'data-entity-type="job">' +
        '</span>';
    }

    function phoneFilter(data) {
      return $filter('phone')(data);
    }

    function charactersFilter(data) {
      return $filter('characters')(data, 120);
    }

    function amCalendarFilter(data) {
      return $filter('amCalendar')(data);
    }

    function authHeader(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + store.get('lsmToken'));
    }

    function remove(id) {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'removeModal.html',
        controller: 'ModalRemove',
        controllerAs: 'vm',
        resolve: {
          object: function () {
            return {
              name: 'complain'
            };
          }
        }
      });

      modalInstance.result.then(function () {
        JobsModel.remove(id)
          .success(function (data, status) {
            if (status === 200) {
              jobCtrl.alertSuccess('Job was deleted');
              update();
            }
          })
          .error(function (data, status) {
            if (status === 500) {
              jobCtrl.alertError('We have some problem on server');
              jobCtrl.hideSpinner();
            }
            else {
              if (status === 401) {
                jobCtrl.alertError(data.error);
                jobCtrl.hideSpinner();
              }
            }
          });
      });
    }

    function update() {
      DTInstances.getLast().then(function (dtInstance) {          
        // dtInstance.reloadData();
        jobCtrl.hideSpinner();
      });
      JobsModel.getStatistics();
    }

    jobCtrl.cancelJob = function(id) 
    {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/jobs/partials/cancelJobModal.html',
        controller: 'CancelJobModalController',
        controllerAs: 'cjc',
        /*//TO PASS PARAMS TO THE DIALOG
        resolve: {
          object: function () {
            return {
              'id': id
            };
          }
        }  */   
      });

      modalInstance.result.then(function (modalData) 
      {
        jobCtrl.showSpinner();

        JobsModel.cancel(id,modalData.cancelText)
          .then(
            //Function when all is ok
            function (data) 
            {
              jobCtrl.alertSuccess('Job was canceled');
              update();
            },
            //Function when something wrong happened
            function (data) {
              jobCtrl.alertError(data.error);
              jobCtrl.hideSpinner();
            }
          );
      });
    };

    jobCtrl.assignISP = function(id) 
    {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/jobs/partials/assingISPJobModal.html',
        controller: 'AssingISPJobModalController',
        controllerAs: 'assingJMC',
        /*//TO PASS PARAMS TO THE DIALOG
        resolve: {
          object: function () {
            return {
              'id': id
            };
          }
        }  */   
      });

      modalInstance.result.then(function (modalData) 
      {
          jobCtrl.showSpinner();

          JobsModel.assignISP(id,modalData.technicianId)
          .then(function (data) 
          {
            jobCtrl.alertSuccess('The ISP was assigned');
            update();
          },
          //Function when something wrong happened
          function (data) {
            jobCtrl.alertError(data.error);
            jobCtrl.hideSpinner();
          });
      });
    };

    jobCtrl.complaint = function(id) 
    {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/jobs/partials/newJobComplaintModal.html',
        controller: 'NewJobComplaintModalController',
        controllerAs: 'cjc',
        /*//TO PASS PARAMS TO THE DIALOG
        resolve: {
          object: function () {
            return {
              'id': id
            };
          }
        }  */   
      });

      modalInstance.result.then(function (modalData) 
      {
          jobCtrl.showSpinner();

          JobsModel.show(id).then(function (data) {
            ComplainsModel.createFromJob(data,modalData)
            .then(function (data) 
            {
              jobCtrl.alertSuccess('The complaint was sent');
              update();
            },
            //Function when something wrong happened
            function (data) {
              jobCtrl.alertError(data.error);
              jobCtrl.hideSpinner();
            });
          });

          
      });
    };

    jobCtrl.callback = function(id) 
    {
        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'app/views/jobs/partials/newJobCallbackModal.html',
          controller: 'NewJobCallbackModalController',
          controllerAs: 'cjc',
          /*//TO PASS PARAMS TO THE DIALOG
          resolve: {
            object: function () {
              return {
                'id': id
              };
            }
          }  */   
        });

        modalInstance.result.then(function (modalData) 
        {
          jobCtrl.showSpinner();

          JobsModel.show(id).then(function (data) {
            CallbacksModel.createFromJob(data,modalData)
              .then(function (data) 
            {
              jobCtrl.alertSuccess('New Job Created');
              update();
            },
            //Function when something wrong happened
            function (data) {
              jobCtrl.alertError(data.error);
              jobCtrl.hideSpinner();
            });
          });
        });
    };
  }
})();