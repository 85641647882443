(function() {
    'use strict';
    /**
     * @ngdoc Service
     * @name locksmithManager.Service.PhoneService
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .service('PhoneSettingModel', [
            '$http', '$q', 'toaster',
            function($http, $q, toaster) {

                var model = this,
                    URLS = {
                        FETCH: '/api/settings',
                        EDIT: '/api/settings',
                        DELETE: '',
                        POST: ''
                    };

                model.activePhoneGroup = {
                    id: null,
                    group_name: null,
                    group_parent_id: null,
                    settings: []
                };

                model.isPhoneEnabled = {
                    data: false
                };


                function extract(result) {
                    return result.data[0];
                }

                function throwError(message) {
                    toaster.pop('error', "Error", message);
                }

                model.setPhoneGroup = function(data) {
                    model.activePhoneGroup.id = data.id;
                    model.activePhoneGroup.group_name = data.group_name;
                    model.activePhoneGroup.group_parent_id = data.group_parent_id;
                    model.activePhoneGroup.settings = data.settings;
                };

                model.resetPhoneGroup = function() {
                    model.activePhoneGroup.id = null;
                    model.activePhoneGroup.lead_title = null;
                    model.activePhoneGroup.lead_description = null;
                };

                model.updateSetting = function(setting) {
                    var setting_id = setting.id;
                    return $http.put(URLS.EDIT + '/' + model.activePhoneGroup.id + '/' + setting_id, { value: setting.value })
                        .then(function(data) {
                            //check if phone is enabled
                            model.checkPhoneEnabled();
                            toaster.pop('success', "Success", "Setting updated", 2000);
                        }, function error(err, data) {
                            throwError('Unable to update the setting at this time, please try again later');
                        });
                };

                model.togglePhoneEnabled = function(bool) {
                    model.isPhoneEnabled.data = bool;
                };

                model.checkPhoneEnabled = function() {
                    var deffered = $q.defer();
                    $http.get(URLS.FETCH + '/2').then(function(data) {
                        var group = extract(data);
                        if (group.settings[0].value == '0') {
                            model.togglePhoneEnabled(false);
                            deffered.resolve(false);
                        } else {
                            model.togglePhoneEnabled(true);
                            deffered.resolve(true);
                        }
                    });
                    return deffered.promise;
                };

                model.getOutgoingPhoneNumber = function(){
                  return $http.get(URLS.FETCH + '/3').then(function(data) {
                    return data.data[0].settings[1].value ;
                  });
                };

                model.getPhoneGroup = function(group_id) {
                    return $http.get(URLS.FETCH + '/' + group_id).then(extract);
                };


            }
        ]);
})();
