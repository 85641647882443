/**
 * Created by nikolay on 3/4/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.DetailGrqController
   *
   * @description
   * Controller - DetailGrqController
   */
  angular
    .module("locksmithManager")
    .controller('DetailGrqController', DetailGrqController);

  DetailGrqController.$inject = ['GeneralRequestsModel', '$stateParams', 'taOptions', 'notes', '$scope'];

  function DetailGrqController(GeneralRequestsModel, $stateParams, taOptions, notes, $scope) {
    var detailGrqCtrl = this,
      id = $stateParams.generalRequestId;

    detailGrqCtrl.generalRequest = {
      data: [],
      history: []
    };

    detailGrqCtrl.notes = notes;
    detailGrqCtrl.gnrl = {};

    detailGrqCtrl.message = '';

    // Job Status Methods used to display/hide elements
    detailGrqCtrl.status = {
      isOpen: statusIsOpen,
      isResolved: statusIsResolve,
      isCancelled: statusIsCancel
    };

    detailGrqCtrl.update = update;
    detailGrqCtrl.resolve = resolve;
    detailGrqCtrl.cancel = cancel;

    function removeInternationalCode(phone) {
      if (phone[0] === "+" && phone[1] === "1") {
        return phone.slice(2);
      }
    }

    function init() {
      detailGrqCtrl.generalRequest = GeneralRequestsModel.generalRequest;

      getGeneralRequest();

      //Can Customizate text-angular
      taOptions.toolbar = [
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
        ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
        ['html', 'insertImage', 'insertLink', 'insertVideo', 'wordcount', 'charcount']
      ];
    }

    function update(message) {
      $scope.mainCtr.showSpinner();
      GeneralRequestsModel.updateGeneralRequest(id, detailGrqCtrl.generalRequest.data, message)
        .success(function (data, status) {
          if (status === 200) {
            $scope.mainCtr.alertSuccess("Changes successfully applied!");
          }
          $scope.mainCtr.hideSpinner();
          return getGeneralRequest();
        })
        .error(function (data) {
          $scope.mainCtr.alertError(data.error);
          $scope.mainCtr.hideSpinner();
          return getGeneralRequest();
        });
    }

    function getGeneralRequest() {
      $scope.mainCtr.showSpinner();
      getHistory();
      detailGrqCtrl.generalRequest = GeneralRequestsModel.generalRequest;
      GeneralRequestsModel.showGeneralRequest(id).then(function () {
        detailGrqCtrl.generalRequest.data.phone = removeInternationalCode(detailGrqCtrl.generalRequest.data.phone);
        detailGrqCtrl.generalRequest.data.caller_id = removeInternationalCode(detailGrqCtrl.generalRequest.data.caller_id);
        angular.copy(detailGrqCtrl.generalRequest.data, detailGrqCtrl.gnrl);
        $scope.mainCtr.hideSpinner();
      }, function (data) {
        $scope.mainCtr.alertError(data.data.error);
        $scope.mainCtr.hideSpinner();
      });
    }

    function statusIsOpen() {
      return +detailGrqCtrl.gnrl.status === 0;
    }

    function statusIsResolve() {
      return +detailGrqCtrl.gnrl.status === 1;
    }

    function statusIsCancel() {
      return +detailGrqCtrl.gnrl.status === 2;
    }

    function resolve() {
      detailGrqCtrl.generalRequest.data.status = 1;
      update(detailGrqCtrl.message);
    }

    function cancel() {
      detailGrqCtrl.generalRequest.data.status = 2;
      update(detailGrqCtrl.message);
    }

    function getHistory() {
      GeneralRequestsModel.getHistory(id);
    }

    init();
  }
})();