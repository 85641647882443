(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.TypeController
   *
   * @description
   * Contoller - Type Controller
   */
  angular
    .module("locksmithManager")
    .controller('TypeController', [
      '$scope', '$stateParams',
      function ($scope, $stateParams) {
        var typeCtr = this;

        $scope.srvcCtr.setActiveService($stateParams.service_id).then(function () {
          $scope.srvcCtr.setActiveServiceType($stateParams.service_type_id);
        });
      }
    ]);
})();