(function () {
  'use strict';
  /**
   * Create a component module
   * All directive and filter will be available to this module
   */
  angular.module('component', []);

  /**
   * Create Application mail module
   * @name locksmithManager
   * @requires following module to load dependencies
   * [
   "ui.router",
   "ui.bootstrap",
   "oc.lazyLoad",
   "ngSanitize",
   "angularMoment",
   "ChartAngular",
   "ui.mask",
   "toaster",
   "pusher-angular",
   'angular-jwt',
   'angular-storage',
   "component"
   ]
   */
  angular
    .module("locksmithManager", [
      "ui.router",
      "ui.bootstrap",
      "oc.lazyLoad",
      "ngSanitize",
      "angularMoment",
      // "ChartAngular",
      "ui.mask",
      "toaster",
      "pusher-angular",
      'angular-jwt',
      'angular-storage',
      "component",
      "truncate",
      "ui.validate",
      "uiGmapgoogle-maps",
      "xeditable",
      'ngResource',
      'ui.slimscroll',
      'ui.select',
      'textAngular',
      'ngIdle',
      'frapontillo.bootstrap-switch',
    ]);

  /**
   * @ngdoc Service
   * @name locksmithManager.Service.settings
   *
   * @description
   * Creates a factory service which shared data globaly to application.
   *
   * @returns {Object}  Settings
   */
  angular
    .module("locksmithManager")
    .factory('settings', [
      '$rootScope',
      function ($rootScope) {

        /**
         * settings
         * @description
         * Setup layout setting and assets url
         * Assets layoutImgPath, layoutCssPath are used to use image or css files
         * @True {Object}
         */
        var settings = {
          layout: {
            pageSidebarClosed: false, // sidebar menu state
            pageBodySolid: false // solid body color state
          },
          layoutImgPath: Metronic.getAssetsPath() + 'admin/layout/img/',
          layoutCssPath: Metronic.getAssetsPath() + 'admin/layout/css/'
        };

        $rootScope.settings = settings;

        return settings;
      }
    ]);

  /**
   * Angular run block
   * Here configuration can be done at the top of application
   */
  angular
    .module("locksmithManager")
    .run([
      "$rootScope", "settings", "$state", "editableOptions",
      function ($rootScope, settings, $state,editableOptions) {
        // state to be accessed from view
        $rootScope.$state = $state;
        editableOptions.theme = 'bs3';
      }
    ])

    .config(function($provide){
      $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function(taRegisterTool, taOptions){
        // $delegate is the taOptions we are decorating
        // register the tool with textAngular
        taOptions.toolbar = [
          ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
          ['bold', 'italics', 'underline', 'ul', 'ol', 'redo', 'undo', 'clear'],
          ['justifyLeft','justifyCenter','justifyRight']
        ];

        // add the button to the default toolbar definition
//        taOptions.toolbar[1].push('colourRed');
        return taOptions;
      }]);
    });
})();
