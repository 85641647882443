(function() {
    'use strict';
    /**
     * @ngdoc provider value
     * @name locksmithManager.provider.validationRegex
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .value('validationRegex', {
            any: /^/
        });

    /**
     * @ngdoc provider value
     * @name locksmithManager.provider.roles
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .value('roles', [
            "Admin",
            "Tech",
            "Sales",
            "Dispatch"
        ]);

    angular
        .module("locksmithManager")
        .value('statuses', (function() {
            var statuses = [],
                titles = [
                    "Open",
                    "Scheduled",
                    "Progress",
                    "Closed",
                    "Pending",
                    "Cancel"
                ],
                title = '',
                i;

            for (i = 0; i < titles.length; i++) {
                title = titles[i];
                statuses.push({
                    id: i,
                    title: title
                });
            }

            for (i = 0; i < titles.length; i++) {
                title = titles[i];
                statuses[title] = {
                    id: i,
                    title: title
                };
            }
            return statuses;
        })());

    angular
        .module("locksmithManager")
        .value('statusesEntity', (function() {
            var statuses = [],
                titles = [
                    "Open",
                    "Resolved",
                    "Cancelled"
                ],
                title = '',
                i;

            for (i = 0; i < titles.length; i++) {
                title = titles[i];
                statuses.push({
                    id: i,
                    title: title
                });
            }

            for (i = 0; i < titles.length; i++) {
                title = titles[i];
                statuses[title] = {
                    id: i,
                    title: title
                };
            }
            return statuses;
        })());
    /**
     * @ngdoc factory
     * @name locksmithManager.factory.ModalFactory
     *
     * @description
     * common factory to reuse the confirm modal
     */
    angular
        .module("locksmithManager")
        .factory('ModalFactory', ['$modal', function($modal) {
            var modal = {};

            modal.confirm = function(options) {
                var modalInstance = $modal.open({
                    animation: true,
                    templateUrl: 'app/views/common/modal/confirm-modal.html',
                    controller: 'ConfirmController',
                    controllerAs: 'confirmCtrl',
                    resolve: {
                        Options: [function() {
                            return options;
                        }]
                    }
                });
                return modalInstance.result;
            };
            return modal;
        }]);


    /**
     * @ngdoc factory
     * @name locksmithManager.factory.Spinner
     *
     * @description
     * common factory to reuse Spinner in whole application
     */
    angular
        .module("locksmithManager")
        .factory('Spinner', ['$rootScope', function($rootScope) {
            return {
                start: function() {
                    $rootScope.loadingSpinner = true;
                },
                stop: function() {
                    $rootScope.loadingSpinner = false;
                }
            };
        }]);

})();
