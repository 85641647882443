(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewController
   *
   * @description
   * Controller - New Controller
   */
  angular
    .module("locksmithManager")
    .controller('NewPaymentTypesController', NewPaymentTypesController);

  NewPaymentTypesController.$inject = ['$scope', 'PaymentTypesModel'];

  function NewPaymentTypesController($scope, PaymentTypesModel) {
    var newCtr = this;

    newCtr.newPaymentType = {};

    newCtr.create = function () {
      PaymentTypesModel.createPaymentType({
        'name': newCtr.newPaymentType.name,
        'processing_percentage_fee': newCtr.newPaymentType.processing_percentage_fee,
        'processing_flat_fee': newCtr.newPaymentType.processing_flat_fee
      }).then(function (data) {
        $scope.pmntTpCtr.init();
      });
    };
  }
})();