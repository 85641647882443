(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewGrqController
   *
   * @description
   * Controller - NewGrqController
   */
  angular
    .module("locksmithManager")
    .controller('NewGrqController', [
      'GeneralRequestsModel', '$rootScope', '$state', '$timeout', 'toaster', 'AuthService',
      function (GeneralRequestsModel, $rootScope, $state, $timeout, toaster, AuthService) {
        var newGrqCtrl = this,
          id = 0;

        function clearModel() {
          newGrqCtrl.generalRequest = {
            first_name: '',
            last_name: '',
            caller_id: '',
            phone: '',
            comments: ''
          };
          newGrqCtrl.same_caller_id = false;
          newGrqCtrl.submitted = false;
        }

        function viewGeneralRequest() {
          $state.go('general_requests.detail', {generalRequestId: id});
        }

        function viewNewGeneralRequest() {
          $state.go('general_requests.new');
        }

        function alertSuccess(message, action) {
          $timeout(action, 5 * 1000);
          toaster.pop('success', "Success", message, 2000);
        }

        function alertError(message) {
          toaster.pop('error', "Error", message);
        }

        newGrqCtrl.init = function () {
          clearModel();

          newGrqCtrl.alert = {
            message: "",
            class: "",
            show: false
          };
        };

        function showSpinner() {
          $rootScope.loadingSpinner = true;
        }

        function hideSpinner() {
          $rootScope.loadingSpinner = false;
        }

        newGrqCtrl.submit = function (valid) {
          newGrqCtrl.submitted = true;
          if (valid) {
            showSpinner();
            if (newGrqCtrl.same_caller_id) {
              newGrqCtrl.generalRequest.phone = newGrqCtrl.generalRequest.caller_id;
            }

            GeneralRequestsModel.create(newGrqCtrl.generalRequest)
              .success(function (data) {
                hideSpinner();
                clearModel();
                id = data.id;

                if (AuthService.user.limited_user == 1) {
                  alertSuccess("General Request success created!", viewNewGeneralRequest);
                } else {
                  alertSuccess("General Request success created! Now you will redirect to detail page.", viewGeneralRequest);
                }
              })
              .error(function (data, status) {
                if (status === 422) {
                  alertError("Please check all fields!");
                }
                else {
                  if (status === 401) {
                    alertError(data.error);
                  }
                }
                hideSpinner();

              });
          }
        };

        newGrqCtrl.changeCallerId = function () {
          if (newGrqCtrl.generalRequest.caller_id === "") {
            newGrqCtrl.same_caller_id = false;
          }
        };

        newGrqCtrl.init();
      }
    ]);
})();