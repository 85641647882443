(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.CbkController
   *
   * @description
   * Controller - CbkController
   */
  angular
    .module("locksmithManager")
    .controller('CbkController', CbkController);

  CbkController.$inject = ['$scope', 'AuthService', 'CallbacksModel',
    'DTInstances', '$state', '$filter', '$compile', 'store', '$modal', 'statusesEntity', 'tables', 'history'];

  function CbkController($scope, AuthService, CallbacksModel,
                         DTInstances, $state, $filter, $compile, store, $modal, statusesEntity, tables, history) {
    var cbkCtrl = this;

    cbkCtrl.statistics = CallbacksModel.statistics;
    cbkCtrl.statuses = statusesEntity;
    cbkCtrl.tables = tables.data;
    cbkCtrl.history = history.data;

    cbkCtrl.remove = remove;
    cbkCtrl.viewCallback = viewCallback;
    cbkCtrl.isAdmin = AuthService.isAdmin;
    cbkCtrl.isNotTech = !AuthService.isTechnician();

    cbkCtrl.authHeader = authHeader;
    cbkCtrl.createdRow = createdRow;
    cbkCtrl.actionsHtml = actionsHtml;
    cbkCtrl.phoneFilter = phoneFilter;
    cbkCtrl.charactersFilter = charactersFilter;
    cbkCtrl.amCalendarFilter = amCalendarFilter;
    cbkCtrl.statusHtml = statusHtml;

    ///////////////////////////////////////////////////////////////////////

    function createdRow(row) {
      // Recompiling so we can bind Angular directive to the DT
      $compile(angular.element(row).contents())($scope);
    }

    function actionsHtml(data) {
      var html = '<div class="actions">' +
        '<a class="btn btn-xs default btn-editable" data-ng-click="cbkCtrl.viewCallback(' +
        data.id + ')"><i class="fa fa-search"></i> View</a>';
      if (cbkCtrl.isAdmin()) {
        html += '<a data-ng-show="cbkCtrl.isAdmin()" class="btn btn-xs red filter-cancel" ' +
        'data-ng-click="cbkCtrl.remove(' + data.id + ')"><i class="fa fa-times"></i>Delete</a>';
      }
      html += '</div>';
      return html;
    }

    function statusHtml(data, type) {
      return '<span data-entity-status ' +
        'data-entity-id=' + data + ' ' +
        'data-entity-type="callback">' +
        '</span>';
    }

    function phoneFilter(data) {
      return $filter('phone')(data);
    }

    function charactersFilter(data) {
      return $filter('characters')(data, 120);
    }

    function amCalendarFilter(data) {
      return $filter('amCalendar')(data);
    }

    function authHeader(xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + store.get('lsmToken'));
    }

    function remove(id) {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'removeModal.html',
        controller: 'ModalRemove',
        controllerAs: 'vm',
        resolve: {
          object: function () {
            return {
              name: 'complain'
            };
          }
        }
      });

      modalInstance.result.then(function () {
      CallbacksModel.deleteCallback(id)
        .success(function (data, status) {
          if (status === 200) {
            $scope.mainCtr.alertSuccess('Callback was deleted');
            update();
          }
        })
        .error(function (data, status) {
          if (status === 500) {
            $scope.mainCtr.alertError('We have some problem on server');
          }
          else {
            if (status === 401) {
              $scope.mainCtr.alertError(data.error);
            }
          }
        });
      });
    }

    function update() {
      DTInstances.getLast().then(function (dtInstance) {
        dtInstance.reloadData();
      });
      CallbacksModel.getStatistics();
    }

    function viewCallback(id) {
      $state.go('callbacks.detail', {callbackId: id});
    }
  }
})();