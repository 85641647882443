(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.FooterController
   *
   * @description
   * Setup Layout Part - Footer
   */
  angular
    .module("locksmithManager")
    .controller('FooterController', [
      '$scope',
      function ($scope) {
        $scope.$on('$includeContentLoaded', function () {
          Layout.initFooter(); // init footer

          $scope.copyRightYear = moment().format('YYYY');
          $scope.copyRightCompany = 'Horesh Studios';
          $scope.LicensedTo = '247-365 Locksmith';
        });
      }
    ]);
})();