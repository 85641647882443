(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.BaseReportsController
     *
     * @description
     * Contoller - BaseReportsController
     */
    angular
        .module("locksmithManager")
        .controller("BaseReportsController", [
            "$scope", "$stateParams",
            function($scope, $stateParams) {

                var self = this;
                init();

                function init() {

                }
            }
        ]);
})();
