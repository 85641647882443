(function() {
    'use strict';
    angular
        .module("locksmithManager")
        .config([
            '$stateProvider',
            function($stateProvider) {
                /**
                 * Register parent state of user module
                 * Which loads all routes and initial dependencies
                 * @True {string}
                 */
                $stateProvider
                    .state("users", {
                        url: "/users",
                        templateUrl: "app/views/users/users.html",
                        data: {
                            pageTitle: "Users",
                            requiresLogin: true,
                            acceptRole: [0]
                        },
                        abstract: true,
                        controller: "BaseUsersController as baseUserCtrl",
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'datatables',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../app/views/common/modal/submit-remove.html',
                                            '../css/modal.css'
                                        ]
                                    }, {
                                        name: 'datatables.bootstrap',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                                            '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                                            '../components/datatables/media/css/jquery.dataTables.css',
                                            '../css/dataTable/dataTables.custom.css'

                                        ]
                                    }]);
                                }
                            ]
                        }
                    }).state("users.list", {
                        url: "/list",
                        templateUrl: "app/views/users/index.html",
                        data: {
                            pageTitle: "All Users"
                        },
                        controller: 'IndexUsersController as self',
                        resolve: {}
                    }).state('users.new', {
                        url: "/new",
                        templateUrl: "app/views/users/newUser.html",
                        data: {
                            pageTitle: 'New User'
                        },
                        controller: 'UserController',
                        resolve: {}
                    }).state('users.edit', {
                        url: "/edit/:user_id",
                        templateUrl: "app/views/users/editUser.html",
                        data: {
                            pageTitle: 'Edit user'
                        },
                        controller: 'UserController',
                        resolve: {}
                    });
            }
        ]);
})();
