(function () {
  "use strict";
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.EmailSettingsController
   *
   * @description
   * Contoller - EmailSettingsController
   */
  angular
    .module("locksmithManager")
    .controller("EmailSettingsController", [
      "$q",
      "$scope",
      "$stateParams",
      "EmailSettingModel",
      "$state",
      "$timeout",
      "emailKeyMap",
      function ($q, $scope, $stateParams, EmailSettingModel, $state, $timeout, emailKeyMap) {

        var emailSetCtr = this;

        $scope.$on("$stateChangeSuccess",
          function (event, toState, toParams, fromState, fromParams) {
            if (toState.name != "settings.email" && angular.isUndefined(emailKeyMap[toParams.group_name])) {
              emailSetCtr.loadingBaseState = true;
              EmailSettingModel.resetEmailGroup();
              //$state.go("settings.email");
            }
            else {
              emailSetCtr.loadingBaseState = false;
            }
          });


        emailSetCtr.init = function () {
          emailSetCtr.loadingBaseState = false;

          EmailSettingModel.resetEmailGroup();
        };

        emailSetCtr.setEmailGroup = function (group_name) {
          var deffered = $q.defer();

          if (emailSetCtr.loadingBaseState) {
            deffered.resolve(false);
            return deffered.promise;
          }

          EmailSettingModel.resetEmailGroup();

          EmailSettingModel.getEmailGroup(group_name).then(function (data) {
            $timeout(function () {
              EmailSettingModel.setEmailGroup(data);
              emailSetCtr.activeEmailGroup = EmailSettingModel.activeEmailGroup;
              deffered.resolve(true);
            }, 10);
          }, function () {
            emailSetCtr.init();
          });

          return deffered.promise;
        };

        emailSetCtr.init();
      }
    ]);
})();