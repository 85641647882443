/**
 * Created by nikolay on 3/10/15.
 */

(function() {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ModalAddPaymentController
   *
   * @description
   * Controller - ModalAddPaymentController
   */
  angular
    .module("locksmithManager")
    .controller('NewPaymentController', [
      '$scope', '$modalInstance', 'jobId', 'paymentTypes', 'PaymentsModel', 'PaymentTypesModel',
      function($scope, $modalInstance, jobId, paymentTypes, PaymentsModel, PaymentTypesModel) {
        var newPaymentCtrl = this;

        newPaymentCtrl.paymentTypes = paymentTypes.data;
        newPaymentCtrl.amount = '';
        newPaymentCtrl.payment = {};

        newPaymentCtrl.setForm = function(form){
          $scope.paymentMethodForm = form;
        };
        newPaymentCtrl.add = function() {
          $scope.paymentMethodForm.$submitted = true;
          if ($scope.paymentMethodForm.$invalid)
            return;
          PaymentsModel.create({
            job_id: jobId,
            payment_type_id: newPaymentCtrl.paymentType.id,
            amount: newPaymentCtrl.amount
          }).then(function(data) {
            PaymentTypesModel.getPaymentType(data.payment_type_id).then(function(res) {
              data.payment_type = res;
              $modalInstance.close(data);
            });

          });
        };
        newPaymentCtrl.cancel = function() {
          $modalInstance.dismiss('cancel');
        };

      }
    ]);
})();