(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.EditController
   *
   * @description
   * Contoller - Edit Controller
   */
  angular
    .module("locksmithManager")
    .controller('EditController', [
      '$scope', '$stateParams', 'ServicesModel',
      function ($scope, $stateParams, ServicesModel) {
        var editCtr = this;

        $scope.srvcCtr.setActiveService($stateParams.service_id).then(function () {
          editCtr.editService = angular.copy(ServicesModel.activeService);
        });

        editCtr.update = function () {
          ServicesModel.updateService(editCtr.editService).then(function () {
            $scope.srvcCtr.init();
          });
        };
      }
    ]);
})();