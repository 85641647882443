(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.SearchJobController
   *
   * @description
   * Controller - SearchJobController
   */
  angular
    .module("locksmithManager")
    .controller('SearchJobController', SearchJobController);

  SearchJobController.$inject = ['DTOptionsBuilder', 'DTColumnBuilder', '$scope', 'DTInstances', '$stateParams'];

  function SearchJobController(DTOptionsBuilder, DTColumnBuilder, $scope, DTInstances, $stateParams) {
    var searchJobCtrl = this;

    searchJobCtrl.search = search;
    //searchJobCtrl.phrase = "";

    searchJobCtrl.filter = {
        phrase: null,
        start_date: null,
        end_date: null,
        status:{
          id:2,
          value:'All'
        }
    };

    /*
    OPEN = 0,
    SCHEDULED = 1,
    IN_PROGRESS = 2,
    CLOSED = 3,
    PENDING = 4,
    CANCELLED = 5;
    */
    searchJobCtrl.jobStatus = [
          /*{
            id: 0,
            name: 'Open'
          }, {
            id: 1,
            name: 'Scheduled'
          },*/
          {
            id:'all',
            name:"All"
          },
          {
            id: 2,
            name: 'In Progress'
          }, {
            id: 3,
            name: 'Completed'
          },
          {
            id: 4,
            name: 'Awating Survey'
          },
          {
            id: 5,
            name: 'Canceled'
          }
        ];

    searchJobCtrl.datePicker = {
      date: { startDate: moment(), endDate: moment() },
      displayString: 'Select date Range',
      opts: {
          locale: {
              separator: ' - ',
              format: 'YYYY-MM-DD'
          },
          ranges: {
              'Today': [moment(), moment()],
              'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
              'Last 7 Days': [moment().subtract(6, 'days'), moment()],
              'Last 30 Days': [moment().subtract(29, 'days'), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          },
          eventHandlers: {
              'apply.daterangepicker': function(ev, picker) {
                  var model = angular.copy(ev.model),
                      sDate = model.startDate.format(searchJobCtrl.datePicker.opts.locale.format),
                      eDate = model.endDate.format(searchJobCtrl.datePicker.opts.locale.format);
                  angular.extend(searchJobCtrl.filter, {
                      start_date: sDate,
                      end_date: eDate
                  });
                  searchJobCtrl.datePicker.displayString = sDate + ' - ' + eDate;
                  
                  searchJobCtrl.search();                  
              }
          }
      }
    };

    init();

    searchJobCtrl.dtOptions = DTOptionsBuilder.newOptions()
      .withOption('ajax', {
        url: '/api/jobs',
        type: 'GET',
        beforeSend: $scope.jobCtrl.authHeader,
        data: function (data) {
          data.search.value = searchJobCtrl.filter;
          if($stateParams.status !== undefined && Number.isInteger(+$stateParams.status)) {
            data.status = $stateParams.status;
          }
        }
      })
      .withDataProp('data')
      .withOption('processing', true)
      .withOption('serverSide', true)
      .withOption('bFilter', false)
      .withOption('createdRow', $scope.jobCtrl.createdRow)
      .withPaginationType('simple_numbers')
      .withBootstrap()
      .withOption('order', [1, 'asc'])
      .withLanguage({
        "sProcessing": $scope.mainCtr.processingHtml
      });

    searchJobCtrl.dtColumns = [
      DTColumnBuilder.newColumn('record_number').withTitle('Ticket ID')
        .notSortable().withClass('record-number'),
      
      DTColumnBuilder.newColumn('status').withTitle('Status')
        .renderWith($scope.jobCtrl.statusHtml).withClass('status'),

      DTColumnBuilder.newColumn('created_at').withTitle('Date Created')
        .renderWith($scope.jobCtrl.amCalendarFilter),

      DTColumnBuilder.newColumn('user_name').withTitle('Agent'),

      DTColumnBuilder.newColumn('name').withTitle('Full Name'),
      
      DTColumnBuilder.newColumn('postal_code').withTitle('Zip Code'),

      DTColumnBuilder.newColumn('tech_name').withTitle('Assigned ISP'),

      DTColumnBuilder.newColumn(null).withTitle('Actions')
        .notSortable().renderWith($scope.jobCtrl.actionsHtml).withClass('actions')
    ];

    function init() {
    }

    function search(phrase) {
      DTInstances.getLast().then(function(dtInstance) {
        dtInstance.reloadData();
      });
    }

    function validateFilter() {
        var flag = true;
        for (var key in searchJobCtrl.filter) {
            if (!searchJobCtrl.filter[key]) {
                flag = false;
                break;
            }
        }
        return flag;
    }

  }
})();