/**
 * Created by nikolay on 3/7/15.
 */

(function() {
    "use strict";
    /**
     * @ngdoc Service
     * @name locksmithManager.service.JobsModel
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .service("JobsModel", JobsModel);

    JobsModel.$inject = ["$http", "$resource", "statuses", "UsersModel", "Statuses"];

    function JobsModel($http, $resource, statuses, UsersModel, Statuses) {
        var model = this,
            URLS = {
                JOBS: 'api/jobs/:id',
                HISTORY: '/api/jobHistory/:id',
                STATISTICS: '/api/jobs/dashboard/statistics',
                PAGINATION: '/api/jobs/pagination',
                DASHBOARD_TABLES: '/api/jobs/dashboard/jobs',
                DASHBOARD_HISTORY: '/api/jobs/dashboard/history',
                JOBS_CALL_LINK: '/api/jobs/job-close-link/'
            },
            Job = $resource(
                        URLS.JOBS, 
                        { id: '@id' }, 
                        {
                            'create': { method: 'POST' },
                            'save': { method: 'PUT' },
                            'search': { method: 'GET', isArray: true }
                        }
                ),
            History = $resource(URLS.HISTORY);

        model.job = {
            data: [],
            history: []
        };

        model.jobs = {
            data: []
        };

        model.availableTechnicians = {
            data: []
        };

        model.statistics = {
            open: 0,
            progress: 0,
            closed: 0,
            scheduled: 0
        };

        //////////////////////

        function cacheJob(result) {
            model.job.data = result;
            return model.job.data;
        }

        function cacheHistory(result) {
            model.job.history = result;
            return model.job.history;
        }

        function extract(result) {
            return result.data;
        }

        function create(job) {
            return Job.create(job).$promise;
        }

        function remove(id) {
            return $http.delete(URLS.JOBS.replace(':id', id));
            //return Job.remove({id: id}).$promise;
        }

        function show(id) {
            return Job.get({ id: id }).$promise.then(cacheJob);
        }

        function getAvailableTechnicians() {
            return UsersModel.query({
                    filter: 'technician'
                })
                .then(function(data) {
                    model.availableTechnicians.data = data.list;
                    return model.availableTechnicians.data;
                });
        }

        function update(id, data) {
            return Job.save({ id: id }, data).$promise;
        }

        function re_open(id) {
            return update(id, { status: statuses.Open.id });
        }

        /*

        function close(id, closeDetails) {
            closeDetails.status = statuses.Closed.id;
            return update(id, closeDetails);
        }

        */

        function close(id,notes) {
            var params = {
                status: statuses.Closed.id,
                closed_note: notes
            };
            return update(id, params);
        }

        function history(id) {
            return History.query({ id: id }).$promise.then(cacheHistory);
        }

        function cancel(id,cancelText) {
            return update(id, { status: statuses.Cancel.id, closed_note: cancelText});                   
        }

        function confirmAssignedTech(id) {
            return update(id, { status: statuses.Progress.id });
        }

        function assignISP(id,ispId) {
            return update(id, { assigned_technician_id: ispId });
        }

        function getStatistics() {
            return $http.get(URLS.STATISTICS)
                .then(function(data) {
                    data = extract(data);
                    model.statistics.open = data.open;
                    model.statistics.progress = data.progress;
                    model.statistics.closed = data.closed;
                    model.statistics.scheduled = data.scheduled;
                    return data;
                });
        }

        function getDashboardTables() {
            return $http.get(URLS.DASHBOARD_TABLES);
        }

        function getDashboardHistory() {
            return $http.get(URLS.DASHBOARD_HISTORY);
        }

        function sendCallLink(id) {
            return $http.get(URLS.JOBS_CALL_LINK + id);
        }

        model.create = create;
        model.remove = remove;
        model.update = update;
        model.getTextStatus = Statuses.jobTextStatus;
        model.show = show;
        model.getAvailableTechnicians = getAvailableTechnicians;
        model.re_open = re_open;
        model.close = close;
        model.history = history;
        model.getStatusClass = Statuses.jobClassStatus;
        model.cancel = cancel;
        model.confirmAssignedTech = confirmAssignedTech;
        model.getStatistics = getStatistics;
        model.getDashboardTables = getDashboardTables;
        model.getDashboardHistory = getDashboardHistory;
        model.sendCallLink = sendCallLink;
        model.assignISP = assignISP;
    }
})();
