(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.SearchCmplController
   *
   * @description
   * Controller - SearchCmplController
   */
  angular
    .module("locksmithManager")
    .controller('SearchCmplController', SearchCmplController);

  SearchCmplController.$inject = ['DTOptionsBuilder', 'DTColumnBuilder', '$scope', 'DTInstances', '$stateParams'];

  function SearchCmplController(DTOptionsBuilder, DTColumnBuilder, $scope, DTInstances, $stateParams) {
    var searchCmplCtrl = this;

    searchCmplCtrl.search = search;
    searchCmplCtrl.phrase = "";

    init();

    searchCmplCtrl.dtOptions = DTOptionsBuilder.newOptions()
      .withOption('ajax', {
        url: '/api/complains',
        type: 'GET',
        beforeSend: $scope.cmplCtrl.authHeader,
        data: function (data) {
          data.search.value = searchCmplCtrl.phrase;
          if($stateParams.status !== undefined && Number.isInteger(+$stateParams.status)) {
            data.status = $stateParams.status;
          }
        }
      })
      .withDataProp('data')
      .withOption('processing', true)
      .withOption('serverSide', true)
      .withOption('bFilter', false)
      .withOption('createdRow', $scope.cmplCtrl.createdRow)
      .withPaginationType('simple_numbers')
      .withBootstrap()
      .withOption('order', [1, 'asc'])
      .withLanguage({
        "sProcessing": $scope.mainCtr.processingHtml
      });

    searchCmplCtrl.dtColumns = [
      DTColumnBuilder.newColumn('record_number').withTitle('Record number')
        .notSortable().withClass('record-number'),

      DTColumnBuilder.newColumn('name').withTitle('Name'),

      DTColumnBuilder.newColumn('phone').withTitle('Phone')
        .renderWith($scope.cmplCtrl.phoneFilter).notSortable().withClass('phone'),

      DTColumnBuilder.newColumn('details').withTitle('Description')
        .notSortable().renderWith($scope.cmplCtrl.charactersFilter),

      DTColumnBuilder.newColumn(null).withTitle('')
        .notSortable().renderWith($scope.cmplCtrl.actionsHtml).withClass('actions')
    ];

    function init() {
    }

    function search() {
      DTInstances.getLast().then(function(dtInstance) {
        dtInstance.reloadData();
      });
    }
  }
})();
