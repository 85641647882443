(function () {
    'use strict';
    /**
     * @ngdoc Filter
     * @name component.Filter.tabHighlight
     *
     * @description
     * highlight tab
     */
    angular
        .module("component")
        .filter("tabHighlight", [
            '$location',
            function ($location) {

                return function (input) {
                    return $location.absUrl().indexOf(input) > -1;
                };

            }
        ]);

    /**
     * @ngdoc Filter
     * @name component.Filter.tabHighlight
     *
     * @description
     * highlight tab
     */
    angular
        .module("component")
        .filter("splitCap", [
            function () {

                return function (input, splitChar) {
                    if (!input) {
                        return;
                    }
                    var comps = input.split(splitChar);
                    for (var i = comps.length - 1; i >= 0; i--) {
                        comps[i] = comps[i].charAt(0).toUpperCase() + comps[i].slice(1);
                    }

                    return comps.join(" ");
                };
            }
        ]);

    /**
     * @ngdoc Filter
     * @name component.Filter.getRole
     *
     * @description
     * Returns an role object based on role key
     */
    angular
        .module("component")
        .filter("getRole", [
            'roles',
            function (roles) {

                return function (input) {
                    return roles[input];
                };
            }
        ]);

    /**
     * @ngdoc Directive
     * @name component.Directive.inputValidate
     *
     * @description
     * Validating inputs
     */
    angular
        .module("component")
        .directive('inputValidate', [
            'validationRegex',
            function (validationRegex) {
                return {
                    restrict: 'A',
                    scope: {
                        input_model: "=ngModel",
                        validation_type: "@valType",
                        empty_validate: "=emptyValidate",
                        init_focus: "=initFocus",
                        error_class: "@errorClass"
                    },
                    link: function (scope, elm, attr) {
                        var pattern,
                            focused,
                            validate,
                            errorMediate,
                            errorToggleUI,
                            errorToggleClass,
                            errorToggle;

                        pattern = validationRegex[scope.validation_type];
                        focused = "";

                        if (scope.empty_validate && !scope.input_model) {
                            elm.addClass("ui-invalid-input");
                        }

                        $(elm).on('focus', function () {
                            if (!focused) {
                                focused = scope.init_focus;
                            }
                        });

                        $(elm).on('blur', function () {
                            focused = true;
                            validate();
                        });

                        $(elm).on("keydown", function (event) {
                            if (event.originalEvent && event.originalEvent.keyCode && event.originalEvent.keyCode == 13) {
                                focused = true;
                                validate();
                            }
                        });

                        scope.$watch('input_model', function () {
                            validate();
                        });

                        scope.$watch('empty_validate', function () {
                            validate();
                        });

                        validate = function () {
                            if (!scope.input_model) {
                                errorMediate(scope.empty_validate);
                                return false;
                            }
                            else {
                                var input = scope.input_model;
                                errorMediate(!(pattern.test(input)));
                            }
                        };
                        errorMediate = function (expr) {
                            errorToggleUI(expr);
                            if (!focused) {
                                return false;
                            }
                            errorToggleClass(expr);
                        };

                        errorToggleUI = function (expr) {
                            errorToggle(expr, "ui-invalid-input", elm);
                        };

                        errorToggleClass = function (expr) {
                            var error_comp = scope.error_class,
                                container = elm,
                                error_class = "has-error";
                            if (error_comp) {
                                var error_comps = error_comp.split("|");
                                container = $('.' + error_comps[0]);
                                error_class = error_comps[1];
                            }
                            errorToggle(expr, error_class, container);
                        };

                        errorToggle = function (expr, class_id, container) {
                            if (expr) {
                                container.addClass(class_id);
                            }
                            else {
                                container.removeClass(class_id);
                            }
                        };
                    }
                };
            }
        ]);

    /**
     * @ngdoc Directive
     * @name component.Directive.ngSpinnerBar
     *
     * @description
     * Route State Load Spinner(used on page or content load)
     */
    angular
        .module("component")
        .directive('validateScope', function () {
            return {
                restrict: 'A',
                scope: true,
                link: function (scope, elm, attr) {
                    scope.isInvalid = function () {
                        if ($("." + attr.containerClass).find("." + attr.errorClass).length > 0 || $("." + attr.containerClass).hasClass(attr.errorClass)) {
                            return true;
                        } else {
                            return false;
                        }
                    };
                }
            };
        })
        .directive('convertToNumber', function () {
            return {
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {
                    ngModel.$parsers.push(function (val) {
                        return parseInt(val, 10);
                    });
                    ngModel.$formatters.push(function (val) {
                        return '' + val;
                    });
                }
            };
        });
})();