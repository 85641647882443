(function() {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('NewJobComplaintModalController', NewJobComplaintModalController);

  NewJobComplaintModalController.$inject = ['$modalInstance',/*, 'object'*/];

  function NewJobComplaintModalController($modalInstance) {
    
    var cjc = this;

    cjc.cancelReasonText = '';

    cjc.submit = function() {

      var data = {
        complaintReason: cjc.cancelReasonText,
        complaintComments: $('#comments').val().trim().substring(0,150)
      };

      $modalInstance.close(data);
    };

    cjc.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
})();