(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.PhoneService
   *
   * @description
   */

  angular
    .module("locksmithManager")
    .service('PhoneService', [
      '$pusher', '$http', '$timeout', 'toaster', 'AuthService', 'UsersModel', 'PhoneSettingModel',
      function ($pusher, $http, $timeout, toaster, AuthService, UsersModel, PhoneSettingModel) {

        var model = this,
          client,
          pusher;

        client = new Pusher(window.lm.pusher, {authEndpoint: '/pusher/auth?token=' + AuthService.getToken()});
        pusher = $pusher(client);

        pusher.subscribe('calls');


        pusher.bind('incoming',
          function (data) {
            // add comment into page
            if (data !== null) {
              model.call_queue.data.push(data);
              new Audio('ding.mp3').play();
            }
            setTimeout(model.updateIncomingQueue, 500);
          }
        );

        pusher.bind("App\\Events\\Phone\\CallStatusChanged", function (data) {
          console.log(data);
          switch (data.call.status) {
            case 'in-progress':
            case 'completed':

              console.log('status: ', data.call.status, model.phone.incomingCallSid, data.call.call_sid);
              if (data.call.status == 'in-progress' &&
                model.phone.incomingCallSid == data.call.call_sid) {
                model.phone.incomingCallSid = null;
                model.showIncomingWarningIfUserIsNotBusy(data.call);
              }
              updateQueues();
              break;
          }
        });

        pusher.bind("App\\Events\\Phone\\IncomingVoiceCall", function (data) {
          model.phone.incomingCallSid = data.call.call_sid;
          updateQueues();
        });

        // Phone Service
        model.phone = {
          token: "",
          availableCallbackNumbers: [],
          status: "Not Ready",
          connection: null,
          description: '',
          connectedNumber: '',
          activeSid: ''
        };

        model.availableNumberPhonesForRules = {
          data: []
        };

        model.members = {
          members: {}
        };

        // 'sid': 'adad6',
        // 'spot': 6,
        // 'phone': "3013254343",
        // 'received': moment()
        model.sales_queue = [];

        model.hold_queue = [];

        model.calls_queue_after_disconnect = [];

        function throwError(message) {
          toaster.pop('error', "Error", message);
        }

        model.init = function () {
          model.phone.token = "";
          model.phone.availableCallbackNumbers = [];
          model.phone.status = "Not Ready";
          model.phone.connection = null;

          PhoneSettingModel.getOutgoingPhoneNumber().then(function (data) {
            model.phone.outgoing = data;
          });

          updateQueues();
        };


        // QUEUES
        model.updateSalesQueue = function () {
          $http.get('/callcenter/voice/queue/sales').then(function success(data) {
            model.sales_queue = [];
            angular.forEach(data.data, function (i, o) {
              model.sales_queue.push({
                'sid': i.sid,
                'spot': i.spot,
                'phone': i.callerId,
                'received': moment(i.startTime)
              });
            });
          });
        };

        model.updateHoldQueue = function () {
          $http.get('/callcenter/voice/queue/hold').then(function success(data) {
            model.hold_queue = [];
            angular.forEach(data.data, function (i, o) {
              model.hold_queue.push({
                'sid': i.sid,
                'spot': i.spot,
                'phone': i.callerId,
                'received': moment(i.startTime)
              });
            });
          });
        };


        model.callNotInQueue = function (callSid) {
          angular.forEach(model.sales_queue, function (call) {
            console.log('checking queue', call);
          });

          angular.forEach(model.sales_queue, function (call) {
            console.log('checking queue', call);
          });

          return true;
        };

        // Softphone

        model.dial = function (phoneNumber) {

          model.phone.status = "dialing";
          model.phone.description = 'Connecting';
          model.phone.connectedNumber = phoneNumber;

          model.phone.connection = Twilio.Device.connect({
            toCall: phoneNumber,
            callFrom: model.phone.outgoing
          });

        };

        model.hangUp = function (callsid) {
          Twilio.Device.disconnectAll();
        };

        model.dialDigit = function (digit) {

          if (model.phone.connection) {

            model.phone.connection.sendDigits(digit);
          }
          else {

          }
        };


        // Incoming Calls
        model.showIncomingWarningIfUserIsNotBusy = function (call) {

          console.log(call);

          if (model.isAgentBusy()) {
            return false;
          }

          new Audio('ding.mp3').play();

          swal({
            title: "Incoming Call!",
            text: "Incoming call from: " + call.from + " click \"Answer Call\" " +
            "to answer, or \"Ignore\" to put the call on hold.",
            icon: "info",
            buttons: true,
            showCancelButton: true,
            confirmButtonText: "Answer Call",
            cancelButtonText: "Ignore"
          }, function (answer) {
            if (answer) {
              model.phone.status = "connecting";
              model.phone.description = "Connecting";
              model.phone.activeSid = call.call_sid;
              model.connectAgentTo(call.call_sid, 'sales');
              $timeout(function () {
                updateQueues();
                model.phone.connectedNumber = call.from;
              }, 200);
            }
          });
        };

        function updateQueues() {
          model.updateSalesQueue();
          model.updateHoldQueue();
        }

        // Phone Workflow
        model.connectAgentTo = function (callSid, queueName) {
          model.phone.status = "connecting";
          model.phone.description = "Connecting";

          // Hit a endpoint url to make the connection
          $http.post('/callcenter/device/' + callSid + '/answer', {'queue': queueName})
            .then(function success(data) {
              model.phone.activeSid = callSid;
              model.phone.connectedNumber = data.data.from;
              updateQueues();
            });

        };

        model.putOnHold = function (callSid) {
          $http.post('/callcenter/device/' + callSid + '/hold', {'queue': 'hold'})
            .then(function success(data) {
              model.phone.connectedNumber = data.data.from;
              updateQueues();
            });
        };

        // Helpers
        model.isAgentBusy = function () {
          console.log(model.phone.status, model.phone.status === 'ready');
          return model.phone.status !== 'ready';
        };


        model.put_active_call_on_hold = function (callback) {
          model.update_active_call();
          if (model.active_call !== null) {
            var call_putting_on_hold = model.active_call;
            call_putting_on_hold.status = 'PUTTING ON HOLD';
            return model.putCallOnHold(model.active_call_sid)
              .then(function (data) {
                  call_putting_on_hold.onHold = true;
                  call_putting_on_hold.status = 'ON HOLD';

                  return callback();
                },
                function (error) {
                  call_putting_on_hold.status = 'ERROR';
                  return callback();
                });
          }
          else {
            return callback();
          }
        };

        /**
         Call to a number that redirects to a hook thats
         redirects the call to the first user in the queue
         */
        model.getCallFromQueue = function (callSid) {

          model.processingOrder.data = true;


          //model.putAllCallsOnHold(callSid);

          return model.put_active_call_on_hold(function () {
            model.processingOrder.data = true;
            var active_connection = Twilio.Device.activeConnection();
            if (typeof active_connection == 'undefined' || active_connection === null || active_connection === undefined) {
              return model.trigger_answer_call(callSid);
            }
            else {
              var queued_call_component = {};
              queued_call_component.my_function = function () {
                return model.trigger_answer_call(callSid);
              };
              queued_call_component.resolve_fn = null;
              queued_call_component.my_promise = new Promise(function (resolve, reject) {
                queued_call_component.resolve_fn = resolve;
              });

              model.calls_queue_after_disconnect.push(queued_call_component);
              //setTimeout(model.process_calls_connection_queue,3000);
              return queued_call_component.my_promise;
            }
          });

        };

        model.trigger_answer_call = function (callSid) {
          model.processingOrder.data = true;
          return $http.post('api/phone/voice/answer', {'call_sid': callSid})
            .then(function success(data) {
                model.processingOrder.data = false;
              },
              function error(data) {
                model.processingOrder.data = false;
              });
        };


        model.dequeueCall = function (callSid) {

          model.processingOrder.data = true;

          return $http.post('api/phone/voice/dequeue', {'callSid': callSid})
            .then(function success(data) {

                model.processingOrder.data = false;
              },
              function error(data) {
                model.processingOrder.data = false;
              });
        };

        model.putCallOnHold = function (callSid) {

          model.processingOrder.data = true;

          var data = {
            'call_sid': callSid
          };

          return $http.post('api/phone/voice/putcallonhold', data)
            .then(function success(data) {
                model.processingOrder.data = false;
              },
              function error(data) {
                model.processingOrder.data = false;
              });
        };

        model.retrieveCallOnHold = function (callSid) {

          model.processingOrder.data = true;

          //model.putAllCallsOnHold(callSid);
          return model.put_active_call_on_hold(function () {
            var active_connection = Twilio.Device.activeConnection();
            if (typeof active_connection == 'undefined' || active_connection === null || active_connection === undefined) {
              return model.trigger_retrieve_call_onhold(callSid);
            }
            else {
              var queued_call_component = {};
              queued_call_component.my_function = function () {
                return model.trigger_retrieve_call_onhold(callSid);
              };
              queued_call_component.resolve_fn = null;
              queued_call_component.my_promise = new Promise(function (resolve, reject) {
                queued_call_component.resolve_fn = resolve;
              });
              model.calls_queue_after_disconnect.push(queued_call_component);
              return queued_call_component.my_promise;
            }

          });


        };

        model.trigger_retrieve_call_onhold = function (callSid) {
          model.processingOrder.data = true;
          return $http.post('api/phone/voice/retrivecallonhold', {'call_sid': callSid})
            .then(function success(data) {
                model.processingOrder.data = false;
                return data;
              },
              function error(data) {
                model.processingOrder.data = false;
                return data;
              });
        };

        model.getAvailableNumbers = function () {

          return $http.get('api/phone/numbers').then(function success(data) {
            model.phone.availableCallbackNumbers = [];

            //if (!refresh)
            angular.forEach(data.data, function (value, key) {
              model.phone.availableCallbackNumbers.push({
                label: value.friendly_name,
                value: value.phone_number,
                token: key
              });
            });

          }, function error(data) {

          });
        };

        model.getToken = function () {
          if (AuthService.isLoggedIn()) {
            console.log('user is logged in');
            return $http.get('api/phone/token').then(function success(data) {
              model.phone.token = data.data.token;
            }, function error(data) {

            });
          }

          console.log('user is not logged in');

        };

        model.refreshAvailableNumbers = function () {
          return $http.put('/api/phone/numbers', {}).then(function success(data) {
            model.getAvailableNumbers();
            toaster.pop('success', "Success", "Available Number Refreshed", 2000);
          }, function error(data) {

          });
        };

        model.updateFriendlyName = function (phone) {
          return $http.put('/api/phone/numbers/' + phone.token, {value: phone.label}).then(function success(data) {
            toaster.pop('success', "Success", "Friendly Name Updated", 2000);
          }, function error(data) {
            throwError('Unable to update Friendly Name at this time, please try again later');
          });
        };

        model.updateIncomingQueue = function () {
          var was_processing_before = (model.processingOrder.data === true);
          return $http.post('api/phone/updateincomingqueue')
            .then(function success(data) {

              if (data.data === '' || data.data === null) {
                data.data = [];
              }

              model.call_queue.data = data.data;
              if (!was_processing_before) {
                model.processingOrder.data = false;
              }

            }, function error(data) {
              model.processingOrder.data = false;
            });
        };

        model.updateOngoingCallsList = function (connection) {

          if (model.ongoingCalls.data !== null) {
            $.each(model.ongoingCalls.data, function (i, v) {

              if ((!v.onHold) && (v.status !== 'PUTTING ON HOLD') && (v.status !== 'ANSWERING')) {
                if (connection.From == v.From && (
                    connection.To == v.To || connection.To.indexOf('client:') !== -1)) {
                  model.ongoingCalls.data.splice(i, 1);
                }
              }
            });
          }
        };

        model.updateOutgoingCallStatus = function (connection) {

          // if(model.outgoingCall.data!==null &&
          // !model.outgoingCall.data.onHold) {

          //     if(connection.From == model.outgoingCall.data.From &&
          //         connection.To == model.outgoingCall.data.To )
          //     {
          //         model.outgoingCall.data = null;
          //     }
          // }

          if (model.outgoingCalls.data !== null) {
            $.each(model.outgoingCalls.data, function (i, v) {

              if (typeof connection.From == 'undefined') {
                return false;
              }

              if ((!v.onHold) && (v.status !== 'PUTTING ON HOLD')) {
                if (connection.From == v.From && (
                    connection.To == v.To || connection.To.indexOf('client:') !== -1)) {
                  model.outgoingCalls.data.splice(i, 1);
                }
              }
            });
          }
        };

        model.getRecordedCalls = function (callSid) {
          return $http.post('api/phone/voice/answer', {'call_sid': callSid}).then(function success(data) {
          }, function error(data) {
          });
        };

        model.requestChildCallSid = function (callSid) {
          model.reqChildSidCounter = 0;
          model.doRequest(callSid);
        };

        model.doRequest = function (callSid) {


          model.reqChildSidCounter++;

          if (model.reqChildSidTimer) {
            clearTimeout(model.reqChildSidTimer);
          }
          return $http.post('api/phone/voice/request-child-call-sid', {'call_sid': callSid})
            .then(function success(data) {

                console.log('request processing: ' + callSid, data);

                for (var i = 0; i < model.outgoingCalls.data.length; i++) {
                  var an_outgoingCall = model.outgoingCalls.data[i];

                  if (an_outgoingCall.CallSid !== null && an_outgoingCall.CallSid === callSid) {
                    model.outgoingCalls.data[i].childCallSid = data.data.childSid;
                    break;
                  }
                }


                if (data.data.childSid === null && model.reqChildSidCounter < 5) {
                  console.log({
                    'data': data.data,
                    'req_counter': model.reqChildSidCounter
                  });
                  model.reqChildSidTimer = setTimeout(model.doRequest(callSid), 1000);
                }

                //model.outgoingCall.data.childCallSid = data.data.childSid;


              },
              function error(data) {
                console.log('error:', data);
              });

        };

        model.setup = function () {
          model.init();

          model.getToken()
            .then(function () {
              twilioSetup(model.phone.token);

              twilioBindError();

              twilioBindIncoming();

              // Update device status
              Twilio.Device.ready(updateDeviceStatus);
              // Twilio.Device.incoming(updateDeviceStatus);
              Twilio.Device.offline(updateDeviceStatus);
              Twilio.Device.connect(updateDeviceStatus);
              Twilio.Device.disconnect(updateDeviceStatus);
            });

        };


        function updateDeviceStatus(connection) {

          console.log("Connection type:", connection.status(), connection.parameters);

          switch (connection.status()) {
            case 'open':
            case 'connecting':
              model.phone.status = 'in call';
              model.phone.description = 'Connected';
              model.phone.callSid = connection.parameters.CallSid;
              model.phone.activeSid =  model.phone.activeSid || connection.parameters.CallSid;
              model.phone.connection = Twilio.Device.activeConnection();
              break;
            case 'closed':
              model.phone.status = 'ready';
              model.phone.description = '';
              model.phone.connectedNumber = '';
              model.phone.connection = null;
              model.phone.callSid = null;
              model.phone.activeSid = null;
              break;
            case 'ready':
              model.phone.status = 'ready';
              break;
            default:
              console.log('connection', connection.status());
              break;
          }

          updateQueues();
        }

        model.check_for_outgoing_childsid = function () {
          for (var i = 0; i < model.outgoingCalls.data.length; i++) {
            var an_outgoingCall = model.outgoingCalls.data[i];
            if (an_outgoingCall.pending_child_sid === true) {
              model.outgoingCalls.data[i].pending_child_sid = false;
              model.requestChildCallSid(an_outgoingCall.CallSid);
              break;
            }
          }

          // if(model.outgoingCall.data !== null)
          //         {
          //             model.requestChildCallSid(model.outgoingCall.data.CallSid);
          // }
        };

        function twilioSetup(token) {
          // Attempt to connect to twilio
          Twilio.Device.setup(token, {
            debug: true,
            closeProtection: true
          });
        }

        function twilioBindError() {
          Twilio.Device.error(function (err) {
            if (err.code == 31205) {
              model.getToken().then(function () {
                twilioSetup(model.phone.token);
              });
            }
          });
        }

        function twilioBindIncoming() {
          Twilio.Device.incoming(function (conn) {
            // accept the incoming connection and start two-way audio
            conn.accept();

          });
        }

        model.process_calls_connection_queue = function () {
          if (model.calls_queue_after_disconnect.length > 0) {
            var queued_call_component = model.calls_queue_after_disconnect.shift();
            //queued_call_component.my_function().then(queued_call_component.my_promise.resolve);
            var function_promise = queued_call_component.my_function();
            queued_call_component.resolve_fn(function_promise);
          }
        };

        model.fallback_disconnect_active_call = function () {
          if (model.ongoingCalls !== null && model.ongoingCalls.data !== null) {
            for (var i = 0; i < model.ongoingCalls.data.length; i++) {
              var an_ongoingCall = model.ongoingCalls.data[i];
              if (an_ongoingCall.status == 'ACTIVE') {
                model.ongoingCalls.data.splice(i, 1);
                model.active_call = null;
                model.active_call_sid = null;
                break;
              }
            }
          }


          if (model.outgoingCalls !== null && model.outgoingCalls.data !== null) {
            for (var j = 0; j < model.outgoingCalls.data.length; j++) {
              var an_outgoingCall = model.outgoingCalls.data[j];
              if (an_outgoingCall.status == 'ACTIVE') {
                model.outgoingCalls.data.splice(j, 1);
                model.active_call = null;
                model.active_call_sid = null;
                break;
              }
            }
          }

        };

        /**
         * searchPhoneNumbers
         * search phone numbers with criteria
         * @return {object} $promise
         */
        model.searchPhoneNumbers = function (data) {
          return $http.post('api/phone/search', data);
        };
        /**
         * buyPhoneNumber
         *
         * @param  {object} data request data
         * @return {object} $promise
         */
        model.buyPhoneNumber = function (data) {
          return $http.post('api/phone/buy', data);
        };

        model.buyPoolNumberForPeople = function (data) {
          return $http.post('api/phone/buy-people-pool-number', data);
        };

        /**
         * getStates
         * search phone numbers with criteria
         * @return {object} $promise
         */
        model.getStates = function () {
          return $http.get('data/states.json');
        };


        model.getAvailableNumbersForRules = function (forPeople, currentNumber) {

          return $http.post('api/phone/getavailablenumbersforrules', {
            isForPeople: forPeople,
            'currentNumber': currentNumber
          })
            .then(function success(data) {
              model.availableNumberPhonesForRules.data = data.data;
              return data.data;
            }, function error(data) {
            });
        };


      }
    ]);
})();
