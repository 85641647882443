/*
 @file Main Routes.
 */

(function() {
    "use strict";

    angular
        .module("locksmithManager")
        .config([
            "$stateProvider",
            "$urlRouterProvider",
            "$httpProvider",
            "jwtInterceptorProvider",
            function($stateProvider, $urlRouterProvider, $httpProvider, jwtInterceptorProvider) {
                //This header allow Laravel check that all requests is ajax
                $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
                /**
                 * Application default route is /#/login
                 * If no route is provided when open application then application get redirect to default URL (/#/login)
                 *
                 * Check if user already logged in and hitting to login or base url (example : http://locksmith-manager.dev/)
                 * Application should render to dashboard
                 *
                 * if not logged in then login page
                 *
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 * @param  {[Object | Instance]} $injector  Angular $injector service to inject dependecy mannually when needed
                 * @return {[Void]}
                 */
                $urlRouterProvider.otherwise(function($injector) {
                    var $state = $injector.get("$state"),
                        authService = $injector.get("AuthService"),
                        isLoggedIn = authService.isLoggedIn();
                    if (isLoggedIn) {
                        $state.go("jobs.search");
                    } else {
                        $state.go("login");
                    }
                });

                jwtInterceptorProvider.tokenGetter = ["AuthService", function(AuthService) {
                    return AuthService.getToken();
                }];
                jwtInterceptorProvider.tokenGetter.$inject = ["AuthService", "jwtHelper"];
                $httpProvider.interceptors.push('jwtInterceptor');
                $httpProvider.interceptors.push('ResponseInterceptor');

                $stateProvider
                //login
                    .state('login', {
                    url: "/login",
                    templateUrl: "app/views/login.html",
                    data: {
                        pageTitle: 'Login',
                        redirectLogin: 'jobs.search'
                    },
                    controller: 'LoginController as loginCtr',
                    resolve: {
                        deps: [
                            '$ocLazyLoad',
                            function($ocLazyLoad) {
                                return $ocLazyLoad.load({
                                    name: 'locksmithManager',
                                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                    files: [
                                        '../css/login3.css'
                                    ]
                                });
                            }
                        ]
                    }
                })

                // Dashboard
                .state('dashboard', {
                    url: "/dashboard",
                    templateUrl: "app/views/dashboard.html",
                    data: {
                        pageTitle: 'Dashboard',
                        requiresLogin: true
                    },
                    controller: 'DashboardController',
                    resolve: {
                        deps: [
                            '$ocLazyLoad',
                            function($ocLazyLoad) {
                                return $ocLazyLoad.load({
                                    name: 'locksmithManager',
                                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                    files: [
                                        '../components/morris.js/morris.css',

                                        '../components/morris.js/morris.min.js',
                                        '../components/raphael/raphael-min.js',

                                        '../components/chart-angular-raphaeljs-morris/src/directives/chartangular.js'
                                    ]
                                });
                            }
                        ]
                    }
                })

                // Dashboard
                .state('profile', {
                    url: "/profile/:profile_id",
                    templateUrl: "app/views/profile/overview.html",
                    data: {
                        pageTitle: 'Profile',
                        requiresLogin: true
                    },
                    controller: 'ProfileController as profileCtr',
                    resolve: {
                        deps: [
                            '$ocLazyLoad',
                            function($ocLazyLoad) {
                                return $ocLazyLoad.load({
                                    name: 'locksmithManager',
                                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                    files: [
                                        '../css/tasks.css',
                                        '../css/profile.css'
                                    ]
                                });
                            }
                        ]
                    }
                })


                /**
                 * Call Backs
                 */
                .state('callbacks', {
                        url: '/callback',
                        templateUrl: 'app/views/callbacks/callbacks.html',
                        data: {
                            requiresLogin: true
                        },
                        controller: "CbkController as cbkCtrl",
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'datatables',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../app/views/common/modal/submit-remove.html',
                                            '../css/modal.css'
                                        ]
                                    }, {
                                        name: 'datatables.bootstrap',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                                            '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                                            '../components/datatables/media/css/jquery.dataTables.css',
                                            '../css/dataTable/dataTables.custom.css'

                                        ]
                                    }]);
                                }
                            ],
                            statistics: [
                                'CallbacksModel',
                                function(CallbacksModel) {
                                    return CallbacksModel.getStatistics();
                                }
                            ],
                            tables: [
                                'CallbacksModel',
                                function(CallbacksModel) {
                                    return CallbacksModel.getDashboardTables();
                                }
                            ],
                            history: [
                                'CallbacksModel',
                                function(CallbacksModel) {
                                    return CallbacksModel.getDashboardHistory();
                                }
                            ]

                        }
                    })
                    .state('callbacks.new', {
                        url: "/new",
                        parents: 'callbacks',
                        templateUrl: "app/views/callbacks/new.html",
                        data: {
                            pageTitle: 'New Callback'
                        },
                        controller: 'NewCbkController as newCbkCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'textAngular',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../components/textAngular/src/textAngular.css',
                                            '../components/textAngular/dist/textAngular-rangy.min.js',
                                            '../components/textAngular/dist/textAngular-sanitize.min.js',
                                            '../components/textAngular/dist/textAngular.min.js',
                                            '../components/angular-bootstrap-switch/dist/angular-bootstrap-switch.js',
                                            '../css/custom.css'
                                        ]
                                    }]);
                                }
                            ]
                        }
                    })
                    .state('callbacks.all', {
                        url: "/all",
                        parents: 'callbacks',
                        templateUrl: "app/views/callbacks/all.html",
                        data: {
                            pageTitle: 'All Callbacks'
                        },
                        controller: 'AllCbkController as allCbkCtrl',
                        resolve: {}
                    })
                    .state('callbacks.search', {
                        url: "/search?status",
                        parents: 'callbacks',
                        templateUrl: "app/views/callbacks/search.html",
                        data: {
                            pageTitle: 'Search Callbacks'
                        },
                        controller: 'SearchCbkController as searchCbkCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'locksmithManager',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../css/pages/search.css'
                                        ]
                                    }]);
                                }
                            ]
                        }
                    })
                    .state('callbacks.detail', {
                        url: "/:callbackId",
                        parents: 'callbacks',
                        templateUrl: "app/views/callbacks/callback_details.html",
                        controller: 'DetailCbkController',
                        controllerAs: 'detailCbkCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'xeditable',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            "../components/angular-xeditable/dist/css/xeditable.css",
                                            "../components/angular-xeditable/dist/js/xeditable.js"
                                        ]
                                    }, {
                                        name: 'textAngular',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../components/textAngular/src/textAngular.css',
                                            '../components/textAngular/dist/textAngular-rangy.min.js',
                                            '../components/textAngular/dist/textAngular-sanitize.min.js',
                                            '../components/textAngular/dist/textAngular.min.js',
                                            '../css/custom.css'
                                        ]
                                    }, {
                                        name: 'angular-md5',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            "../components/angular-md5/angular-md5.min.js"
                                        ]
                                    }]);
                                }
                            ],
                            notes: [
                                '$stateParams',
                                'NotesModel',
                                function($stateParams, NotesModel) {
                                    return NotesModel.getNotesForCallback($stateParams.callbackId);
                                }
                            ]
                        }
                    })

                /**
                 * General Requests
                 */
                .state('general_requests', {
                        url: "/general_requests",
                        templateUrl: 'app/views/general_requests/general_requests.html',
                        data: {
                            requiresLogin: true
                        },
                        controller: 'GrqController as grqCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'datatables',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../app/views/common/modal/submit-remove.html',
                                            '../css/modal.css'
                                        ]
                                    }, {
                                        name: 'datatables.bootstrap',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                                            '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                                            '../components/datatables/media/css/jquery.dataTables.css',
                                            '../css/dataTable/dataTables.custom.css'

                                        ]
                                    }]);
                                }
                            ],
                            statistics: [
                                'GeneralRequestsModel',
                                function(GeneralRequestsModel) {
                                    return GeneralRequestsModel.getStatistics();
                                }
                            ],
                            tables: [
                                'GeneralRequestsModel',
                                function(GeneralRequestsModel) {
                                    return GeneralRequestsModel.getDashboardTables();
                                }
                            ],
                            history: [
                                'GeneralRequestsModel',
                                function(GeneralRequestsModel) {
                                    return GeneralRequestsModel.getDashboardHistory();
                                }
                            ]
                        }
                    })
                    .state('general_requests.new', {
                        url: "/new",
                        parents: 'general_requests',
                        templateUrl: "app/views/general_requests/new.html",
                        data: {
                            pageTitle: 'New General Request'
                        },
                        controller: 'NewGrqController as newGrqCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'textAngular',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../components/textAngular/src/textAngular.css',
                                            '../components/textAngular/dist/textAngular-rangy.min.js',
                                            '../components/textAngular/dist/textAngular-sanitize.min.js',
                                            '../components/textAngular/dist/textAngular.min.js',
                                            '../css/custom.css'
                                        ]
                                    }]);
                                }
                            ]
                        }
                    })
                    .state('general_requests.all', {
                        url: "/all",
                        parents: 'general_requests',
                        templateUrl: "app/views/general_requests/all.html",
                        data: {
                            pageTitle: 'All General Requests'
                        },
                        controller: 'AllGrqController as allGrqCtrl',
                        resolve: {}
                    })
                    .state('general_requests.search', {
                        url: "/search?status",
                        parents: 'general_requests',
                        templateUrl: "app/views/general_requests/search.html",
                        data: {
                            pageTitle: 'Search General Requests'
                        },
                        controller: 'SearchGrqController as searchGrqCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'locksmithManager',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../css/pages/search.css'
                                        ]
                                    }]);
                                }
                            ]
                        }
                    })
                    .state('general_requests.detail', {
                        url: "/:generalRequestId",
                        parents: 'general_requests',
                        templateUrl: "app/views/general_requests/general_request_details.html",
                        controller: 'DetailGrqController as detailGrqCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'xeditable',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            "../components/angular-xeditable/dist/css/xeditable.css",
                                            "../components/angular-xeditable/dist/js/xeditable.js"
                                        ]
                                    }, {
                                        name: 'angular-md5',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            "../components/angular-md5/angular-md5.min.js"
                                        ]
                                    }, {
                                        name: 'textAngular',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../components/textAngular/src/textAngular.css',
                                            '../components/textAngular/dist/textAngular-rangy.min.js',
                                            '../components/textAngular/dist/textAngular-sanitize.min.js',
                                            '../components/textAngular/dist/textAngular.min.js',
                                            '../css/custom.css'
                                        ]
                                    }]);
                                }
                            ],
                            notes: [
                                '$stateParams',
                                'NotesModel',
                                function($stateParams, NotesModel) {
                                    return NotesModel.getNotesForGeneralRequest($stateParams.generalRequestId);
                                }
                            ]
                        }
                    })

                /**
                 * Complains
                 */
                .state('complains', {
                        url: '/complains',
                        templateUrl: 'app/views/complains/complains.html',
                        data: {
                            requiresLogin: true
                        },
                        controller: 'CmplController as cmplCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'datatables',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../app/views/common/modal/submit-remove.html',
                                            '../css/modal.css'
                                        ]
                                    }, {
                                        name: 'datatables.bootstrap',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            '../components/angular-datatables/dist/plugins/bootstrap/angular-datatables.bootstrap.min.js',
                                            '../components/angular-datatables/dist/plugins/bootstrap/datatables.bootstrap.min.css',
                                            '../components/datatables/media/css/jquery.dataTables.css',
                                            '../css/dataTable/dataTables.custom.css'

                                        ]
                                    }]);
                                }
                            ],
                            statistics: [
                                'ComplainsModel',
                                function(ComplainsModel) {
                                    return ComplainsModel.getStatistics();
                                }
                            ],
                            tables: [
                                'ComplainsModel',
                                function(ComplainsModel) {
                                    return ComplainsModel.getDashboardTables();
                                }
                            ],
                            history: [
                                'ComplainsModel',
                                function(ComplainsModel) {
                                    return ComplainsModel.getDashboardHistory();
                                }
                            ]
                        }

                    })
                    .state('complains.new', {
                        url: "/new",
                        parents: 'complains',
                        templateUrl: "app/views/complains/new.html",
                        data: {
                            pageTitle: 'New Complain'
                        },
                        controller: 'NewCmplController as newCmplCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'locksmithManager',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            // Dependancies:
                                            '../css/login3.css'
                                        ]
                                    }, {
                                        name: 'textAngular',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../components/textAngular/src/textAngular.css',
                                            '../components/textAngular/dist/textAngular-rangy.min.js',
                                            '../components/textAngular/dist/textAngular-sanitize.min.js',
                                            '../components/textAngular/dist/textAngular.min.js',
                                            '../css/custom.css'
                                        ]
                                    }]);
                                }
                            ]
                        }
                    })
                    .state('complains.all', {
                        url: "/all",
                        parents: 'complains',
                        templateUrl: "app/views/complains/all.html",
                        data: {
                            pageTitle: 'All Complains'
                        },
                        controller: 'AllCmplController as allCmplCtrl',
                        resolve: {}
                    })
                    .state('complains.search', {
                        url: "/search?status",
                        parents: 'complains',
                        templateUrl: "app/views/complains/search.html",
                        data: {
                            pageTitle: 'Search Complains'
                        },
                        controller: 'SearchCmplController as searchCmplCtrl',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'locksmithManager',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../css/pages/search.css'
                                        ]
                                    }]);
                                }
                            ]
                        }
                    })
                    .state('complains.detail', {
                        url: "/complains/:complainId",
                        parents: 'complains',
                        templateUrl: "app/views/complains/complain_details.html",
                        controller: 'DetailCmplController as detailCmplCtr',
                        resolve: {
                            deps: [
                                '$ocLazyLoad',
                                function($ocLazyLoad) {
                                    return $ocLazyLoad.load([{
                                        name: 'xeditable',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            "../components/angular-xeditable/dist/css/xeditable.css",
                                            "../components/angular-xeditable/dist/js/xeditable.js"
                                        ]
                                    }, {
                                        name: 'angular-md5',
                                        insertBefore: '#ng_load_plugins_before',
                                        files: [
                                            "../components/angular-md5/angular-md5.min.js"
                                        ]
                                    }, {
                                        name: 'textAngular',
                                        insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                        files: [
                                            '../components/textAngular/src/textAngular.css',
                                            '../components/textAngular/dist/textAngular-rangy.min.js',
                                            '../components/textAngular/dist/textAngular-sanitize.min.js',
                                            '../components/textAngular/dist/textAngular.min.js',
                                            '../css/custom.css'
                                        ]
                                    }]);
                                }
                            ],
                            notes: [
                                '$stateParams',
                                'NotesModel',
                                function($stateParams, NotesModel) {
                                    return NotesModel.getNotesForComplain($stateParams.complainId);
                                }
                            ]
                        }
                    })

                /**
                 * Auth
                 */
                .state('reset-password', {
                    url: "/password/reset/:token",
                    templateUrl: "app/views/edit.html",
                    controller: 'ResetController as resetCtr',
                    resolve: {
                        deps: [
                            '$ocLazyLoad',
                            function($ocLazyLoad) {
                                return $ocLazyLoad.load({
                                    name: 'locksmithManager',
                                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                    files: [
                                        // Dependancies:
                                        '../css/login3.css'
                                    ]
                                });
                            }
                        ]
                    }
                })


                /**
                 * Reports
                 */
                .state("reports", {
                    url: "/reports",
                    template: "<ui-view></ui-view>",
                    data: {
                        pageTitle: "Technician Reports",
                        requiresLogin: true,
                        acceptRole: [0]
                    },
                    controller: "BaseReportsController as baseReportCtrl",
                    resolve: {
                        deps: [
                            '$ocLazyLoad',
                            function($ocLazyLoad) {
                                return $ocLazyLoad.load([{
                                    name: 'daterangepicker',
                                    insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
                                    files: [
                                        "../components/momentjs/moment.js",
                                        "../components/angular-moment/angular-moment.min.js",
                                        "../components/bootstrap-daterangepicker/daterangepicker.js",
                                        "../components/angular-daterangepicker/js/angular-daterangepicker.min.js",
                                        "../components/bootstrap-daterangepicker/daterangepicker.css"
                                    ]
                                }]);
                            }
                        ],
                    }
                })

                /**
                 * Technician Reports
                 */
                .state("reports.tech", {
                        url: "/tech",
                        parent: 'reports',
                        templateUrl: "app/views/reports/tech/all.html",
                        data: {
                            pageTitle: "Technician Reports"
                        },
                        controller: "TechReportsController as techReportCtrl"
                    }).state("reports.tech.detail", {
                        url: "/tech/:user_id/:start_date",
                        parent: 'reports',
                        templateUrl: "app/views/reports/tech/report.html",
                        data: {
                            pageTitle: "Technician Reports Detail"
                        },
                        controller: "TechReportViewController as techReportViewCtrl"
                    })
                    /**
                     * Phone Agent Reports
                     */
                    .state("reports.sale", {
                        url: "/sale",
                        parent: 'reports',
                        templateUrl: "app/views/reports/sale/all.html",
                        data: {
                            pageTitle: "Phone Agent Reports"
                        },
                        controller: "SaleReportsController as saleReportCtrl"
                    }).state("reports.sale.detail", {
                        url: "/sale/:user_id/:start_date",
                        parent: 'reports',
                        templateUrl: "app/views/reports/sale/report.html",
                        data: {
                            pageTitle: "Phone Agent Reports Detail"
                        },
                        controller: "SaleReportViewController as saleReportViewCtrl"
                    });


            }
        ]);

    /**
     * @module locksmithManager
     * Run block to make configuration for this module when service and factory providers are available
     */
    angular
        .module("locksmithManager")
        .run([
            "$rootScope", "$state", "store", "jwtHelper", "AuthService", "toaster",
            function($rootScope, $state, store, jwtHelper, AuthService, toaster) {
                $rootScope.$on("$stateChangeSuccess", function(e, to, params) {
                    $rootScope.pageTitle = to.hasOwnProperty('data') ? to.data.pageTitle : '';
                });
                $rootScope.$on("$stateChangeStart", function(e, to, params) {

                    //  // On Every status change we need to check for authentication.
                    //  var routePromise = "";
                    //
                    //  function validateRoutePermission(routeData) {
                    //    if (routeData.acceptRole && routeData.acceptRole.indexOf(AuthService.user.role) == -1) {
                    //      e.preventDefault();
                    //      toaster.pop("error", "Error", "You do not have permission to access this page");
                    //      $state.go("dashboard");
                    //    }
                    //  }
                    //
                    //save a last state of page
                    if (to.name !== "login") {
                        store.set("back_state", to);
                        store.set("back_state_params", params);
                    }
                    //
                    //  // If not logged in, go to login page.
                    //  if (to.data && to.data.requiresLogin) {
                    //    if (!AuthService.isLoggedIn()) {
                    //      // LOGIN VERIFICATION FAILED
                    //      e.preventDefault();
                    //      AuthService.logout(); //logout
                    //      $state.go("login");//add change state to login that we don't have redirect loop
                    //      location.reload();//force reload page
                    //    }
                    //
                    //    // PASSED LOGIN CHECKS
                    //    if (!AuthService.token_obj.token) {
                    //      routePromise = AuthService.getUserFromToken(store.get("lsmToken"));
                    //    }
                    //
                    //    if (routePromise) {
                    //      routePromise.then(function () {
                    //        validateRoutePermission(to.data);
                    //      });
                    //    }
                    //    else {
                    //      validateRoutePermission(to.data);
                    //    }
                    //  }
                    //
                    if (to.data && to.data.redirectLogin && AuthService.isLoggedIn()) {
                        e.preventDefault();
                        $state.go(to.data.redirectLogin);
                    }
                    //
                });


            }
        ]);
})
();
