(function () {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('ChatController', ChatController);

  ChatController.$inject = [
    '$window',
    'ChatService',
    'AuthService',
    'UsersModel',
    '$pusher',
    '$scope'
  ];


  function ChatController($window, ChatService, AuthService, UsersModel, $pusher, $scope) {

    var chatCtrl = this;

    var client = new Pusher(window.lm.pusher, {
      authEndpoint: '/pusher/auth?token=' + AuthService.token_obj.token
    });

    var pusher = $pusher(client);

    // Cleanup stuff...
    $scope.$on('$destroy', function () {
      pusher.unsubscribe('chat');
    });


    chatCtrl.currentTechnicianId = null;
    chatCtrl.currentTechAvailable = 0;
    chatCtrl.currentTechnicianName = '';
    chatCtrl.conversationsList = {};

    chatCtrl.openMessage = false;

    // 109 - 2 header and title
    chatCtrl.heightChat = 500 + 'px';

    chatCtrl.openChat = openChat;
    chatCtrl.closeChat = closeChat;
    chatCtrl.sendMessage = sendMessage;
    chatCtrl.updateCurrentTechnicianAvailability = updateCurrentTechnicianAvailability;

    chatCtrl.contactListUpdateTimer = null;
    chatCtrl.contactListUpdateMessageInterval = 10000;//10seg

    chatCtrl.messageListUpdateTimer = null;
    chatCtrl.messageListIdleTime = 0;
    chatCtrl.messageListUpdateMessageInterval = 10000;//10seg

    chatCtrl.user = AuthService.getUser();

    chatCtrl.loadedSameTechMessages = 0;

    //********* CONTACT LIST TIMER UPDATEER *******

    //Increment the idle time counter every minute.

    /*//Zero the idle timer on mouse movement.
     $(this).mousemove(function (e) {
     this.contactListIdleTime = 0;
     this.contactListUpdateMessageInterval = 5000;//5seg
     });

     $(this).keypress(function (e) {
     this.contactListIdleTime = 0;
     this.contactListUpdateMessageInterval = 5000;//5seg
     });*/


    // Pusher Notifications
    var chat_channel = pusher.subscribe('chat');
    chat_channel.bind_all(function (event, data) {
      // Listen to any push regards a job, and whenever it comes refresh the
      // history.

      switch (event) {

        case 'App\\Events\\Technicians\\TechnicianAvailablityChanged':
          // only pop a notification if the current user is not the one who
          // created the new message.
          startContactListupdater();
          break;

        case 'App\\Events\\Phone\\IncomingTextMessage':
          startMessageUpdater();
          break;

      }
    });

    function startContactListupdater() {

      if (AuthService.isLoggedIn()) {
        ChatService.getContactList().then(function (response) {
          chatCtrl.contactList = response.data.list;
        });
      }

      restartContactListTimer();
    }

    function restartContactListTimer() {
      if (chatCtrl.contactListUpdateTimer) {
        clearTimeout(chatCtrl.contactListUpdateTimer); //cancel the previous
                                                       // timer.
      }

      chatCtrl.contactListUpdateTimer = setTimeout(startContactListupdater, chatCtrl.contactListUpdateMessageInterval);
    }

    //********* MESSAGES LIST TIMER UPDATEER *******

    //Increment the idle time counter every minute.

    /*//Zero the idle timer on mouse movement.
     $(this).mousemove(function (e) {
     this.messageListIdleTime = 0;
     this.contactListUpdateMessageInterval = 5000;//5seg
     });

     $(this).keypress(function (e) {
     this.messageListIdleTime = 0;
     this.contactListUpdateMessageInterval = 5000;//5seg
     });*/

    function messageTimerIncrement() {
      chatCtrl.messageListIdleTime++;

      if (chatCtrl.messageListIdleTime > 2) { // 2 minutes
        chatCtrl.contactListUpdateMessageInterval = 180000;//3min
      }
      else if (chatCtrl.messageListIdleTime > 5) { // 5 minutes
        chatCtrl.contactListUpdateMessageInterval = 300000;//5min
      }
      else if (chatCtrl.messageListIdleTime > 10) { // 10 minutes
        chatCtrl.contactListUpdateMessageInterval = 900000;//15min
      }
    }

    var messageIdleInterval = setInterval(messageTimerIncrement, 60000); // 1
                                                                         // minute

    function startMessageUpdater() {

      if (AuthService.isLoggedIn() && chatCtrl.openMessage == true) {
        chatCtrl.messageList = chatCtrl.conversationsList[chatCtrl.currentTechnicianId];

        showMessagesScrollBar();

        ChatService.getContactChatMessages(chatCtrl.currentTechnicianId).then(
          function (response) {
            if (chatCtrl.conversationsList[chatCtrl.currentTechnicianId] === undefined ||
              chatCtrl.conversationsList[chatCtrl.currentTechnicianId].length < response.data.chats.length) {
              chatCtrl.conversationsList[chatCtrl.currentTechnicianId] = response.data.chats;
              chatCtrl.messageList = chatCtrl.conversationsList[chatCtrl.currentTechnicianId];
              chatCtrl.loadedSameTechMessages = 0;
              showMessagesScrollBar();
            }
          });
      }

      restartMessageTimer();
    }

    function showMessagesScrollBar() {
      if (chatCtrl.loadedSameTechMessages < 3) {
        setTimeout(QuickSidebar.externalInitChatSlimScroll, 500);
      }

      if (chatCtrl.lastCurrentTechnicianId == chatCtrl.currentTechnicianId) {
        chatCtrl.loadedSameTechMessages++;
      }
      else {
        chatCtrl.loadedSameTechMessages = 0;
      }
      chatCtrl.lastCurrentTechnicianId = chatCtrl.currentTechnicianId;
    }

    function restartMessageTimer() {
      if (chatCtrl.messageListUpdateTimer) {
        clearTimeout(chatCtrl.messageListUpdateTimer); //cancel the previous
                                                       // timer.
      }

      chatCtrl.messageListUpdateTimer = setTimeout(startMessageUpdater, chatCtrl.messageListUpdateMessageInterval);
    }

    function updateCurrentTechnicianAvailability() {
      var tech_id = chatCtrl.currentTechnicianId;
      var available = chatCtrl.currentTechAvailable;
      UsersModel.updateTechAvailable(tech_id, available).then(
        function (response) {
          // console.log(response);
        }
      );
    }

    function openChat(tech) {
      chatCtrl.currentTechnicianId = tech.id;
      chatCtrl.currentTechnicianName = tech.name;
      chatCtrl.currentTechAvailable = tech.tec_availability;
      tech.notReadCount = 0;
      chatCtrl.openMessage = true;

      startMessageUpdater();

    }

    function closeChat() {
      chatCtrl.openMessage = false;
      chatCtrl.currentTechnicianId = null;
      chatCtrl.currentTechnicianName = '';
      chatCtrl.currentTechAvailable = 0;

      //stop chat timer updater
      if (chatCtrl.messageListUpdateTimer) {
        clearTimeout(chatCtrl.messageListUpdateTimer); //cancel the previous
                                                       // timer.
      }
    }

    function sendMessage() {

      if (chatCtrl.message !== '' && chatCtrl.message !== null && chatCtrl.message !== undefined) {
        $('#messageForm > fieldset').attr('disabled', 'disabled');

        ChatService.sendTextMessage(chatCtrl.currentTechnicianId, chatCtrl.message)

          .then(function (response) {

            $('#messageForm > fieldset').removeAttr('disabled');
            chatCtrl.message = '';
            // chatCtrl.conversationsList[chatCtrl.currentTechnicianId].push(response.data);
            // chatCtrl.messageList = chatCtrl.conversationsList[chatCtrl.currentTechnicianId];
            chatCtrl.loadedSameTechMessages = 0;
            showMessagesScrollBar();

          })
          .catch(function (res, status) {
            swal('', "Unable to send the message at this time, please contact support.", 'error');
          });
      }
    }

    startContactListupdater();
  }
})();