(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.TypeViewController
   *
   * @description
   * Contoller - TypeView Controller
   */
  angular
    .module("locksmithManager")
    .controller('TypeViewController', [
      '$scope', '$stateParams',
      function ($scope, $stateParams) {
        var typeViewCtr = this;

        $scope.typeCtr.setActiveService($stateParams.service_type_id);
      }
    ]);
})();