(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.PhoneNumberRulesController
   *
   * @description
   * Contoller - PhoneNumberRulesController
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype ::
   *   mohan.singh42 )
   */
  angular
    .module("locksmithManager")
    .controller('RecordedCallsController', RecordedCallsController);
  /**
   * $injector
   * lets inject dependecies
   * @type {Array}
   */
  RecordedCallsController.$injector = [
    'DTOptionsBuilder',
    'DTColumnBuilder',
    'DTInstances',
    'store',
    '$scope',
    '$compile'];

  /**
   * RecordedCallsController Create a class to encapsulation
   */
  function RecordedCallsController(DTOptionsBuilder,
                                   DTColumnBuilder,
                                   DTInstances,
                                   store,
                                   $scope,
                                   $compile) {
    /**
     * self Holds current instance of Class
     * @private {[Object]}
     */
    var $ctrl = this;

    $ctrl.pageTitle = 'Phone Recordings';
    $ctrl.filter = {
      number: null,
      start_date: null,
      end_date: null
    };

    $ctrl.authHeader = function (xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + store.get('lsmToken'));
    };

    $ctrl.createdRow = function (row) {
      // Recompiling so we can bind Angular directive to the DT
      $compile(angular.element(row).contents())($scope);
    };

    $ctrl.audioHtmlRender = function (data, type, full) {
      if (data === '' || data === null) {
        return "";
      }

      return '<audio controls><source src="' + data + '.mp3" type="audio/mpeg"></source></audio>';
    };

    $ctrl.actionsHtml = function (data, type, rowData, event) {
      if (data !== null) {
        return '<a target="_blank" class="btn btn-md btn-default" href="' + data + '.mp3" download="' + rowData.call_sid + '.mp3"><i class="fa fa-cloud-download"></i></a>';
      }
      else {
        return "";
      }
    };

    $ctrl.datePicker = {
      date: {startDate: moment(), endDate: moment()},
      displayString: 'Select date Range',
      opts: {
        locale: {
          separator: ' - ',
          format: 'YYYY-MM-DD'
        },
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        eventHandlers: {
          'apply.daterangepicker': function (ev, picker) {
            var model = angular.copy(ev.model),
              sDate = model.startDate.format($ctrl.datePicker.opts.locale.format),
              eDate = model.endDate.format($ctrl.datePicker.opts.locale.format);

            angular.extend($ctrl.filter, {
              start_date: sDate,
              end_date: eDate
            });

            $ctrl.datePicker.displayString = sDate + ' - ' + eDate;

            $ctrl.search();
          }
        }
      }
    };

    $ctrl.dtOptions = DTOptionsBuilder.newOptions()
      .withOption('ajax', {
        url: '/api/calls/recordedcalls',
        type: 'POST',
        beforeSend: $ctrl.authHeader,
        data: function (data) {
          data.search.value = $ctrl.filter;
          /*if($stateParams.status !== undefined && Number.isInteger(+$stateParams.status)) {
            data.status = $stateParams.status;
          }*/
        }
      })
      .withDataProp('data')
      .withOption('processing', true)
      .withOption('serverSide', true)
      .withOption('bFilter', false)
      .withOption('createdRow', $ctrl.createdRow)
      .withPaginationType('simple_numbers')
      .withBootstrap()
      .withOption('order', [1, 'asc'])
      .withLanguage({
        "sProcessing": $scope.mainCtr.processingHtml
      });

    $ctrl.dtColumns = [
      DTColumnBuilder.newColumn('created_at').withTitle('Date')
        .withClass('record-number'),

      DTColumnBuilder.newColumn('status').withTitle('Status'),

      DTColumnBuilder.newColumn('to').withTitle('Dialed Number'),

      DTColumnBuilder.newColumn('from').withTitle('Caller ID'),

      DTColumnBuilder.newColumn('recording_url').withTitle('Audio').notSortable()
        .renderWith($ctrl.audioHtmlRender),

      DTColumnBuilder.newColumn('recording_url').withTitle('Actions')
        .notSortable().renderWith($ctrl.actionsHtml).withClass('actions')
    ];

    $ctrl.search = function () {
      DTInstances.getLast().then(function (dtInstance) {
        dtInstance.reloadData();
      });
    };

    $ctrl.searchOnChange = function () {

      if ($ctrl.searchTimer) {
        clearTimeout($ctrl.searchTimer); //cancel the previous timer.
      }

      $ctrl.searchTimer = setTimeout($ctrl.search, 1000);
    };
  }
})();
