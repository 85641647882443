(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.BaseSettingsController
   *
   * @description
   * Basic controller
   */
  angular
    .module("locksmithManager")
    .controller('BaseSettingsController', [
      '$rootScope', '$scope', '$ocLazyLoad',
      function ($rootScope, $scope, $ocLazyLoad) {

        $scope.$on('$viewContentLoaded', function () {
          // initialize core components
          Metronic.initAjax();
        });

        // set sidebar closed and body solid layout mode
        $rootScope.settings.layout.pageBodySolid = true;
        $rootScope.settings.layout.pageSidebarClosed = false;

      }
    ]);
})();