(function(){
  'use strict';

  angular
    .module('locksmithManager')
    .service('Statuses', Statuses);

  function Statuses() {
    return {
      jobTextStatus: jobTextStatus,
      jobClassStatus: jobClassStatus,
      entityTextStatus: entityTextStatus,
      entityClassStatus: entityClassStatus
    };

    function jobTextStatus(status){
      switch (+status) {
        case (0):
          return "Open";
        case (1):
          return "Scheduled";
        case (2):
          return "In Progress";
        case (3):
          return "Completed";
        case (4):
          return "Awaiting Survey";
        case (5):
          return "Cancel";
      }
    }

    function entityTextStatus(status) {
      switch (+status) {
        case (0):
          return "Open";
        case (1):
          return "Resolved";
        case (2):
          return "Cancelled";
      }
    }

    function jobClassStatus(status) {
      switch (+status) {
        case (0):
          return "bg-green";
        case (1):
          return "bg-blue-hoki";
        case (2):
          return "bg-green-jungle";
        case (3):
          return "bg-blue-chambray";
        case (4):
          return "bg-yellow-casablanca";
        case (5):
          return "bg-red";
      }
    }

    function entityClassStatus(status) {
      switch (+status) {
        case (0):
          return "bg-green";
        case (1):
          return "bg-yellow-casablanca";
        case (2):
          return "bg-blue-chambray";
      }
    }
  }
})();