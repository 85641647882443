(function () {
  'use strict';

  angular
    .module('locksmithManager')
    .directive('entityStatus', status);

  status.$inject = ['Statuses'];

  function status(Statuses) {
    return {
      restrict: 'EA',
      scope: {
        type: '@entityType',
        status: '@entityId'
      },
      template: '<span class="label {{class(status)}}">{{text(status)}}</span>',
      link: link
    };

    function link(scope, element, attr) {
      switch(scope.type) {
        case 'general_request':
        case 'complain':
        case 'callback':
          scope.text = Statuses.entityTextStatus;
          scope.class = Statuses.entityClassStatus;
          break;
        case 'job':
          scope.text = Statuses.jobTextStatus;
          scope.class = Statuses.jobClassStatus;
          break;
      }
    }
  }
})();