(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ViewSettingsController
   *
   * @description
   * Contoller - ViewSettings
   */
  angular
    .module("locksmithManager")
    .controller('ViewSettingsController', [
      '$scope', '$stateParams',
      function ($scope, $stateParams) {
        var viewCtr = this;

        $scope.leadCtr.setActiveLead($stateParams.lead_id);
      }
    ]);
})();