(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ResetController
   *
   * @description
   * Contoller - ResetController
   */
  angular
    .module("locksmithManager")
    .controller('ResetController', [
      '$rootScope', 'ResetService', '$timeout', '$state', '$stateParams',
      function ($rootScope, ResetService, $timeout, $state, $stateParams) {

        var resetCtr = this;

        resetCtr.resetForm = {
          'token': $stateParams.token,
          'email': '',
          'password': '',
          'password_confirmation': ''
        };

        resetCtr.resetPassword = {
          'class': '',
          'show': false,
          'message': ''
        };

        function extract(result) {
          return result.data;
        }

        resetCtr.submit = function () {
          $rootScope.loadingSpinner = true;
          ResetService.sendReset(resetCtr.resetForm).then(
            function (data) {
              data = extract(data);

              if (data.redirectPath !== undefined) {
                resetCtr.resetPassword.class = 'alert-success';
                resetCtr.resetPassword.message = 'Your password is reset. You will be redirect to login page through 10 second.';

                $timeout(function () {
                  $state.go('login');
                }, 10 * 1000);

              }
              else {
                if (data.email) {
                  resetCtr.resetPassword.class = 'alert-danger';
                  resetCtr.resetPassword.message = data.email;
                }
              }
              resetCtr.resetPassword.show = true;
              $rootScope.loadingSpinner = false;

            },
            function (data) { //if data(e.t. email or passwords) is not valid

              resetCtr.resetPassword.show = true;
              resetCtr.resetPassword.message = 'The data entered is not valid. Check and try again.';
              resetCtr.resetPassword.class = 'alert-danger';

              $rootScope.loadingSpinner = false;
            });
        };
      }
    ]);
})();