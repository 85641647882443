(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ViewController
   *
   * @description
   * Contoller - View page Controller
   */
  angular
    .module("locksmithManager")
    .controller('ViewController', [
      '$q', '$scope', 'ServicesModel', '$state', '$stateParams', '$timeout',
      function ($q, $scope, ServicesModel, $state, $stateParams, $timeout) {
        var viewCtr = this;

        $scope.srvcCtr.setActiveService($stateParams.service_id);
      }
    ]);
})();