(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewTypeController
   *
   * @description
   * Contoller - New Type Controller
   */
  angular
    .module("locksmithManager")
    .controller('NewTypeController', [
      '$scope', '$state', '$stateParams', 'ServicesModel',
      function ($scope, $state, $stateParams, ServicesModel) {
        var newCtr = this;

        $scope.srvcCtr.setActiveService($stateParams.service_id);

        newCtr.newServiceType = {};

        newCtr.create = function () {
          ServicesModel.createServiceType({
            'service_title': newCtr.newServiceType.service_title,
            'service_description': newCtr.newServiceType.service_description || null,
            'base_price': newCtr.newServiceType.base_price || null
          }).then(function (data) {
            $state.go('settings.base.services.view', {
              service_id: $stateParams.service_id
            });
          });
        };
      }
    ]);
})();