(function() {
    "use strict";
    /**
     * @ngdoc Service
     * @name locksmithManager.service.JobsModel
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .service("ChatService", ChatService);

    ChatService.$inject = ["$http"];

    function ChatService($http, $resource, statuses, UsersModel, Statuses) {

        this.getContactList = function() {

            var datatosubmit = {};

            var request = $http({
                  method: 'POST',    
                  data: datatosubmit,
                  url: "/api/chat/contactlist",
            });
              
            return  request.success(
                function (requestResponse) {          
                  return requestResponse.data;  
                  
              }).error(function (requestResponse) { 
                return null;          
              });

        };

        this.getContactChatMessages = function(techId) {

            var datatosubmit = {
                tech_id: techId
            };

            var request = $http({
                  method: 'POST',    
                  data: datatosubmit,
                  url: "/api/chat/chat_messages",
            });
              
            return  request.success(
                function (requestResponse) {          
                  return requestResponse.data;  
                  
              }).error(function (requestResponse) { 
                return null;          
              });

        };

        this.sendTextMessage = function(techId,message) {

            var datatosubmit = {
                tech_id: techId,
                message: message
            };

            var request = $http({
                  method: 'POST',    
                  data: datatosubmit,
                  url: "/api/phone/sms/send",
            });
              
            return  request.success(
                function (requestResponse) {
                  return requestResponse.data;
                  
              }).error(function (res, status) {
                return status;
              });

        };
    }
})();