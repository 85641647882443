(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.ComplainsModel
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('ComplainsModel', [
      '$http', 'Statuses',
      function ($http, Statuses) {

        var model = this,
          URLS = {
            SHOW: 'api/complains/:id',
            EDIT: '/api/complains',
            UPDATE: 'api/complains/:id',
            DELETE: 'api/complains/:id',
            CREATE: '/api/complains',
            STATISTICS: '/api/complains/dashboard/statistics',
            HISTORY: '/api/complainHistory/:id',
            DASHBOARD_TABLES: '/api/complains/dashboard/complains',
            DASHBOARD_HISTORY: '/api/complains/dashboard/history'
          },
          complains;

          model.complain = {
              data: []
          };

        model.statistics = {
          open: 0,
          resolved: 0,
          cancelled: 0
        };

        model.getStatistics = getStatistics;
        model.getHistory = getHistory;
        model.getDashboardTables = getDashboardTables;
        model.getDashboardHistory = getDashboardHistory;

        function getStatistics() {
            return $http.get(URLS.STATISTICS)
              .then(function(data) {
                var extract_result = extract(data);
                model.statistics.open = extract_result.open;
                model.statistics.resolved = extract_result.resolved;
                model.statistics.cancelled = extract_result.cancelled;
                return data;
            });
        }

        function extract(result) {
          return result.data;
        }

        model.create = function (data) {
          return $http.post(URLS.CREATE, data);
        };

        model.createFromJob = function (jobData,complainData) {

          var data ={
            name: jobData.name,
            caller_id: jobData.caller_id,
            phone: jobData.phone,
            job_id: jobData.id,
            details: "| Reason: "+complainData.complaintReason+" | "+complainData.complaintComments            
          };

          return $http.post(URLS.CREATE, data).then(function(response) {
          
            return response.data;
          });
        };

        function cacheComplain(result) {
            model.complain.data = extract(result);
            return model.complain.data;
        }

        model.showComplain = function (id) {
          return $http.get(URLS.SHOW.replace(':id', id)).then(cacheComplain);
        };

        model.updateComplain = function (id, data, message) {
          data.message = message;
          return $http.put(URLS.UPDATE.replace(':id', id), data);
        };

        model.deleteComplain = function (id) {
          return $http.delete(URLS.DELETE.replace(':id', id));
        };

        model.getTextStatus = Statuses.entityTextStatus;

        function getHistory(id) {
          return $http.get(URLS.HISTORY.replace(':id', id))
            .then(function(data) {
              model.complain.history = extract(data);
              return data;
            });
        }

        function getDashboardTables() {
          return $http.get(URLS.DASHBOARD_TABLES);
        }

        function getDashboardHistory() {
          return $http.get(URLS.DASHBOARD_HISTORY);
        }
      }
    ]);
})();
