/*jshint -W047 */
/*jshint undef: false, eqeqeq:false */
(function () {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.UserController
     *
     * @description
     * Contoller - UserController
     */
    angular
        .module("locksmithManager")
        .controller('UserController', [
            '$scope', 'UsersModel', '$q', '$timeout', 'toaster', '$state', 'ServicesModel', 'PhoneService', 'AdWordsService',
            function ($scope, UsersModel, $q, $timeout, toaster, $state, ServicesModel, PhoneService, AdWordsService) {
                /**
                 * UserController Create a class to encapsulation
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 */
                function UserController() {

                    $scope.tabs = [
                        {title: 'Dynamic Title 1', content: 'Dynamic content 1'},
                        {title: 'Dynamic Title 2', content: 'Dynamic content 2', disabled: true}
                    ];

                    $scope.alertMe = function () {
                        setTimeout(function () {
                            $window.alert('You\'ve selected the alert tab!');
                        });
                    };

                    $scope.model = {
                        name: 'Tabs'
                    };
                    /**
                     * _this Holds current instance of Class
                     * @private {[Object]}
                     */
                    var _this = this;
                    _this.availableNumbers = PhoneService.availableNumberPhonesForRules;
                    /**
                     * saving A flag which is used to show loader on button
                     * @True {bool}
                     */
                    _this.saving = false;

                    /**
                     * userForm Holds user form properties
                     * @True {Object}
                     */
                    _this.userForm = {};

                    /**
                     * user A object which holds user data
                     * @True {Object}
                     */
                    _this.user = {
                        job_flat_fee_day: 1,//default
                        job_flat_fee_night: 1,
                        sales_tax: null,
                        company_name: null,
                        campaigns: []
                    };

                    /**
                     * role Selected Role for user
                     * @True {[type]}
                     */
                    _this.user.role = $scope.userRoles[0];

                    _this.user.selectedOps = [];
                    _this.user.selectedServicesTypes = [];

                    /**
                     serviceOptions are the availables services to choose from
                     */

                    _this.servicesOptions = [];

                    /**
                     serviceOptions are the availables Type Services to choose from
                     and backup for restoring filtered
                     */

                    _this.servicesAvailables = [];
                    _this.servicesAvailablesBkp = [];

                    $scope.campaigns = [];

                    /**
                     * [setForm description]
                     * @param  {[type]} form [description]
                     */
                    _this.setForm = function (form) {
                        form.password = form.confirm_password = '';
                        _this.userForm = form;
                    };

                    /**
                     * [resetForm description]
                     * @return {[type]} [description]
                     */
                    _this.resetForm = function () {
                        _this.userForm.$setPristine();
                        _this.userForm.submitted = false;
                        _this.user = angular.copy(UsersModel.user);
                    };

                    /**
                     * [submitForm description]
                     * @return {[type]} [description]
                     */
                    _this.submitForm = function () {
                        /**
                         * Set flag to true to show errors on form
                         */
                        _this.userForm.submitted = true;
                        _this.setRoleValidity();
                        _this.setEmailExistsValidity();
                        _this.setAsignedNumberValidity();
                        _this.setSalesTaxValidity();
                        _this.checkRoleAndDefaultTech();

                        if (_this.userForm.$valid) {
                            _this.setSavingLoader(true);
                            _this.saveUser(_this.user, _this.init_put_user_data);
                        } else {

                        }
                    };
                    /**
                     * [isValidFieldField description]
                     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                     * @param  {[type]}  formField [description]
                     * @return {Boolean}           [description]
                     */
                    _this.isValidFieldField = function (formField) {

                        return _this.userForm[formField].$invalid && _this.userForm.submitted || _this.userForm[formField].$touched;
                    };

                    _this.isSales = function () {
                        return UsersModel.isSales(_this.user.role.role);
                    };

                    _this.isTechnician = function () {
                        return UsersModel.isTechnician(_this.user.role.role);
                    };

                    _this.checkRoleAndDefaultTech = function () {
                        if (!_this.isSales()) {
                            _this.user.default_technician_user_id = null;
                        }
                    };

                    /**
                     * [setUserRole description]
                     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                     * @param  {[type]} role [description]
                     */
                    _this.setUserRole = function (role) {
                        if (role.role === '') {
                            _this.userForm.role.$setValidity('required', false);
                        } else {
                            _this.userForm.role.$setValidity('required', true);
                            if (role.role == '1') {
                                $scope.isChecked = true;

                            } else {
                                $scope.isChecked = false;

                            }
                        }
                    };
                    /**
                     * hasRole
                     * checks if loggedin user has same role as passed
                     * @param  {array}  roles expects array of user Roles
                     * @return {Boolean}
                     */
                    _this.hasRole = function (roles) {
                        if (!_.isArray(roles)) {
                            return false;
                        }
                        var _roles = [_this.user.role.role];
                        return _.intersection(_roles, roles).length;
                    };

                    _this.removedOption = function ($item) {
                        var service_group_id = $item.id;
                        var i = $scope.user.selectedServicesTypes.length;
                        while (i--) {
                            if ($scope.user.selectedServicesTypes[i].service_group_id == service_group_id) {
                                $scope.user.selectedServicesTypes.splice(i, 1);
                            }
                        }

                    };



                    /**
                     * [getUserRole description]
                     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                     * @return {[type]} [description]
                     */
                    function getUserRole(roleId) {
                        return _.find($scope.userRoles, function (iterator) {
                            return iterator.role === roleId;
                        });
                    }

                    function getAvailableTechnicians() {
                        UsersModel.query({
                            filter: 'technician'
                        })
                            .then(function (data) {

                                $scope.availableTechs = data.list;
                            });
                    }

                    function getAvailableServices() {
                        ServicesModel.getServices().then(function () {

                            _this.servicesAvailablesBkp = ServicesModel.services.data;
                            $scope.servicesOptions = ServicesModel.services.data;
                        });
                    }


                    function addWatchers() {

                        $scope.$watch('user.selectedOps', function () {

                            var temp_service_ops = [];
                            var options = [];
                            angular.copy(_this.servicesAvailablesBkp, options);
                            // por cada una de las opciones, 
                            // si dicha opcion esta elegida por el usuario => no debe estar en
                            // las opciones para elegir
                            for (var opindx in options) {
                                for (var usrop in $scope.user.selectedOps) {
                                    if ($scope.user.selectedOps[usrop].id == options[opindx].id) {
                                        options.splice(opindx, 1);
                                    }
                                }
                            }

                            $scope.servicesOptions = options;

                            var temp_services = [];
                            for (var i in $scope.user.selectedOps) {
                                var an_option = [];
                                angular.copy($scope.user.selectedOps[i], an_option);
                                // por cada una de las opciones elegidas, 
                                // por cada servicio contenido en dicha opcion
                                // recorro todos los servicios elegidos por el usuario
                                // si dicho servicio fue elegido por el usuario,
                                // tiene q dejar de estar disponible para elegir
                                // 
                                var k = 0;
                                while (k < an_option.services.length) {
                                    var an_option_service = an_option.services[k];
                                    var was_spliced = false;
                                    for (var j in $scope.user.selectedServicesTypes) {
                                        if (an_option_service.id == $scope.user.selectedServicesTypes[j].id) {
                                            an_option.services.splice(k, 1);
                                            was_spliced = true;
                                        }
                                    }
                                    if (!was_spliced) {
                                        k++;
                                    }
                                }

                                temp_services = temp_services.concat(an_option.services);
                            }
                            $scope.servicesAvailables = temp_services;

                        });

                        $scope.$watch('user.selectedServicesTypes', function () {

                            var temp_services = [];
                            for (var i in $scope.user.selectedOps) {
                                var an_option = [];
                                angular.copy($scope.user.selectedOps[i], an_option);
                                // por cada una de las opciones elegidas, 
                                // por cada servicio contenido en dicha opcion
                                // recorro todos los servicios elegidos por el usuario
                                // si dicho servicio fue elegido por el usuario,
                                // tiene q dejar de estar disponible para elegir
                                // 
                                var k = 0;
                                while (k < an_option.services.length) {
                                    var an_option_service = an_option.services[k];
                                    var was_spliced = false;
                                    for (var j in $scope.user.selectedServicesTypes) {
                                        if (an_option_service.id == $scope.user.selectedServicesTypes[j].id) {
                                            an_option.services.splice(k, 1);
                                            was_spliced = true;
                                        }
                                    }
                                    if (!was_spliced) {
                                        k++;
                                    }
                                }
                                temp_services = temp_services.concat(an_option.services);
                            }
                            $scope.servicesAvailables = temp_services;
                        });
                    }


                    /**
                     * init function which is called before class is intantiated
                     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                     * @return {[Object]} $promise
                     */
                    function init() {
                        addWatchers();
                        ServicesModel.getServices();
                        getAvailableTechnicians();
                        getAvailableServices();

                        if ($state.params.user_id) {
                            UsersModel
                                .getUser($state.params.user_id).then(_this.init_put_user_data, function (error) {
                                toaster.pop('error', "Error", 'Requested user does not exists ' + error.data.error);
                            });
                            //alert(_this.user.role);
                        }
                        else {
                            PhoneService.getAvailableNumbersForRules(true, null);
                        }

                        AdWordsService.getAllCampaigns().then(function(data){
                             $scope.campaigns = data;
                        });

                    }

                    _this.setAsignedNumberValidity = function () {
                        //var _this = this;
                        _this.userForm.phone.$setValidity('required', !_this.user.forwardingEnabled ||
                            (_this.user.assigned_number !== null &&
                                _this.user.assigned_number !== undefined &&
                                _this.user.assigned_number !== ""));
                    };

                    _this.setSalesTaxValidity = function () {

                        if (_this.userForm.sales_tax === undefined) {
                            return;
                        }

                        //var _this = this;
                        if (!_this.user.salesTaxEnabled) {
                            _this.user.sales_tax = null;
                        }
                        _this.userForm.sales_tax.$setValidity('required', !_this.user.salesTaxEnabled ||
                            (_this.user.sales_tax !== null &&
                                _this.user.sales_tax !== undefined &&
                                _this.user.sales_tax !== ""));
                    };

                    _this.init_put_user_data = function (data) {

                        // if(typeof (_this.user.campaigns) === 'Array' ) {
                        //     console.log('array?');
                        // }

                        angular.extend(_this.user, data.data);

                        _this.user.role = UsersModel.findRole(data.data.role);
                        _this.user.password = '';

                        if ($state.params.user_id && _this.user && _this.user.role && _this.user.role.role == '1') {
                            $scope.isChecked = true;
                        } else {
                            $scope.isChecked = false;
                        }

                        _this.user.forwardingEnabled = _this.user.assigned_number;
                        _this.user.salesTaxEnabled = _this.user.sales_tax !== null;


                        if (_this.user.forwarding_rules.length !== 0) {
                            _this.user.forwardingPhone = {
                                'twilio_number': _this.user.forwarding_rules[0].to_number
                            };
                        }
                        else {
                            _this.user.forwardingPhone = {
                                'twilio_number': null
                            };
                        }

                        PhoneService.getAvailableNumbersForRules(true, _this.user.forwardingPhone.twilio_number);

                        if(typeof _this.user.campaigns[0] != 'undefined') {
                            _this.user.campaigns = _this.user.campaigns[0].id;
                        }
                        if(_this.user && _this.user.phone) _this.user.phone = _this.user.phone.replace("+1","");
                    };

                    init();

                }


                /**
                 * [saveUser description]
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 * @param  {[type]} data [description]
                 * @return {[type]}      [description]
                 */
                UserController.prototype.saveUser = function (data, callback) {
                    var _this = this,
                        userObject,
                        userRequest;
                    userObject = angular.copy(data);
                    userObject.role = userObject.role.role;

                    if (userObject.id) {
                        userRequest = UsersModel.updateUser(userObject);
                    } else {
                        userRequest = UsersModel.createUser(userObject);
                    }
                    userRequest
                        .then(
                            function (response) {
                                toaster.pop('success', "Success", 'User has been saved successfully');
                                _this.setSavingLoader(false);
                                var userId = response.data.id;
                                //angular.extend(_this.user, response.data);
                                if (callback !== null && typeof callback == 'function') {
                                    callback(response);
                                }
                                //init_put_user_data(response.data);
                                $timeout(function () {
                                    $state.go('users.edit', {
                                        user_id: userId
                                    });
                                }, 100);
                            },
                            function (error) {
                                _this.checkServerErrors(error);
                                _this.setSavingLoader(false);
                            }
                        );
                };
                /**
                 * [checkServerErrors description]
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 * @param  {[type]} data [description]
                 * @return {[type]}      [description]
                 */
                UserController.prototype.checkServerErrors = function (data) {
                    var validationData,
                        _this = this;
                    if (data.status === 422) {
                        validationData = data.data;
                        if (validationData.length <= 0) {
                            return;
                        }
                        angular.forEach(validationData, function (item, key) {
                            if (key === 'email') {
                                _this.userForm[key].$setValidity('emailExists', false);
                                _this.userForm[key].emailMessage = item;
                            }
                        });
                    }
                };
                /**
                 * [setRoleValidity description]
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 */
                UserController.prototype.setRoleValidity = function () {
                    var _this = this;
                    if (_this.user.role.role === '') {
                        _this.userForm.role.$setValidity('required', false);
                    }
                };
                /**
                 * [setEmailExistsValidity description]
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 */
                UserController.prototype.setEmailExistsValidity = function () {
                    var _this = this;
                    if (_this.userForm.email.$error.emailExists) {
                        _this.userForm.email.$setValidity('emailExists', true);
                    }
                };


                /**
                 * [setSavingLoader description]
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 * @param  {[type]} flag [description]
                 */
                UserController.prototype.setSavingLoader = function (flag) {
                    var _this = this;
                    _this.userForm.saving = flag;
                };
                /**
                 * Expose public properties and methods to scope
                 */
                angular.extend($scope, new UserController());
            }
        ]);
})();
