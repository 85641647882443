(function() {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('ModalRemove', ModalRemove);

  ModalRemove.$inject = ['$modalInstance', 'object'];

  function ModalRemove($modalInstance, object) {
    var vm = this;

    vm.object = object;

    vm.ok = ok;
    vm.cancel = cancel;

    function ok() {
      $modalInstance.close();
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }
  }
})();