(function () {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.MainController
     *
     * @description
     * Setup Layout Part - Content
     */
    angular
        .module("locksmithManager")
        .controller('MainController', [
            '$scope', '$rootScope', '$state', 'AuthService', 'PhoneSettingModel', '$window', 'toaster', "Idle", "Keepalive", "$modal", 'PhoneService',
            function ($scope, $rootScope, $state, AuthService, PhoneSettingModel, $window, toaster, Idle, Keepalive, $modal, PhoneService) {
                $scope.test = 'test message';

                var mainCtr = this;

                mainCtr.phoneConnection = PhoneService;
                mainCtr.started = true;
                mainCtr.isChatEnable = true;
                mainCtr.isPhoneDialerEnable = PhoneSettingModel.isPhoneEnabled.data;

                function closeModals() {
                    if ($scope.warning) {
                        $scope.warning.close();
                        $scope.warning = null;
                    }

                    if ($scope.timedout) {
                        $scope.timedout.close();
                        $scope.timedout = null;
                    }
                }

                $scope.$on('IdleStart', function () {
                    if(!AuthService.isLoggedIn()){
                        return;
                    }
                    closeModals();

                    $scope.warning = $modal.open({
                        templateUrl: 'warning-dialog.html',
                        windowClass: 'modal-danger'
                    });
                });

                $scope.$on('IdleEnd', function () {
                  if(!AuthService.isLoggedIn()){
                    return;
                  }
                    closeModals();
                });

                $scope.$on('IdleTimeout', function () {
                  if(!AuthService.isLoggedIn()){
                    return;
                  }
                    closeModals();
                    AuthService.logout();
                    $state.go('login');
                });

                $scope.$on('Keepalive', function () {
                    // Lets check if we have a token... if we do, refresh. other wise do not.
                    AuthService.refreshToken(AuthService.getToken());
                });


                mainCtr.chatOpen = true;
                mainCtr.phoneOpen = false;
                mainCtr.promises = {};
                mainCtr.processingHtml = snipperHtml();

                mainCtr.chat = chat;
                mainCtr.phone = phone;

                mainCtr.alertInfo = alertInfo;
                mainCtr.alertError = alertError;
                mainCtr.alertSuccess = alertSuccess;

                mainCtr.showSpinner = showSpinner;
                mainCtr.hideSpinner = hideSpinner;

                mainCtr.init = function () {
                    $rootScope.settings.layout.pageBodySolid = false;
                    $rootScope.settings.layout.pageSidebarClosed = false;

                    mainCtr.user = AuthService.getUser();
                };
                mainCtr.isPhoneEnabled = function () {
                    return PhoneSettingModel.isPhoneEnabled.data;
                };

                mainCtr.logout = function () {
                    AuthService.logout();
                };

                function chat() {
                    /*mainCtr.chatOpen = !mainCtr.chatOpen;
                     if (mainCtr.phoneOpen && mainCtr.chatOpen) {
                     phone();
                     }*/
                }

                function phone() {
                    mainCtr.phoneOpen = !mainCtr.phoneOpen;
                    if (mainCtr.phoneOpen && mainCtr.chatOpen) {
                        chat();
                    }
                }

                function snipperHtml() {
                    return '<div data-ng-spinner-bar data-ng-show="loadingSpinner" class="page-spinner-bar">' +
                        '<div class="bounce1"></div>' +
                        '<div class="bounce2"></div>' +
                        '<div class="bounce3"></div>' +
                        '</div>';
                }

                function alertSuccess(message) {
                    toaster.success("Success", message, 2000);
                }

                function alertError(message) {
                    toaster.error("Error", message, 2000);
                }

                function alertInfo(message) {
                    toaster.info("Info", message, 2000);
                }

                function showSpinner() {
                    $rootScope.loadingSpinner = true;
                }

                function hideSpinner() {
                    $rootScope.loadingSpinner = false;
                }

                mainCtr.init();
            }
        ])
        .config(function (IdleProvider, KeepaliveProvider) {

            // Show Idle warning (3 minutes)
            IdleProvider.idle(10 * 60);

            // After Idle warning is shown, time out in 2 minutes.
            IdleProvider.timeout(2 * 60);

            //T he tokens are valid for 30minutes. so 20 minutes refresh is good enough.
            KeepaliveProvider.interval(5 * 60);
        })
        .run(function (Idle) {
            // start watching when the app runs. also starts the Keepalive service by default.
            Idle.watch();
        });

})();