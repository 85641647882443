(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.ResetService
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('ResetService', [
      '$http',
      function ($http) {
        var model = this;
        model.sendReset = function (data) {
          return $http.post('/password/reset', data);
        };
        model.sendReminder = function (data) {
          $http.post('/password/email', data);
        };
      }
    ]);
})();