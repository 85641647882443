(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.AllCmplController
   *
   * @description
   * Controller - AllCmplController
   */
  angular
    .module("locksmithManager")
    .controller('AllCmplController', AllCmplController);

  AllCmplController.$inject = ['ComplainsModel', 'DTOptionsBuilder', 'DTColumnBuilder', '$scope'];

  function AllCmplController(ComplainsModel, DTOptionsBuilder, DTColumnBuilder, $scope) {

    var allCmplCtrl = this;

    allCmplCtrl.statistics = ComplainsModel.statistics;

    init();

    allCmplCtrl.dtOptions = DTOptionsBuilder.newOptions()
      .withOption('ajax', {
        url: '/api/complains',
        type: 'GET',
        beforeSend: $scope.cmplCtrl.authHeader
      })
      // or here
      .withDataProp('data')
      .withOption('processing', true)
      .withOption('serverSide', true)
      .withOption('bFilter', false)
      .withOption('createdRow', $scope.cmplCtrl.createdRow)
      .withPaginationType('simple_numbers')
      .withBootstrap()
      .withOption('order', [3, 'desc'])
      .withLanguage({
        "sProcessing": $scope.mainCtr.processingHtml
      });

    allCmplCtrl.dtColumns = [
      DTColumnBuilder.newColumn('record_number').withTitle('Record number')
        .notSortable().withClass('record-number'),

      DTColumnBuilder.newColumn('name').withTitle('Name'),

      DTColumnBuilder.newColumn('phone').withTitle('Phone')
        .renderWith($scope.cmplCtrl.phoneFilter).notSortable().withClass('phone'),

      DTColumnBuilder.newColumn('created_at').withTitle('Create at')
        .renderWith($scope.cmplCtrl.amCalendarFilter),

      DTColumnBuilder.newColumn('status').withTitle('Status')
        .renderWith($scope.cmplCtrl.statusHtml).withClass('status'),

      DTColumnBuilder.newColumn(null).withTitle('')
        .notSortable().renderWith($scope.cmplCtrl.actionsHtml).withClass('actions')
    ];

    function init() {
      ComplainsModel.getStatistics();
    }
  }
})();
