(function () {
  'use strict';
  /**
   * @description
   */
  angular
    .module("locksmithManager")
    .filter('toTrusted', function ($sce) {
      return function (value) {
        return $sce.trustAsHtml(value);
      };
    });
})();