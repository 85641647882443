(function() {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('AssingISPJobModalController', AssingISPJobModalController);

  AssingISPJobModalController.$inject = ['$modalInstance','JobsModel'/*, 'object'*/];

  function AssingISPJobModalController($modalInstance,JobsModel) {
    
    var assingJMC = this;

    assingJMC.techniciansList = [];
    assingJMC.selectedISP = null;

    assingJMC.submit = function() {

      var data = {
        technicianId: assingJMC.selectedISP.id
      };

      $modalInstance.close(data);
    };

    assingJMC.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    JobsModel.getAvailableTechnicians().then(function(response) {
      assingJMC.techniciansList = response;
    });
  }
})();