(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.PhoneNumberRulesController
   *
   * @description
   * Contoller - PhoneNumberRulesController
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype ::
   *   mohan.singh42 )
   */
  angular
    .module("locksmithManager")
    .controller('BlockedNumbersManagerController', [
      "$scope",
      "$modal",
      "blockedNumbers",
      "BlackListModel",
      function ($scope, $modal, blockedNumbers, BlackListModel) {

        var $ctrl = this;

        $ctrl.pageTitle = "Incoming Numbers Black List";
        $ctrl.numbers = blockedNumbers;


        $ctrl.createNewBlockedNumber = function(){
          var newBlockRule = $modal.open({
            animation: true,
            templateUrl: 'app/views/settings/phone/settings/new-blocked-number-modal.html',
            controller: 'NewBlockedNumberModalController',
            controllerAs: 'Ctrl',
            size: 'md',
            resolve: {

            }
          });


          newBlockRule.result.then(
            function (response) {
              $ctrl.numbers = BlackListModel.getAll();
            });


        };

        $ctrl.deleteBlockedNumber = function(number){
          BlackListModel.delete(number).then(function(){
            $ctrl.numbers = BlackListModel.getAll();
          });
        };

      }
    ]);

})();
