(function () {
  "use strict";
  /**
   * @ngdoc provider value
   * @name locksmithManager.provider.emailKeyMap
   *
   * @description
   * Mapping state urls to settings id and appropriate templates
   */

  angular
    .module("locksmithManager")
    .value("emailKeyMap", {
      "smtp": {
        id: "7",
        template: "/app/views/settings/email/settings/smtp.html"
      },
      "notifications": {
        id: "8",
        template: "/app/views/settings/email/settings/notifications.html"
      },
      "templates": {
        id: "9",
        template: "/app/views/settings/email/settings/templates.html"
      }
    });

})();