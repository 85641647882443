(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewBlockedNumberModalController
   *
   * @description
   * Contoller - SearchPhoneModalController
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype ::
   *   mohan.singh42 )
   */
  angular
    .module("locksmithManager")
    .controller('NewBlockedNumberModalController', NewBlockedNumberModalController);
  /**
   * $injector
   * lets inject dependecies
   * @type {Array}
   */
  NewBlockedNumberModalController.$injector = ['toaster', '$modalInstance', 'Spinner', 'BlackListModel'];

  /**
   * SearchPhoneModalController Create a class to encapsulation
   */
  function NewBlockedNumberModalController(toaster, $modalInstance, Spinner, BlackListModel) {

    var $ctrl = this;

    $ctrl.number = {
      'pattern': ''
    };

    $ctrl.cancel = function () {
      $modalInstance.dismiss('cancelModal');
    };

    $ctrl.save = function () {
      Spinner.start();

      BlackListModel.create($ctrl.number).then(function () {
        Spinner.stop();
        toaster.pop('success', "Success", "new rule created");
        $modalInstance.close();
      });

    };


  }
})();
