(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewController
   *
   * @description
   * Contoller - New Controller
   */
  angular
    .module("locksmithManager")
    .controller('NewController', [
      '$scope', 'ServicesModel',
      function ($scope, ServicesModel) {
        var newCtr = this;

        newCtr.newService = {};

        newCtr.create = function () {
          ServicesModel.createService({
            'service_group_name': newCtr.newService.service_group_name,
            'description': newCtr.newService.description || null,
            'base_price': newCtr.newService.base_price || null,
            'services': []
          }).then(function (data) {
            $scope.srvcCtr.init();
          });
        };
      }
    ]);
})();