(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.LeadsSettingsController
   *
   * @description
   * Contoller - LeadsSettingsController
   */
  angular
    .module("locksmithManager")
    .controller('LeadsSettingsController', [
      '$q', '$scope', '$stateParams', 'LeadsModel', '$state', '$timeout',
      function ($q, $scope, $stateParams, LeadsModel, $state, $timeout) {

        var leadCtr = this;

        $scope.$on('$stateChangeSuccess',
          function (event, toState, toParams, fromState, fromParams) {
            if (angular.isUndefined($stateParams.lead_id)) {
              leadCtr.loadingBaseState = false;
            }
            LeadsModel.resetActiveLead();
          });


        leadCtr.init = function () {
          leadCtr.loadingBaseState = false;
          leadCtr.leads = LeadsModel.leads;

          LeadsModel.getLeads();
          LeadsModel.resetActiveLead();
          if ($state.current.name != "settings.base.leads") {
            leadCtr.loadingBaseState = true;
            $state.go('settings.base.leads');
          }
        };

        leadCtr.setActiveLead = function (lead_id) {
          if (leadCtr.loadingBaseState) {
            return false;
          }

          var deffered = $q.defer();
          LeadsModel.resetActiveLead();

          var lead_promise = LeadsModel.getLead(lead_id).then(function (data) {
            $timeout(function () {
              LeadsModel.setActiveLead(data);
              leadCtr.activeLead = LeadsModel.activeLead;
              deffered.resolve(true);
            }, 10);
          }, function () {
            leadCtr.init();
          });

          return deffered.promise;
        };

        leadCtr.delete = function (lead_id) {
          LeadsModel.deleteLead(lead_id);
          leadCtr.init();
        };

        leadCtr.init();
      }
    ]);
})();