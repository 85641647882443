/**
 * Created by nikolay on 3/11/15.
 */

(function () {
  'use strict';


  angular
    .module("locksmithManager")
    .service('PhoneNumberService', PhoneNumberService);

    PhoneNumberService.$inject = ['$resource','$http'];
      function PhoneNumberService($resource,$http) {
        var model = this;


        model.getAllPhoneNumbers = function() {
            var request = $http({
                method: 'GET',
                url: "/api/phone-numbers"
            });

            return  request.success(
                function (requestResponse) {
                    return( requestResponse.data );

                }).error(function (requestResponse) {
                return null;
            });
        };

        model.update = function(phoneNumber) {
          var request = $http({
            method: 'PATCH',
            data: phoneNumber,
            url: "/api/phone-numbers/"+phoneNumber.id
          });


          return request.then(function successCallback(response) {
            // this callback will be called asynchronously
            // when the response is available
          }, function errorCallback(response) {
            // called asynchronously if an error occurs
            // or server returns response with an error status.
          });


        };

        model.release = function(phoneNumber) {
          var request = $http({
            method: 'DELETE',
            data: phoneNumber,
            url: "/api/phone-numbers/"+phoneNumber.id
          });

          return request.then(function successCallback(response) {
            // this callback will be called asynchronously
            // when the response is available
          }, function errorCallback(response) {
            // called asynchronously if an error occurs
            // or server returns response with an error status.
          });
        };


        model.categories = [
            {'id': 0, 'name': 'Main'},
            {'id': 1, 'name': 'Advertising'},
            {'id': 2, 'name': 'Masked'},
            {'id': 3, 'name': 'ISP'},
            {'id': 999, 'name': 'Not Set'}
        ];


      }
})();
