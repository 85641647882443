/**
 * Created by nikolay on 3/3/15.
 */
(function () {
  'use strict';
  /**
   * @description
   */
  angular
    .module("locksmithManager")
    .filter('phone', function () {
      return function (phone) {
        var result = "";
        if (phone !== undefined) {
          if (phone[0] === "+" && phone.length === 12) {
            result = "(" + phone.slice(2, 5) + ")" + " " + phone.slice(5, 8) + "-" + phone.slice(8, 12);
          }
          else {
            if (phone.length === 10) {
              result = "(" + phone.slice(0, 3) + ")" + " " + phone.slice(3, 6) + "-" + phone.slice(6, 10);
            }
          }
        }
        return result;
      };
    });
})();