(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ViewPhoneSettingsController
   *
   * @description
   * Contoller - ViewSettings
   */
  angular
    .module("locksmithManager")
    .controller('ViewPhoneSettingsController', [
      '$scope',
      '$stateParams',
      'PhoneSettingModel',
      'phoneKeyMap',
      'PhoneService',
      function ($scope, $stateParams, PhoneSettingModel, phoneKeyMap, PhoneService) {
        
        var phoneViewCtr = this;

        phoneViewCtr.recording_options = [
          {value: 'record-from-answer', title: 'Record from Answer'},
          {value: 'record-from-ringing', title: 'Record from Ringing'}
        ];

        phoneViewCtr.init = function () {
          phoneViewCtr.group_name = $stateParams.group_id;
          phoneViewCtr.group_template = phoneKeyMap[phoneViewCtr.group_name].template;

          $scope.phoneSetCtr.setPhoneGroup(phoneKeyMap[phoneViewCtr.group_name].id).then(function (data) {
            if (phoneViewCtr.group_name == 'numbers') {
              $scope.phoneSetCtr.phone = PhoneService.phone;
            }
            else {
              if (phoneViewCtr.group_name == 'calls') {
                phoneViewCtr.parsePhoneNumbers($scope.phoneSetCtr.activePhoneGroup.settings[1].value);
              }
              else {
                if (phoneViewCtr.group_name == 'recording' && $scope.phoneSetCtr.activePhoneGroup.settings[0].value) {
                  phoneViewCtr.parseNumber($scope.phoneSetCtr.activePhoneGroup.settings[0]);
                }
              }
            }
            if($stateParams.group_id === 'phoneRules' ){
               phoneViewCtr.group_name = 'Phone number rules';
            }

          });


        };

        phoneViewCtr.refreshPhoneNumbers = function () {
          PhoneService.refreshAvailableNumbers();
        };

        phoneViewCtr.saveFriendlyNumber = function (phone) {
          PhoneService.updateFriendlyName(phone);
        };

        phoneViewCtr.saveSetting = function (setting) {
          PhoneSettingModel.updateSetting(setting);
          // commented out this code to fix LM-230 issue
          /*if (setting.key == 'enable_softphone') {
            PhoneSettingModel.togglePhoneEnabled(setting.value != '0');
          }*/
        };

        phoneViewCtr.savePhoneNumber = function (setting, value) {
          setting.value = phoneViewCtr.appendInternationalCode(value);
          phoneViewCtr.saveSetting(setting);
        };

        phoneViewCtr.saveNumberSetting = function (setting) {
          setting.value = setting.value.toString();
          phoneViewCtr.saveSetting(setting);
        };

        phoneViewCtr.parseNumber = function (setting) {
          setting.value = parseInt(setting.value);
        };

        phoneViewCtr.parsePhoneNumbers = function (number_string) {
          phoneViewCtr.parsedPhoneNumbers = {};
          try {
            phoneViewCtr.parsedPhoneNumbers = JSON.parse(number_string);
          }
          catch (err) {
            phoneViewCtr.parsedPhoneNumbers = {numbers: []};
          }
        };

        phoneViewCtr.saveListPhoneNumber = function (setting) {
          for (var i = phoneViewCtr.parsedPhoneNumbers.numbers.length - 1; i >= 0; i--) {
            phoneViewCtr.parsedPhoneNumbers.numbers[i].number = phoneViewCtr.appendInternationalCode(phoneViewCtr.parsedPhoneNumbers.numbers[i].mask);
          }

          setting.value = JSON.stringify(phoneViewCtr.parsedPhoneNumbers);
          phoneViewCtr.saveSetting(setting);
        };

        phoneViewCtr.appendInternationalCode = function (number) {
          return (number.indexOf("+1") > -1) ? number : ("+1" + number);
        };

        phoneViewCtr.addPhoneNumber = function () {
          phoneViewCtr.inserted = {name: "", number: ""};
          phoneViewCtr.parsedPhoneNumbers.numbers.push(phoneViewCtr.inserted);
        };

        phoneViewCtr.removePhoneNumber = function (index, setting) {
          var old_val = phoneViewCtr.parsedPhoneNumbers.numbers.splice(index, 1)[0];
          if (old_val == phoneViewCtr.inserted) {
            return true;
          }
          phoneViewCtr.saveListPhoneNumber(setting);
        };

        if (angular.isDefined(phoneKeyMap[$stateParams.group_id])) {
          phoneViewCtr.init();
        }
      }
    ]);
})();