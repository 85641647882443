(function () {
  'use strict';

  angular
    .module('locksmithManager')
    .service('ServiceAreaModel', ServiceAreaModel);

  ServiceAreaModel.$inject = ['$resource'];

  /* @ngInject */
  function ServiceAreaModel($resource) {
    var ServiceArea = $resource('api/service-area/:id', {id: '@id'}, {
        update: {method: 'PUT'}
      }),
      serviceAreas = {
          data: []
        },
      activeServiceArea = {
        id: null,
        area_name: null,
        description: null
      },
      service = {
        get: get,
        create: create,
        update: update,
        remove: remove,
        setActiveServiceArea: setActiveServiceArea,
        resetActiveServiceArea: resetActiveServiceArea,
        serviceAreas: serviceAreas,
        activeServiceArea: activeServiceArea
      };

    return service;

    ////////////////

    function get() {
      return ServiceArea.query().$promise.then(function(data) {
        serviceAreas.data = data;
        return data;
      });
    }

    function create(serviceArea) {
      return ServiceArea.save(serviceArea).$promise;
    }

    function update(attributes) {
      return ServiceArea.update(attributes).$promise;
    }

    function remove(id) {
      return ServiceArea.remove({id: id}).$promise;
    }

    function setActiveServiceArea(data) {
      activeServiceArea.id = data.id;
      activeServiceArea.area_name = data.area_name;
      activeServiceArea.description = data.description;
    }

    function resetActiveServiceArea() {
      activeServiceArea.id = null;
      activeServiceArea.area_name = null;
      activeServiceArea.description = null;
    }
  }
})();