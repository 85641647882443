(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewCmplController
   *
   * @description
   * Contoller - NewCmplController
   */
  angular
    .module("locksmithManager")
    .controller('NewCmplController', [
      '$rootScope', 'ComplainsModel', '$state', '$timeout', 'toaster', 'AuthService',
      function ($rootScope, ComplainsModel, $state, $timeout, toaster, AuthService) {
        var newCmplCtrl = this,
          id = 0;

        function viewComplain() {
          $state.go('complains.detail', {complainId: id});
        }

        function viewNewComplain() {
          $state.go('complains.new');
        }


        function clearModel() {
          newCmplCtrl.complain = {
            first_name: '',
            last_name: '',
            caller_id: '',
            phone: '',
            details: ''
          };
          newCmplCtrl.same_caller_id = false;
          newCmplCtrl.submitted = false;
        }

        function alertSuccess(message, action) {
          toaster.pop('success', "Success", message, 2000);
          $timeout(action, 5 * 1000);
        }

        function alertError(message) {

          toaster.pop('error', "Error", message);
        }

        newCmplCtrl.init = function () {
          clearModel();

          newCmplCtrl.alert = {
            message: "",
            class: "",
            show: false
          };
        };

        function showSpinner() {
          $rootScope.loadingSpinner = true;
        }

        function hideSpinner() {
          $rootScope.loadingSpinner = false;
        }

        newCmplCtrl.submit = function (valid) {
          newCmplCtrl.submitted = true;
          if (valid) {
            showSpinner();
            if (newCmplCtrl.same_caller_id) {
              newCmplCtrl.complain.phone = newCmplCtrl.complain.caller_id;
            }

            ComplainsModel.create(newCmplCtrl.complain)
              .success(function (data) {
                id = data.id;
                hideSpinner();
                clearModel();
                if (AuthService.user.limited_user == 1) {
                  alertSuccess("Complain success created!", viewNewComplain);
                } else {
                  alertSuccess("Complain success created! Now you will redirect to detail page.", viewComplain);
                }
              })
              .error(function (data, status) {
                if (status === 422) {
                  alertError("Please check all fields!");
                }
                else {
                  if (status === 401) {
                    alertError(data.error);
                  }
                }
                hideSpinner();

              });
          }
        };

        newCmplCtrl.changeCallerId = function () {
          if (newCmplCtrl.complain.caller_id === "") {
            newCmplCtrl.same_caller_id = false;
          }
        };


        newCmplCtrl.init();
      }
    ]);
})();