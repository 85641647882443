(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ViewEmailSettingsController
   *
   * @description
   * Contoller - ViewSettings
   */
  angular
    .module("locksmithManager")
    .controller("ViewEmailSettingsController", [
      "$scope", "$stateParams", "EmailSettingModel", "emailKeyMap",
      function ($scope, $stateParams, EmailSettingModel, emailKeyMap) {
        var emailViewCtr = this;

        emailViewCtr.init = function () {
          emailViewCtr.group_name = $stateParams.group_name;
          emailViewCtr.group_template = emailKeyMap[emailViewCtr.group_name].template;

          $scope.emailSetCtr.setEmailGroup(emailKeyMap[emailViewCtr.group_name].id).then(function (data) {
            if (emailViewCtr.group_name === "smtp" && $scope.emailSetCtr.activeEmailGroup.settings[1].value) {
              emailViewCtr.parseNumber($scope.emailSetCtr.activeEmailGroup.settings[1]);
            }
          });


        };

        emailViewCtr.saveSetting = function (setting) {
          EmailSettingModel.updateSetting(setting);
        };

        emailViewCtr.saveNumberSetting = function (setting) {
          setting.value = setting.value.toString();
          emailViewCtr.saveSetting(setting);
        };

        emailViewCtr.parseNumber = function (setting) {
          setting.value = parseInt(setting.value);
        };


        if (angular.isDefined(emailKeyMap[$stateParams.group_name])) {
          emailViewCtr.init();
        }
      }
    ]);
})();