(function() {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('NewJobCallbackModalController', NewJobCallbackModalController);

  NewJobCallbackModalController.$inject = ['$modalInstance',/*, 'object'*/];

  function NewJobCallbackModalController($modalInstance) {
    
    var cjc = this;

    cjc.comments = '';

    cjc.submit = function() {

      var data = {
        comments: cjc.comments.trim().substring(0,150)
      };

      $modalInstance.close(data);
    };

    cjc.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
})();