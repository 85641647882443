(function () {
  'use strict';

  angular
    .module('locksmithManager')
    .factory('ResponseInterceptor', interceptor);

  interceptor.$inject = ['$injector', '$q'];

  /* @ngInject */
  function interceptor($injector, $q) {
    return {
      responseError: responseError,
      response: analyzeResponse
    };

    function analyzeResponse(response) {

      var AuthService = $injector.get("AuthService");

      var headers = response.headers();
      if (headers.authorization) {
        var m = headers.authorization.match(/^Bearer ([^,]*)/);
        AuthService.setToken(m[1]);
      }
      return response;
    }


    function responseError(data) {
      //422 - validations error
      //423 - resource is locked
      if (400 <= data.status && data.status < 500 &&
        data.status !== 404 &&
        data.status !== 423 &&
        data.status !== 422) {
        var AuthService = $injector.get("AuthService"),
          $state = $injector.get("$state"),
          toaster = $injector.get("toaster"),
          $timeout = $injector.get("$timeout");

        AuthService.logout();//logout from system

        //Message
        toaster.info("Response error", "Please login again");

        if ($state.current !== 'login') {
          $state.go('login');
        }

      }

      return $q.reject(data);
    }
  }
})();