(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ViewEmailSettingsController
   *
   * @description
   * Contoller - ViewSettings
   */
  angular
    .module("locksmithManager")
    .controller('EmailTemplateController', [
      '$scope', '$stateParams', 'EmailSettingModel', 'emailKeyMap', 'textAngularManager', '$http', 'toaster',
      function ($scope, $stateParams, EmailSettingModel, emailKeyMap, textAngularManager, $http, toaster) {

        $scope.editor = {};
        $scope.useCutsomTemplate = false;
        // $scope.additionsToolbar = [['insertCustomerName','insertCustomerAddress','insertCustomerPhoneNumber','insertCustomerCallerID','insertLeadType','insertComments','insertJobService','insertJobType']];
        $scope.additionsToolbar = [[]];
        $scope.buttonPreviewDisabled = true;
        $scope.templateTokens = null;
        $scope.submitTestMailButtonDisabled = false;
        // used so that form errors are shown only after the form has been submitted
        $scope.testMailFormSubmitted = false;

        $scope.codemirrorLoaded = function (_editor) {

          var _doc = _editor.getDoc();
          _editor.focus();

          // Options
          _doc.markClean();

          $scope.editor = _editor;

        };

        $scope.loadTemplate = function () {
          // A template was selected, try to load default information
          $scope.fetchTemplateData($scope.emailTemplate.selected.code);
          $scope.buttonPreviewDisabled = false;
        };

        $scope.cancelTemplateEdit = function () {
          $scope.emailTemplate.selected = null;
        };

        $scope.fetchTemplateData = function (templateCode) {
          var settings = EmailSettingModel.activeEmailGroup.settings;

          for (var index = 0; index < settings.length; index++) {
            var templateSetting = settings[index];
            if (templateSetting && templateSetting.key == templateCode) {
              $scope.markup = templateSetting.value.template;
              $scope.currentTemplateSetting = templateSetting;
              $scope.clearToolbar();
              $scope.templateTokens = templateSetting.value.tokens;
              $scope.populateToolbar();
              break;
            }
          }
        };

        $scope.clearToolbar = function () {
          // console.log("clearToolbar: " + !$scope.templateTokens);
          if (!$scope.templateTokens) {
            return;
          }
          var parsedTokens = angular.fromJson($scope.templateTokens);

          for (var index = 0; index < parsedTokens.length; index++) {
            var tokenCode = parsedTokens[index].tokenCode;
            var tokenLabel = parsedTokens[index].tokenLabel;
            var token = parsedTokens[index].token;
            textAngularManager.removeTool(tokenCode);
          }
          document.getElementById('additionsDiv').innerHTML = '<div class="btn-group"></div>';
          textAngularManager.refreshEditor("templateEditor");
        };

        $scope.populateToolbar = function () {
          if (!$scope.templateTokens) {
            return;
          }
          var parsedTokens = angular.fromJson($scope.templateTokens);
          //console.table(parsedTokens);
          var functionHandler = function (token) {
            return function () {
              this.$editor().wrapSelection('insertHTML', token);
            };
          };

          for (var index = 0; index < parsedTokens.length; index++) {
            var tokenCode = parsedTokens[index].tokenCode;
            var tokenLabel = parsedTokens[index].tokenLabel;
            var token = parsedTokens[index].token;
            var data = {
              buttontext: tokenLabel,
              action: functionHandler(token)
            };
            textAngularManager.addToolToToolbar(tokenCode, data, 'additions');
          }
        };

        $scope.saveTemplate = function () {
          //console.table($scope.currentTemplateSetting);
          if ($scope.currentTemplateSetting !== null) {
            $scope.currentTemplateSetting.value.template = $scope.markup;
          }
          //console.table($scope.currentTemplateSetting);

          EmailSettingModel.updateSetting($scope.currentTemplateSetting);
        };

        $scope.emailTemplate = {};
        $scope.emailTemplates = [

          // Jobs
          {
            name: 'Job: New - Manager Notification',
            code: 'job_new_manager_notification_email'
          },
          {
            name: 'Job: New - Technician Email',
            code: 'job_new_technician_notification_email'
          },
          {
            name: 'Job: Update - Manager Notification',
            code: 'job_update_manager_notification_email'
          },
          {
            name: 'Job: Technician Close Link',
            code: 'job_tech_close_link'
          },

          // Complains
          {
            name: 'Complaint: New - Manager Notification',
            code: 'complaint_new_manager_notification_email'
          },
          {
            name: 'Complaint: Update - Manager Notification',
            code: 'complaint_update_manager_notification_email'
          },

          // Call Backs
          {
            name: 'Callback: New - Manager Notification',
            code: 'callback_new_manager_notification_email'
          },
          {
            name: 'Callback: Update - Manager Notification',
            code: 'callback_update_manager_notification_email'
          },

          // General Request
          {
            name: 'General Request: New - Manager Notification',
            code: 'gen_request_manager_notification_email'
          },
          {
            name: 'General Request: Update - Manager Notification',
            code: 'gen_request_update_manager_notification_email'
          }
        ];


        $scope.textAreaSetup = function ($element) {
          $element.attr('ui-codemirror', 'codeMirrorSettings');
        };

        $scope.submitTestMail = function (contactform) {
          $scope.testMailFormSubmitted = true;
          $scope.submitTestMailButtonDisabled = true;
          if (contactform.$valid) {
            var formData = angular.copy(contactform);
            var data = {
              entityId: $scope.formData.inputid,
              targetMail: $scope.formData.inputEmail,
              emailTemplateCode: $scope.emailTemplate.selected.code
            };
            var submitRequest = EmailSettingModel.sendTestMail(data);
            submitRequest
              .then(
                function (response) {
                  toaster.pop('success', "Success", 'Test email has been sent successfully');
                  $scope.submitButtonDisabled = false;
                },
                function (error) {
                  toaster.pop('error', "Error", 'Error while sending test email. Error - ' + error.data.message);
                  $scope.submitButtonDisabled = false;
                }
              );
          } else {
            $scope.submitButtonDisabled = false;
          }
        };


        $scope.resetTestMail = function () {
          $scope.submitTestMailButtonDisabled = false;
          if ($scope.formData && $scope.formData.inputEmail) {
            $scope.formData.inputEmail = null;
          }
          if ($scope.formData && $scope.formData.inputid) {
            $scope.formData.inputid = null;
          }
        };
      }

    ]);
})();