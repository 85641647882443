(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.SidebarController
   *
   * @description
   * Setup Layout Part - Quick Sidebar
   */
  angular
    .module("locksmithManager")
    .controller('PhoneController', [
      '$scope',
      '$http',
      '$timeout',
      '$rootScope',
      'PhoneService',
      'PhoneSettingModel',
      function ($scope, $http, $timeout, $rootScope, PhoneService, PhoneSettingModel) {

        $scope.init = function () {
          $scope.phone = PhoneService.phone;
          $scope.call = {
            phoneNumber: '',
            callFrom: '',
            status: ''
          };

          $scope.members = PhoneService.members;

          PhoneSettingModel.getOutgoingPhoneNumber().then(function (fromCaller) {
            $scope.call.callFrom = fromCaller;
          });

          $scope.$on('$includeContentLoaded', function () {
            setTimeout(function () {
              // init quick sidebar
              QuickSidebar.init();
            }, 10);
          });

          PhoneService.setup();

          $scope.mainCtr.promises.checkPhoneEnabled = PhoneSettingModel.checkPhoneEnabled();

        };

        $scope.dialDigit = function (digit) {
          PhoneService.dialDigit(digit);
          $scope.call.phoneNumber += digit;
        };

        $scope.dial = function () {
          PhoneService.dial($scope.call.phoneNumber);
          // $scope.mainCtr.phone();
        };

        $scope.hangUp = function () {
          PhoneService.hangUp();
          clearCall();
        };

        $scope.putOnHold = function (callSid) {
          PhoneService.putOnHold(callSid);
          clearCall();
        };


        var clearCall = function () {
          $scope.call = {
            phoneNumber: '',
            callFrom: '',
            status: ''
          };
        };

        $scope.init();
      }
    ]);
})();