(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ViewController
   *
   * @description
   * Controller - View page Controller
   */
  angular
    .module("locksmithManager")
    .controller('ViewPaymentTypesController', ViewPaymentTypesController);

  ViewPaymentTypesController.$inject = ['$scope', '$stateParams'];

  function ViewPaymentTypesController($scope, $stateParams) {
    var viewCtr = this;

    $scope.pmntTpCtr.setActivePaymentType($stateParams.payment_type_id);
  }
})();