/**
 * @ngdoc Directive
 * @name locksmithManager.Directive.priceValidation
 *
 * @description
 * Validate price value
 * 1.Should be greater than 0
 * 2.Can be float value
 * 3.Should be numeri
 */
angular
  .module('locksmithManager')
  .directive('priceValidation', [

    function() {
      /**
       * Return Directive definition Object
       */
      return {
        restrict: 'A',
        require: 'ngModel',
        link: link
      };
      /**
       * Price validation
       * Triggered when price field model is updated
       * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
       * @param  {[type]} scope   Controller scope
       * @param  {[type]} el      Element
       * @param  {[type]} attrs   Element's Attributes
       * @param  {[type]} ngModel Element model
       * @return {[type]}         Directive definition Object
       */
      function link(scope, el, attrs, ngModel) {
        /**
         * Do nothing if there is no model
         */
        if (!ngModel) return;
        /**
         * Keep watching model and
         * validate field value
         */
        scope.$watch(attrs.ngModel, function() {
          validateField();
        });
        /**
         * Validate price
         */
        function validateField() {
          var regex = /^\d{0,9}(\.\d{1,9})?$/,
            viewValue = ngModel.$modelValue,
            floatValue = parseFloat(viewValue);
            if(!viewValue){
              ngModel.$setValidity('validPrice', true);
              return viewValue;
            }

          if (floatValue >= 0 && regex.test(viewValue)) {
            ngModel.$setValidity('validPrice', true);
          } else {
            ngModel.$setValidity('validPrice', false);
          }
          return viewValue;
        }
      }
    }
  ]);