(function () {
  'use strict';

  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewJobController
   *
   * @description
   * Controller - NewJobController
   */
  angular
    .module("locksmithManager")
    .controller('NewJobController', NewJobController);

  NewJobController.$inject = [
    '$rootScope',
    '$scope',
    'LeadsModel',
    'ServicesModel',
    'uiGmapIsReady',
    '$timeout',
    'JobsModel',
    'AuthService',
    'UsersModel',
    '$pusher'
  ];

  function NewJobController($rootScope, $scope, LeadsModel, ServicesModel, uiGmapIsReady, $timeout, JobsModel, AuthService, UsersModel, $pusher) {
    var newJobCtrl = this;

    newJobCtrl.create = create;
    newJobCtrl.resetForm = resetForm;
    newJobCtrl.tech_availability = 1;


    var chatCtrl = this;

    var client = new Pusher(window.lm.pusher, {
      authEndpoint: '/pusher/auth?token=' + AuthService.token_obj.token
    });

    var pusher = $pusher(client);

    // Cleanup stuff...
    $scope.$on('$destroy', function () {
      pusher.unsubscribe('technicians');
    });

    // Pusher Notifications
    var chat_channel = pusher.subscribe('chat');
    chat_channel.bind_all(function (event, data) {
      // Listen to any push regards a job, and whenever it comes refresh the
      // history.

      switch (event) {

        case 'App\\Events\\Technicians\\TechnicianAvailablityChanged':
          // only pop a notification if the current user is not the one who
          // created the new message.
          getAvailableTechnicians();
          break;
      }
    });
    /////////////////////////////

    // set sidebar closed and body solid layout mode
    $rootScope.settings.layout.pageBodySolid = true;
    $rootScope.settings.layout.pageSidebarClosed = false;
    $scope.availableTechs = [];

    $scope.processing = false;

    $scope.$on('$viewContentLoaded', function () {
      // initialize core components
      Metronic.initAjax();
    });

    function initGoogleMap() {
      newJobCtrl.map = {
        center: {
          latitude: 38.825568,
          longitude: -77.810027
        },
        control: {},
        options: {
          scrollwheel: false
        },
        zoom: 8
      };

      newJobCtrl.addressMarker = {
        id: 0,
        coords: {
          latitude: 0,
          longitude: 0
        },
        options: {
          draggable: false
        }
      };

      // Using the googles variable names to make things easier...
      newJobCtrl.address = {
        'street_number': '',// House Number
        'route': '',        // Street Name
        'unit_number': '',// Apt/Suite (not in google maps)
        'locality': '',     // City
        'administrative_area_level_1': '',// State
        'postal_code': '',// Zip Code
        'comments': ''      // Not in Google Maps.
      };

      newJobCtrl.autocompleteAddress = '';
      newJobCtrl.autoCompleteAddressDetails = {};

      uiGmapIsReady.promise(1).then(function (instances) {

        instances.forEach(function (inst) {
          var map = inst.map;
          //var uuid = map.uiGmap_id;
          //var mapInstanceNumber = inst.instance; // Starts at 1.
          if (newJobCtrl.map.control.refresh) {
            newJobCtrl.map.control.refresh({
              latitude: 32.779680,
              longitude: -79.935493
            });
          }


          $scope.$watch('newJobCtrl.autoCompleteAddressDetails', function () {

            if (typeof(newJobCtrl.autoCompleteAddressDetails.geometry) !== 'undefined') {

              newJobCtrl.addressMarker.coords.latitude = newJobCtrl.autoCompleteAddressDetails.geometry.location.lat();
              newJobCtrl.addressMarker.coords.longitude = newJobCtrl.autoCompleteAddressDetails.geometry.location.lng();

              // Reset...
              newJobCtrl.address = {
                'street_number': '',                // House Number
                'route': '',                        // Street Name
                'unit_number': '',                  // Apt/Suite (not in google
                                                    // maps)
                'locality': '',                     // City
                'administrative_area_level_1': '',  // State
                'postal_code': ''                   // Zip Code
              };
              // Fill in Address Details fields
              _.forEach(newJobCtrl.autoCompleteAddressDetails.address_components, function (address, key) {
                if (typeof(newJobCtrl.address[address.types[0]]) !== 'undefined') {

                  newJobCtrl.address[address.types[0]] = address.short_name;
                }
              });

              map.panTo(new google.maps.LatLng(
                newJobCtrl.autoCompleteAddressDetails.geometry.location.lat(),
                newJobCtrl.autoCompleteAddressDetails.geometry.location.lng()
              ));
              map.setZoom(15);

              getAvailableTechnicians('address');

            }
          });
        });
      });
    }

    function getAvailableTechnicians(call) {

      var filters = {
        address: newJobCtrl.address,
        location: newJobCtrl.addressMarker.coords,
        service_type: newJobCtrl.serviceType.id,
        tech_availability: 1//newJobCtrl.tech_availability
      };

      //if (filters.address.postal_code !== "" && filters.service_type !==
      // null){
      $scope.jobCtrl.showSpinner();
      UsersModel.getAvailableTechnicians(filters).then(function (requestResponse) {
        $scope.availableTechs = requestResponse.data;
        if ($scope.availableTechs.length == 0 && call == 'serviceType') {
          $('.alert-modal').trigger('click');
          $('#alert-modal').find('p').html('<b>Currently I have no available technician for that specific service</b>');
        }
        else if ($scope.availableTechs.length == 0 && call == 'address') {
          $('.alert-modal').trigger('click');
          $('#alert-modal').find('p').html('<b>Currently I have no available technician for that specific location</b>');
        }

        if (call == 'serviceType' || call == 'address') {
          var url = window.location.href;
          var interval = setInterval(function(){
            if (!availableFilter(url)) {
              clearInterval(interval);
            }
          },10);
        }
        $scope.jobCtrl.hideSpinner();
      });
      //}
    }

    function availableFilter(url) {
      if (url == window.location.href) {
        $('#available-technicians').find('li').each(function() {
            var item_id = $(this).attr('data-key');
            var test = 0;
            for (var i = 0; i < $scope.availableTechs.length; i++) {
              if ($scope.availableTechs[i].id == item_id) {
                test++;
              }
            }
            if (test == 0) {
              $(this).attr('style','background:rgba(229,61,60,0.2);');
            } else {
              $(this).attr('style','background:rgba(68,229,9,0.2);');
            }
          });
        return true;
      } else {
        $('#available-technicians').find('li').attr('style','');
        return false;
      }
    }

    function resetForm() {

      newJobCtrl.leadsOptions = LeadsModel.leads;
      newJobCtrl.servicesOptions = ServicesModel.services;

      newJobCtrl.jobInfo = {
        name: '',
        phone: '',
        caller_id: '',
        comments: ''
      };

      newJobCtrl.tech_availability = 1;
      newJobCtrl.selectedLead = {};

      newJobCtrl.serviceType = {};
      newJobCtrl.servicesOption = {};

      newJobCtrl.autocompleteAddress = '';

      newJobCtrl.submitted = false;
    }
    function init() {

      initGoogleMap();
      initWatchers();

      LeadsModel.getLeads();
      ServicesModel.getServices();

      resetForm();
    }
    function create(isValid) {
      $scope.jobCtrl.showSpinner();
      $scope.processing = true;
      newJobCtrl.submitted = true;
      if (isValid && newJobCtrl.serviceType.id) {
        var jobInfo = {
          "service_id": newJobCtrl.serviceType.id,
          "name": newJobCtrl.jobInfo.name,
          "caller_id": newJobCtrl.jobInfo.caller_id,
          "phone": newJobCtrl.jobInfo.phone,
          "searched_location": newJobCtrl.autocompleteAddress,
          "address_notes": newJobCtrl.address.comments,
          "street_number": newJobCtrl.address.street_number,
          "street_name": newJobCtrl.address.route,
          "unit_number": newJobCtrl.address.unit_number,
          "city": newJobCtrl.address.locality,
          "state": newJobCtrl.address.administrative_area_level_1,
          "postal_code": newJobCtrl.address.postal_code,
          "lead_id": null,
          "assigned_technician_id": newJobCtrl.selectedTech.id,
          "comments": newJobCtrl.jobInfo.comments,
          "service_type_price": newJobCtrl.jobInfo.service_type_price
        };

        JobsModel.create(jobInfo)
          .then(function (data) {

            resetForm();

            $scope.jobCtrl.alertSuccess("Job created");

            if (AuthService.user.limited_user == 1) {
              $scope.jobCtrl.viewNewJobPage();

            }
            else {
              $scope.jobCtrl.viewJobDetails(data.id);
            }


          }, function (data) {
            if (data.data.city !== undefined || data.data.street_number !== undefined || data.data.postal_code !== undefined || data.data.state !== undefined) {
              $scope.jobCtrl.alertError('Address could not be found. Please enter it manually in the address details.');
            }else{
              $scope.jobCtrl.alertError('Unable to create a new job at this time, please try again later');
            }
          })
          .catch(function () {
            $scope.jobCtrl.alertError('Unable to create a new job at this time, please try again later');
          })
          .finally(function () {
            $scope.processing = false;
            $scope.jobCtrl.hideSpinner();
          });
      }
    }

    function initWatchers() {
      $scope.$watch('newJobCtrl.serviceType', function () {
        getAvailableTechnicians('serviceType');
      });
      $scope.$watch('newJobCtrl.tech_availability', function () {
        getAvailableTechnicians();
      });

    }

    init();
  }
})();