(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.BaseUsersController
   *
   * @description
   * User's module parent controller
   */
  angular
    .module("locksmithManager")
    .controller('BaseUsersController', [
      '$rootScope',
      '$scope',
      '$ocLazyLoad',
      'UsersModel',
      'toaster',
      '$state',
      'AuthService',
      function ($rootScope, $scope, $ocLazyLoad, usersModel) {

        $scope.$on('$viewContentLoaded', function () {
          // initialize core components
          Metronic.initAjax();
        });

        // set sidebar closed and body solid layout mode
        $rootScope.settings.layout.pageBodySolid = false;
        $rootScope.settings.layout.pageSidebarClosed = false;

        /**
         * Load users by default
         */
        usersModel.getUsers();
        /**
         * userRoles An array of user roles
         * @True {Array}
         */
        $scope.userRoles = usersModel.userRoles = [
          {
            role: '',
            name: 'Select user role'
          }, {
            role: 0,
            name: 'Administrator User'
          }, {
            role: 1,
            name: 'Technician User'
          }, {
            role: 2,
            name: 'Sales User'
          }, {
            role: 3,
            name: 'Dispatch User'
          }
        ];

      }
    ]);
})();