(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.PhoneNumberRulesController
   *
   * @description
   * Contoller - PhoneNumberRulesController
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype ::
   *   mohan.singh42 )
   */
  angular
    .module("locksmithManager")
    .controller('PhoneNumberManagerController', [
      "$scope", '$modal', "$stateParams", 'ReportsModel', 'toaster', 'UsersModel', 'Spinner', 'PhoneNumberService',
      function ($scope, $modal, $stateParams, ReportsModel, toaster, UsersModel, Spinner, PhoneNumberService) {

        var self = this;

        self.category = '';
        self.categories = PhoneNumberService.categories;
        self.phoneNumbers = [];

        PhoneNumberService.getAllPhoneNumbers().then(function (data) {

          self.phoneNumbers = data.data;

        });


        self.editPhoneNumber = function (phoneNumber) {

          var editPhoneNumberModal = $modal.open({
            animation: true,
            templateUrl: 'app/views/settings/phone/settings/phone-number-edit.html',
            controller: 'EditPhoneModalController',
            controllerAs: 'Ctrl',
            resolve: {
              phoneNumber: function () {
                return phoneNumber;
              },
              categories: function () {
                return self.categories;
              }
            }
          });

          editPhoneNumberModal.result.then(
            function (response) {
              PhoneNumberService.getAllPhoneNumbers().then(function (data) {
                self.phoneNumbers = data.data;

              });
            });


        };

        self.releasePhoneNumber = function (phoneNumber) {

          var releasePhoneNumber = $modal.open({
            animation: true,
            templateUrl: 'app/views/settings/phone/settings/phone-number-release.html',
            controller: 'ReleasePhoneNumberController',
            controllerAs: 'Ctrl',
            size: 'md',
            resolve: {
              phoneNumber: function () {
                return phoneNumber;
              }
            }
          });


          releasePhoneNumber.result.then(
            function (response) {
              PhoneNumberService.getAllPhoneNumbers().then(function (data) {
                self.phoneNumbers = data.data;

              });
            });




        };

        self.showNewPhoneRuleModal = function (isEdit, index) {

          var newPhoneNumber = $modal.open({
            animation: true,
            templateUrl: 'app/views/settings/phone/settings/search-phone-modal.html',
            controller: 'SearchPhoneModalController',
            controllerAs: 'searchModalCtrl',
            size: 'lg',
            resolve: {
              STATES: ['PhoneService', '$q', function (PhoneService, $q) {
                var deferred = $q.defer();
                PhoneService.getStates().then(function (response) {
                  if (response.hasOwnProperty('data')) {
                    deferred.resolve(response.data);
                  }
                  else {
                    deferred.resolve([]);
                  }
                }).catch(function (error) {
                  deferred.resolve(null);
                });
                return deferred.promise;
              }]
            }
          });

          newPhoneNumber.result.then(
            function (response) {
              PhoneNumberService.getAllPhoneNumbers().then(function (data) {
                self.phoneNumbers = data.data;

              });
            },
            function (error) {

            });


        };

      }
    ]);

})();
