(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.EditController
   *
   * @description
   * Controller - Edit Controller
   */
  angular
    .module("locksmithManager")
    .controller('EditPaymentTypesController', EditPaymentTypesController);

  EditPaymentTypesController.$inject = ['$scope', '$stateParams', 'PaymentTypesModel'];

  function EditPaymentTypesController($scope, $stateParams, PaymentTypesModel) {
    var editCtr = this;

    $scope.pmntTpCtr.setActivePaymentType($stateParams.payment_type_id).then(function () {
      editCtr.editPaymentType = angular.copy(PaymentTypesModel.activePaymentType);
    });

    editCtr.update = function () {
      PaymentTypesModel.updatePaymentType(editCtr.editPaymentType).then(function () {
        $scope.pmntTpCtr.init();
      });
    };
  }
})();