/**
 * Created by nikolay on 3/4/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.GeneralRequestsModel
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('GeneralRequestsModel', [
      '$http', 'Statuses',
      function ($http, Statuses) {
        var model = this,
          URLS = {
            FETCH: '/api/general-requests',
            SHOW: '/api/general-requests/:id',
            EDIT: '/api/general-requests',
            UPDATE: '/api/general-requests/:id',
            DELETE: '/api/general-requests/:id',
            CREATE: '/api/general-requests',
            SEARCH: '/api/general-requests/search',
            STATISTICS: '/api/general-requests/dashboard/statistics',
            HISTORY: '/api/generalRequestHistory/:id',
            DASHBOARD_TABLES: '/api/general-requests/dashboard/general-requests',
            DASHBOARD_HISTORY: '/api/general-requests/dashboard/history'
          };

        model.getStatistics = getStatistics;
        model.getHistory = getHistory;
        model.getDashboardTables = getDashboardTables;
        model.getDashboardHistory = getDashboardHistory;

        model.generalRequest = {
          data: []
        };

        model.generalRequests = {
          data: []
        };

        model.statistics = {
          open: 0,
          resolved: 0,
          cancelled: 0
        };

        function extract(result) {
          return result.data;
        }

        model.create = function (data) {
          return $http.post(URLS.CREATE, data);
        };

        function cacheGeneralRequest(result) {
          model.generalRequest.data = extract(result);
          return model.generalRequest.data;
        }

        model.create = function (data) {
          return $http.post(URLS.CREATE, data);
        };

        model.getGeneralRequest = function () {
          return $http.get(URLS.FETCH, {
            params: {
              'page': model.paginate.current_page,
              'per_page': model.paginate.per_page,
              'field': model.sorting.field,
              'direction': model.sorting.direction
            }
          }).then(cacheGeneralRequest);
        };

        model.showGeneralRequest = function (id) {
          return $http.get(URLS.SHOW.replace(':id', id)).then(cacheGeneralRequest);
        };

        model.updateGeneralRequest = function (id, data, message) {
          data.message = message;
          return $http.put(URLS.UPDATE.replace(':id', id), data);
        };

        model.deleteGeneralRequest = function (id) {
          return $http.delete(URLS.DELETE.replace(':id', id));
        };

        model.getTextStatus = Statuses.entityTextStatus;

        function getStatistics() {
          return $http.get(URLS.STATISTICS).then(function(data) {
            var extract_result = extract(data);
            model.statistics.open = extract_result.open;
            model.statistics.resolved = extract_result.resolved;
            model.statistics.cancelled = extract_result.cancelled;
            return data;
          });
        }

        function getHistory(id) {
          return $http.get(URLS.HISTORY.replace(':id', id))
            .then(function(data) {
              model.generalRequest.history = extract(data);
              return data;
            });
        }

        function getDashboardTables() {
          return $http.get(URLS.DASHBOARD_TABLES);
        }

        function getDashboardHistory() {
          return $http.get(URLS.DASHBOARD_HISTORY);
        }

      }
    ]);
})();