(function() {
  'use strict';

  angular
    .module('locksmithManager')
    .controller('CancelJobModalController', CancelJobModalController);

  CancelJobModalController.$inject = ['$modalInstance',/*, 'object'*/];

  function CancelJobModalController($modalInstance) {
    
    var cjc = this;

    cjc.cancelReasonText = '';

    cjc.submit = function() {

      if(cjc.cancelReasonText=='custom')
      {
        cjc.cancelReasonText = $('#other').val().trim().substring(0,150);
      }

      var data = {
        cancelText: cjc.cancelReasonText
      };

      $modalInstance.close(data);
    };

    cjc.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
})();