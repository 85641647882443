(function() {
    'use strict';
    /**
     * @ngdoc Service
     * @name locksmithManager.Service.ReportsModel
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .service('ReportsModel', [
            '$http',
            function($http) {

                var model = this,
                    URLS = {
                        all: '/api/reports/available/:user_id?start_date=:start_date&end_date=:end_date',
                        get: '/api/reports/:user_id?start_date=:start_date'
                    };

                /**
                 * GET(
                 *   path : "/api/reports/available{user_id}?start_date=yyyy-mm-dd&end_date=yyyy-mm-dd",
                 *   parameters(
                 *     user_id : integer,
                 *     start_date : string
                 *     end_date : string
                 *   )
                 * )
                 *
                 * @return $promise
                 */
                model.all = function(params) {
                    return $http.get(URLS.all.replace(':user_id', params.user_id).replace(':start_date', params.start_date).replace(':end_date', params.end_date));
                };
                /**
                 * GET(
                 *   path : "/api/reports{user_id}?start_date=yyyy-mm-dd",
                 *   parameters(
                 *     user_id : integer,
                 *     start_date : string
                 *   )
                 * )
                 *
                 * @return $promise
                 */
                model.get = function(params) {
                    return $http.get(URLS.get.replace(':user_id', params.user_id).replace(':start_date', params.start_date));
                };
            }
        ]);
})();
