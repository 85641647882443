(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.ServicesModel
   *
   * @description
   * Model utility service
   */
  angular
    .module("locksmithManager")
    .service('ServicesModel', [
      '$http', 'toaster',
      function ($http, toaster) {
        var model = this,
          URLS = {
            FETCH: '/api/services',
            EDIT: '/api/services',
            DELETE: '',
            POST: ''
          },
          services,
          serviceTypes;

        model.services = {
          data: []
        };
        model.serviceTypes = {
          data: []
        };
        model.activeService = {
          id: null,
          service_group_name: null,
          description: null,
          base_price: null,
          services: []
        };

        model.activeServiceType = {
          id: null,
          service_group_id: null,
          service_title: null,
          service_description: null,
          base_price: null
        };


        function extract(result) {
          return result.data;
        }

        function cacheServices(result) {
          model.services.data = extract(result);

          return model.services;
        }

        function cacheServiceTypes(result) {
          model.serviceTypes.data = extract(result);
        }

        function throwError(message) {
          toaster.pop('error', "Error", message);
        }

        model.setActiveService = function (data) {
          model.activeService.id = data.id;
          model.activeService.service_group_name = data.service_group_name;
          model.activeService.description = data.description;
          model.activeService.base_price = data.base_price;
          model.activeService.services = data.services;

        };

        model.setActiveServiceType = function (data) {
          model.activeServiceType.id = data.id;
          model.activeServiceType.service_group_id = data.service_group_id;
          model.activeServiceType.service_title = data.service_title;
          model.activeServiceType.service_description = data.service_description;
          model.activeServiceType.base_price = data.base_price;
        };

        model.resetActiveService = function () {
          model.activeService.id = null;
          model.activeService.service_group_name = null;
          model.activeService.description = null;
          model.activeService.base_price = null;
          model.activeService.services = [];
        };

        model.resetActiveServiceType = function () {
          model.activeServiceType.id = null;
          model.activeServiceType.service_group_id = null;
          model.activeServiceType.service_title = null;
          model.activeServiceType.service_description = null;
          model.activeServiceType.base_price = null;
        };

        model.updateService = function (editservice) {

          var service_id = editservice.id;
          return $http.put(URLS.FETCH + '/' + service_id, editservice).then(function (data) {
            //console.log(extract(data));
            model.getServices();

            toaster.pop('success', "Success", "service updated", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to update the service at this time, please try again later');

          });
        };

        model.updateServiceType = function (editservicetype) {

          var service_id = model.activeService.id;
          var service_type_id = editservicetype.id;
          return $http.put(URLS.FETCH + '/' + service_id + '/' + service_type_id, editservicetype).then(function (data) {
            //console.log(extract(data));
            model.getServiceTypes();

            toaster.pop('success', "Success", "service type updated", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to update service type at this time, please try again later');

          });
        };

        model.deleteService = function (service_id) {
          return $http.delete(URLS.FETCH + '/' + service_id).then(function (data) {

            model.getServices();

            toaster.pop('success', "Success", "service deleted", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to delete the service at this time, please try again later');

          });
        };

        model.deleteServiceType = function (type_id) {
          return $http.delete(URLS.FETCH + '/' + model.activeService.id + '/' + type_id).then(function (data) {

            model.getService(model.activeService.id).then(function (data) {
              model.setActiveService(data[0]);
              model.getServiceTypes();
            });

            toaster.pop('success', "Success", "service type deleted", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to delete the service type at this time, please try again later');

          });
        };

        model.createService = function (service) {

          return $http.post(URLS.FETCH, service).then(function (data) {
            //console.log(extract(data));
            model.getServices();

            toaster.pop('success', "Success", "service Created", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to create a new service at this time, please try again later');

          });
        };

        model.createServiceType = function (service_type) {

          return $http.post(URLS.FETCH + '/' + model.activeService.id, service_type).then(function (data) {
            //console.log(extract(data));
            model.getServiceTypes();

            toaster.pop('success', "Success", "service type Created", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to create a new service type at this time, please try again later');

          });
        };

        model.getServices = function () {

          return $http.get(URLS.FETCH).then(cacheServices);
        };

        model.getServiceTypes = function () {

          return cacheServiceTypes(model.activeService.services);
        };

        model.getService = function (service_id) {
          return $http.get(URLS.FETCH + '/' + service_id).then(extract);
        };

        model.getServiceType = function (type_id) {

          return $http.get(URLS.FETCH + '/' + model.activeService.id + '/' + type_id).then(extract);
        };

      }
    ]);
})();