/**
 * Created by nikolay on 3/4/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.DetailCbkController
   *
   * @description
   * Controller - DetailCbkController
   */
  angular
    .module("locksmithManager")
    .controller('DetailCbkController', DetailCbkController);

  DetailCbkController.$inject = ['CallbacksModel', '$stateParams', '$scope', 'taOptions', 'notes'];

  function DetailCbkController(CallbacksModel, $stateParams, $scope, taOptions, notes) {
    var detailCbkCtrl = this,
      id = $stateParams.callbackId;

    detailCbkCtrl.callback = {
      data: [],
      history: []
    };

    detailCbkCtrl.notes = notes;
    detailCbkCtrl.clb = {};

    detailCbkCtrl.message = '';

    // Job Status Methods used to display/hide elements
    detailCbkCtrl.status = {
      isOpen: statusIsOpen,
      isResolved: statusIsResolve,
      isCancelled: statusIsCancel
    };

    detailCbkCtrl.update = update;
    detailCbkCtrl.resolve = resolve;
    detailCbkCtrl.cancel = cancel;

    init();

    //////////////////////

    function getCallback() {
      $scope.mainCtr.showSpinner();
      getHistory();
      CallbacksModel.showCallback(id)
        .then(function () {
          angular.copy(detailCbkCtrl.callback.data, detailCbkCtrl.clb);
        })
        .catch(function (data) {
          $scope.mainCtr.alertError(data.data.error);
        })
        .finally(function () {
          $scope.mainCtr.hideSpinner();
        });
    }

    function init() {
      detailCbkCtrl.callback = CallbacksModel.callback;

      getCallback();

      //Can Customizate text-angular
      taOptions.toolbar = [
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
        ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
        ['html', 'insertImage','insertLink', 'insertVideo', 'wordcount', 'charcount']
      ];
    }

    function update(message) {
      $scope.mainCtr.showSpinner();
      return CallbacksModel.updateCallback(message)
        .then(function () {
          $scope.mainCtr.alertSuccess("Changes successfully applied!");
        })
        .catch(function (data) {
          $scope.mainCtr.alertError(data);
        })
        .finally(function () {
          $scope.mainCtr.hideSpinner();
          return getCallback();
        });
    }

    function statusIsOpen() {
      return +detailCbkCtrl.clb.status === 0;
    }

    function statusIsResolve() {
      return +detailCbkCtrl.clb.status === 1;
    }

    function statusIsCancel() {
      return +detailCbkCtrl.clb.status === 2;
    }

    function resolve() {
      detailCbkCtrl.callback.data.status = 1;
      update(detailCbkCtrl.message);
    }

    function cancel() {
      detailCbkCtrl.callback.data.status = 2;
      update(detailCbkCtrl.message);
    }

    function getHistory() {
      CallbacksModel.getHistory(id);
    }


  }
})();