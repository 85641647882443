(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ProfileController
   *
   * @description
   * Contoller - ProfileController
   */
  angular
    .module("locksmithManager")
    .controller('ProfileController', [
      '$scope', '$stateParams',
      function ($scope, $stateParams) {

        var profileCtr = this;

        $scope.$on('$stateChangeSuccess',
          function (event, toState, toParams, fromState, fromParams) {
            // if(angular.isUndefined($stateParams.profile_id))
            // Do something
          });

        profileCtr.init = function () {
          if ($stateParams.profile_id == "me") {
            profileCtr.user = $scope.mainCtr.user;
          }
        };

        profileCtr.init();
      }
    ]);
})();