(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NewSettingsController
   *
   * @description
   * Controller - NewSettingsController
   */
  angular
    .module("locksmithManager")
    .controller('NewServiceAreaController', NewServiceAreaController);

  NewServiceAreaController.$inject = ['$scope', 'ServiceAreaModel'];


  function NewServiceAreaController($scope, ServiceAreaModel) {
    var newCtr = this;

    newCtr.newServiceArea = {};

    newCtr.create = function () {
      ServiceAreaModel.create({
        'area_name': newCtr.newServiceArea.area_name,
        'description': newCtr.newServiceArea.description || null
      })
        .then(function() {
          $scope.srvAreaCtr.init();
          $scope.srvAreaCtr.alertSuccess('Service area created');
        })
        .catch(function(){
          $scope.srvAreaCtr.alertError('Unable to create the service area this time, please try again later');
        });
    };
  }
})();