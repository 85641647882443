(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.SidebarController
   *
   * @description
   * Setup Layout Part - Sidebar
   */
  angular
    .module("locksmithManager")
    .controller('SidebarController', [
      '$scope', 'AuthService',
      function ($scope, authService) {
        $scope.isAdmin = authService.isAdmin();
        $scope.isLimitedSales = authService.isLimitedSales();

        $scope.$on('$includeContentLoaded', function () {
          // init sidebar
          Layout.initSidebar();
        });
      }
    ]);
})();