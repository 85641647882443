(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.ServicesController
   *
   * @description
   * Contoller - Services Controller
   */
  angular
    .module("locksmithManager")
    .controller('ServicesController', [
      '$q', '$scope', 'ServicesModel', '$state', '$stateParams', '$timeout',
      function ($q, $scope, ServicesModel, $state, $stateParams, $timeout) {

        var srvcCtr = this;

        $scope.$state = $state;

        $scope.$on('$stateChangeSuccess',
          function (event, toState, toParams, fromState, fromParams) {
            if (angular.isUndefined(toParams.service_id)) {
              srvcCtr.loadingBaseState = false;
              ServicesModel.resetActiveService();
            }
          });

        srvcCtr.init = function () {
          srvcCtr.loadingBaseState = false;
          srvcCtr.services = ServicesModel.services;

          ServicesModel.getServices();
          ServicesModel.resetActiveService();
          if ($state.current.name != "settings.base.services") {
            srvcCtr.loadingBaseState = true;
            $state.go('settings.base.services');
          }
        };

        srvcCtr.setActiveService = function (service_id) {
          if (srvcCtr.loadingBaseState) {
            return false;
          }
          var deffered = $q.defer();
          ServicesModel.resetActiveService();
          ServicesModel.resetActiveServiceType();

          ServicesModel.getService(service_id).then(function (data) {
            if (data.length === 0) {
              srvcCtr.init();
            }
            else {
              $timeout(function () {
                ServicesModel.setActiveService(data[0]);
                srvcCtr.activeService = ServicesModel.activeService;
                deffered.resolve(true);
              }, 10);
            }
          }, function () {
            srvcCtr.init();
          });

          return deffered.promise;
        };

        srvcCtr.delete = function (service_id) {
          ServicesModel.deleteService(service_id);
          srvcCtr.init();
        };

        // Service type functions

        srvcCtr.setActiveServiceType = function (type_id) {
          var deffered = $q.defer();
          ServicesModel.resetActiveServiceType();

          ServicesModel.getServiceType(type_id).then(function (data) {
            $timeout(function () {
              ServicesModel.setActiveServiceType(data);
              srvcCtr.activeServiceType = ServicesModel.activeServiceType;
              deffered.resolve(true);
            }, 10);
          });

          return deffered.promise;
        };

        srvcCtr.deleteServiceType = function (type_id) {
          ServicesModel.deleteServiceType(type_id).then(function () {
            $state.go("settings.base.services.view", {
              service_id: ServicesModel.activeService.id
            });
          });
        };

        srvcCtr.init();
      }
    ]);
})();