/**
 * Created by nikolay on 3/4/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.CallbacksModel
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('CallbacksModel', CallbacksModel);

  CallbacksModel.$inject = ['$http', '$resource', 'Statuses'];

  function CallbacksModel($http, $resource, Statuses) {
    var model = this,
      URLS = {
        CALLBACKS: '/api/callbacks/:id',
        STATISTICS: '/api/callbacks/dashboard/statistics',
        HISTORY: '/api/callbackHistory/:id',
        DASHBOARD_TABLES: '/api/callbacks/dashboard/callbacks',
        DASHBOARD_HISTORY: '/api/callbacks/dashboard/history'
      },
      Callback = $resource(URLS.CALLBACKS,
        {id: '@id'},
        {
          'create': {method: 'POST'},
          'save': {method: 'PUT'}
        }
      );

    model.callback = {
      data: [],
      history: []
    };

    model.statistics = {
      open: 0,
      resolved: 0,
      cancelled: 0
    };

    model.getStatistics = getStatistics;
    model.getTextStatus = Statuses.entityTextStatus;
    model.getHistory = getHistory;
    model.getDashboardTables = getDashboardTables;
    model.getDashboardHistory = getDashboardHistory;

    function extract(result) {
      return result.data;
    }

    model.create = function (data) {
      var callback = new Callback(data);
      var promise = callback.$create();
      promise.then(function (data) {
        return data;
      });
      return promise;
    };

    model.createFromJob = function (jobData,callbackData) {

      var data ={
        name: jobData.name,
        caller_id: jobData.caller_id,
        phone: jobData.phone,
        job_id: jobData.id,
        comments: callbackData.comments           
      };

      var callback = new Callback(data);
      var promise = callback.$create();
      promise.then(function (data) {
        return data;
      });

      return promise;
    };

    function getStatistics() {
      return $http.get(URLS.STATISTICS)
        .then(function (data) {
          data = extract(data);
          model.statistics.open = data.open;
          model.statistics.resolved = data.resolved;
          model.statistics.cancelled = data.cancelled;
        });
    }

    function removeInternationalCode(phone) {
      if (phone[0] === "+" && phone[1] === "1") {
        return phone.slice(2);
      }
    }

    model.showCallback = function (id) {
      return Callback.get({id: id}).$promise
        .then(function (data) {
          model.callback.data = data;
          model.callback.data.phone = removeInternationalCode(model.callback.data.phone);
          model.callback.data.caller_id = removeInternationalCode(model.callback.data.caller_id);
          return model.callback.data;
        });
    };

    model.updateCallback = function (message) {
      model.callback.data.message = message;
      return model.callback.data.$save();
    };

    model.deleteCallback = function (id) {
      return $http.delete(URLS.CALLBACKS.replace(':id', id));
      //return Callback.delete({id: id});
    };

    function getHistory(id) {
      return $http.get(URLS.HISTORY.replace(':id', id))
        .then(function(data) {
          model.callback.history = extract(data);
          return data;
        });
    }

    function getDashboardTables() {
      return $http.get(URLS.DASHBOARD_TABLES);
    }

    function getDashboardHistory() {
      return $http.get(URLS.DASHBOARD_HISTORY);
    }

    return model;
  }
})();