/**
 * Created by nikolay on 3/11/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.PaymentsModel
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('PaymentsModel', PaymentsModel);

  PaymentsModel.$inject = ['$resource','$http'];
      function PaymentsModel($resource,$http) {
        var model = this,
          URLS = {
            PAYMENTS: '/api/payments/:id',
          },
          Payment = $resource(URLS.PAYMENTS);

        model.payments = {
          data: []
        };

        function cachePayments(data) {
          model.payments.data = data;
          return model.payments;
        }

        model.getAll = function (jobId) {
          return Payment.query({jobId: jobId}).$promise.then(cachePayments);
        };

        model.create = function (payment) {
          return Payment.save(payment).$promise;
        };

        model.remove = function (id) {
          return Payment.remove({id: id}).$promise;
        };

        model.updateByJob = function(datatosubmit) {

            var request = $http({
                  method: 'POST',    
                  data: datatosubmit,
                  url: "/api/payments/update",
            });
              
            return  request.success(
                function (requestResponse) {          
                  return requestResponse.data;  
                  
              }).error(function (requestResponse) { 
                return null;          
              });

        };
      }
})();
