(function() {
    'use strict';
    /**
     * @ngdoc Service
     * @name locksmithManager.Service.UsersModel
     *
     * @description
     */
    angular
        .module("locksmithManager")
        .service('UsersModel', [
            '$http', 'toaster', '$q', 'AuthService', '$resource',
            function($http, toaster, $q, authService, $resource) {
                var model = this,
                    URLS = {
                        FETCH: '/api/users',
                        EDIT: '/api/users',
                        DELETE: '/api/users',
                        POST: '/api/users',
                        USERS: 'api/users/:id',
                        AREA: 'api/users/in-area',
                        UPDATEAVAILABLE: 'api/users/updateTecAvailable'
                    },
                    User = $resource(URLS.USERS, {
                        id: '@id'
                    }, {
                        query: {
                            method: 'GET',
                            transformResponse: transformQueryResponse
                        }
                    }),
                    typeRoleMap = {
                        admin: 0,
                        technician: 1,
                        sale: 2,
                        dispatch: 3
                    };


                function transformQueryResponse(data, headersGetter) {
                    var _response = {};
                    _response.list = angular.fromJson(data);
                    _response.totalCount = parseInt(headersGetter('x-total-count'));
                    return angular.fromJson(_response);
                }
                model.user = {
                    name: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    phone: '',
                    role: '',
                    active: 1,
                    id: null,
                    hourly_rate: '',
                    limited_user: false
                };
                model.selectedUser = null;
                /*model.user = {
                 name: 'Mohan Singh',
                 email: 'singhmoancs@gmail.com',
                 password: '123456789',
                 password_confirmation: '123456789',
                 phone: '1234567899',
                 role: 0,
                 active : 1,
                 id:null
                 };*/
                model.users = {
                    data: []
                };

                model.activeLead = {
                    id: null,
                    user_title: null,
                    user_description: null
                };

                model.query = function(params) {
                    if (params.hasOwnProperty('filter')) {
                        params.filter = typeRoleMap.hasOwnProperty(params.filter) ? typeRoleMap[params.filter] : null;
                    }
                    return User.query(params).$promise;

                };

                function extract(result) {
                    return result.data;
                }

                function cacheUsers(result) {
                    model.users.data = extract(result);
                    return model.users;
                }

                function throwError(message) {
                    toaster.pop('error', "Error", message);
                }

                model.setActiveUser = function(data) {
                    model.activeLead.id = data.id;
                    model.activeLead.user_title = data.user_title;
                    model.activeLead.user_description = data.user_description;

                };

                model.resetActiveUser = function() {
                    model.activeLead.id = null;
                    model.activeLead.user_title = null;
                    model.activeLead.user_description = null;
                };

                model.deleteUser = function(user_id) {
                    return $http.delete(URLS.DELETE + '/' + user_id).then(function(data) {

                        model.getUsers();

                        toaster.pop('success', "Success", "User has been deleted successfully", 2000);

                    }, function error(err) {
                        var errorMessage = err.data.error ? err.data.error : 'Unable to delete user';
                        throwError(errorMessage);
                    });
                };

                model.updateUser = function(user) {

                    var deferred = $q.defer(),
                        user_id;
                    user_id = user.id;
                    $http.put(URLS.POST + '/' + user_id, user).then(function(data) {
                        model.getUsers();
                        deferred.resolve(data);
                    }, function error(err, data) {
                        deferred.reject(err);
                    });
                    return deferred.promise;
                };

                model.updateTechAvailable = function(user_id, available) {
                    var deferred = $q.defer();
                    var tosubmit = {
                        available : available
                    };
                    $http.put(URLS.UPDATEAVAILABLE + '/' + user_id, tosubmit).then(function(data) {
                        model.getUsers();
                        deferred.resolve(data);
                    }, function error(err, data) {
                        deferred.reject(err);
                    });
                    return deferred.promise;
                };

                model.createUser = function(user) {
                    
                    var deferred = $q.defer();
                    $http.post(URLS.POST, user).then(function(data) {
                        model.getUsers();
                        deferred.resolve(data);
                    }, function error(err, data) {
                        deferred.reject(err);
                    });
                    return deferred.promise;
                };

                model.getAvailableTechnicians = function(filters) {
                    
                    var deferred = $q.defer();
                    $http.post(URLS.AREA, {params:filters}).then(function(data) {
                        deferred.resolve(data);
                    }, function error(err, data) {
                        deferred.reject(err);
                    });
                    return deferred.promise;
                };

                model.getUsers = function() {
                    return $http.get(URLS.FETCH).then(cacheUsers);
                };

                model.getAvailableUsersForRules = function(currentUser) {
                    return $http.post('api/users/users-for-rules',{'currentUser':currentUser})
                    .then(function success(data) {
                        model.availableUsersForRules.data =  data.data.list;
                    }, function error(data) {
                    });
                };

                model.getUser = function(user_id) {
                    return $http.get(URLS.FETCH + '/' + user_id);
                };

                /**
                 * findRole Returns user's role based ob roleId
                 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
                 * @param  {[Integre]} roleId user's roleId
                 * @return {[Object]} User's role Object
                 */
                model.findRole = function(roleId) {
                    return _.find(model.userRoles, function(iterator) {
                        return iterator.role === roleId;
                    });
                };

                model.findStatus = function(statusId) {
                    //return statusId ?
                };

                model.isSales = function(role) {
                    return role === 2;
                };

                model.isTechnician = function(role) {
                    return role === 1;
                };
            }
        ]);
})();
