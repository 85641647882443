(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.NotifController
   *
   * @description
   * Setup Layout Part - Quick Sidebar
   */
  angular
    .module("locksmithManager")
    .controller('NotifController', [
      '$scope', 'PhoneService', 'AdWordsService',
      function ($scope, PhoneService, AdWordsService) {
        var notifCtr = this;
        notifCtr.phoneservice = PhoneService;
        notifCtr.processingOrder = PhoneService.processingOrder;
        notifCtr.call_queue = PhoneService.call_queue;

        notifCtr.ongoingCalls = PhoneService.ongoingCalls;
        notifCtr.outgoingCalls = PhoneService.outgoingCalls;

        notifCtr.answerCall = function (callSid, index) {
          var call_answered = null;
          notifCtr.call_queue.data[index].status = 'ANSWERING';
          notifCtr.call_queue.data[index].onHold = false;
          call_answered = angular.copy(notifCtr.call_queue.data[index]);
          PhoneService.getCallFromQueue(callSid)
            .then(function (data) {
                notifCtr.ongoingCalls.data.push(call_answered);
                notifCtr.ongoingCalls.data[notifCtr.ongoingCalls.data.length - 1].status = 'ACTIVE';
                notifCtr.call_queue.data.splice(index, 1);
                PhoneService.updateIncomingQueue();
              },
              function (error) {
              }
            );

        };

        notifCtr.dequeueCall = function (callSid, index) {
          notifCtr.call_queue.data.splice(index, 1);
          PhoneService.dequeueCall(callSid);
        };

        notifCtr.putOnHold = function (callSid, isOutbound, index) {
          if (isOutbound) {
            notifCtr.outgoingCalls.data[index].status = 'PUTTING ON HOLD';
          }
          else {
            notifCtr.ongoingCalls.data[index].status = 'PUTTING ON HOLD';
          }
          PhoneService.putCallOnHold(callSid)
            .then(function (data) {
                if (isOutbound) {
                  notifCtr.outgoingCalls.data[index].onHold = true;
                  notifCtr.outgoingCalls.data[index].status = 'ON HOLD';
                }
                else {
                  notifCtr.ongoingCalls.data[index].onHold = true;
                  notifCtr.ongoingCalls.data[index].status = 'ON HOLD';
                }
              },
              function (error) {
              });
        };

        notifCtr.retrieveCallOnHold = function (callSid, isOutbound, index) {
          if (isOutbound) {
            notifCtr.outgoingCalls.data[index].status = 'RETRIEVING CALL';
          }
          else {
            notifCtr.ongoingCalls.data[index].status = 'RETRIEVING CALL';
          }
          PhoneService.retrieveCallOnHold(callSid)
            .then(function (data) {
                var call_status = 'in-progress';
                try {
                  if (data.data.call_status == 'completed') {
                    call_status = 'completed';
                  }
                }
                catch (err) {
                  // console.log('Reload the screen in case of error');
                }

                if (isOutbound) {
                  if (call_status === 'completed') {
                    //notifCtr.outgoingCall.data = null;
                    notifCtr.outgoingCalls.data.splice(index, 1);
                  }
                  else {
                    //notifCtr.outgoingCall.data.onHold = false;
                    //notifCtr.outgoingCall.data.status = 'ACTIVE';
                    notifCtr.outgoingCalls.data[index].onHold = false;
                    notifCtr.outgoingCalls.data[index].status = 'ACTIVE';
                    PhoneService.requestChildCallSid(notifCtr.outgoingCalls.data[index].CallSid);
                  }
                }
                else {
                  if (call_status === 'completed') {
                    notifCtr.ongoingCalls.data.splice(index, 1);
                  }
                  else {
                    notifCtr.ongoingCalls.data[index].onHold = false;
                    notifCtr.ongoingCalls.data[index].status = 'ACTIVE';
                  }
                }
              },
              function (error) {
              });
        };

        notifCtr.hangUp = function (callSid, type, index) {
          if (type !== 'outgoing') {
            notifCtr.ongoingCalls.data.splice(index, 1);
          }
          else {
            //notifCtr.outgoingCall.data = null;
            notifCtr.outgoingCalls.data.splice(index, 1);
          }

          PhoneService.hangUp(callSid);
        };

        notifCtr.hangUpOnHoldCalls = function (callSid, type, index) {
          if (index !== undefined) {
            if (type !== 'outgoing') {
              notifCtr.ongoingCalls.data.splice(index, 1);
            }
            else {
              //notifCtr.outgoingCall.data = null;
              notifCtr.outgoingCalls.data.splice(index, 1);
            }
          }


          PhoneService.hangUpOnHoldCalls(callSid);
        };
      }
    ]);
})();