(function () {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.NewCbkController
     *
     * @description
     * Controller - NewCbkController
     */
    angular
        .module("locksmithManager")
        .controller('NewCbkController', NewCbkController);

    NewCbkController.$inject = [
        'CallbacksModel', '$rootScope', '$state', '$timeout', 'toaster', 'AuthService'];

    function NewCbkController(CallbacksModel, $rootScope, $state, $timeout, toaster, AuthService) {
        var newCbkCtrl = this,
            id = 0;

        function clearModel() {
            newCbkCtrl.callback = {
                name: '',
                caller_id: '',
                phone: '',
                comments: ''
            };
            newCbkCtrl.same_caller_id = false;
            newCbkCtrl.submitted = false;
        }

        newCbkCtrl.submit = submit;
        newCbkCtrl.changeCallerId = changeCallerId;

        init();

        ///////////////////////////

        function init() {
            clearModel();
        }

        function viewCallback() {
            $state.go('callbacks.detail', {callbackId: id});
        }

        function viewNewCallback() {
            $state.go('callbacks.new');
        }

        function alertSuccess(message, action) {
            toaster.success("Success", message, 2000);
            $timeout(action, 5 * 1000);
        }

        function alertError(message) {
            toaster.error("Error", message, 2000);
        }

        function showSpinner() {
            $rootScope.loadingSpinner = true;
        }

        function hideSpinner() {
            $rootScope.loadingSpinner = false;
        }

        function submit(valid) {
            newCbkCtrl.submitted = true;
            if (valid) {
                if (newCbkCtrl.same_caller_id) {
                    newCbkCtrl.callback.phone = newCbkCtrl.callback.caller_id;
                }

                showSpinner();
                CallbacksModel.create(newCbkCtrl.callback)
                    .then(function (data) {
                        clearModel();
                        id = data.id;
                        if (AuthService.user.limited_user == 1) {
                            alertSuccess("Callback success created!", viewNewCallback);
                        } else {
                            alertSuccess("Callback success created! Now you will redirect to detail page.", viewCallback);
                        }
                    })
                    .catch(function (data, status) {
                        if (status === 422) {
                            alertError("Please check all fields!");
                        }
                        else {
                            if (status === 401) {
                                alertError(data.error);
                            }
                        }
                    })
                    .finally(function () {
                        hideSpinner();
                    });
            }
        }

        function changeCallerId() {
            if (newCbkCtrl.callback.caller_id === "") {
                newCbkCtrl.same_caller_id = false;
            }
        }
    }
})();