/**
 * Created by nikolay on 3/11/15.
 */

(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.NotesModel
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('NotesModel', NotesModel);

  NotesModel.$inject = ['$http', 'toaster'];


  function NotesModel($http, toaster) {
    var model = this,
      URLS = {
        FETCH: 'api/notes/:record_type/record/:record_id',
        CREATE: 'api/notes/:record_type/record/:record_id'
      },
      TYPES = {
        JOB: 0,
        CALLBACK: 1,
        GENERAL_REQUEST: 2,
        COMPLAIN: 3
      };

    model.notes = {
      data: []
    };

    model.createNoteForJob = createNoteForJob;
    model.getNotesForJob = getNotesForJob;

    model.createNoteForCallback = createNoteForCallback;
    model.getNotesForCallback = getNotesForCallback;

    model.createNoteForCallback = createNoteForCallback;
    model.getNotesForCallback = getNotesForCallback;

    model.createNoteForComplain = createNoteForComplain;
    model.getNotesForComplain = getNotesForComplain;

    model.createNoteForGeneralRequest = createNoteForGeneralRequest;
    model.getNotesForGeneralRequest = getNotesForGeneralRequest;

    //////////////////////////////

    function extract(data) {
      return data.data;
    }

    function cacheJobs(data) {
      model.notes.data = extract(data);

      return model.notes;
    }

    function throwError(message) {
      toaster.pop('error', "Error", message);
    }

    function successMessage(message) {
      toaster.pop('success', "Success", message, 2000);
    }

    function handleSuccess(data) {
      successMessage("Note success added!");
      return extract(data);
    }

    function handleError(data) {
      throwError('Sorry, note don\'t add, please try again later');
      return extract(data);
    }

    function createNote(id, data, type) {
      return $http.post(URLS.CREATE
        .replace(':record_type', type.toString())
        .replace(':record_id', id), data).then(handleSuccess, handleError);
    }

    function getNotes(id, type) {
      return $http.get(URLS.FETCH
        .replace(':record_type', type.toString())
        .replace(':record_id', id)).then(cacheJobs);
    }

    function createNoteForJob(jobId, data) {
      return createNote(jobId, data, TYPES.JOB);
    }

    function getNotesForJob(jobId) {
      return getNotes(jobId, TYPES.JOB);
    }

    function getNotesForCallback(id) {
      return getNotes(id, TYPES.CALLBACK);
    }

    function createNoteForCallback(id, data) {
      return createNote(id, data, TYPES.CALLBACK);
    }

    function getNotesForComplain(id) {
      return getNotes(id, TYPES.COMPLAIN);
    }

    function createNoteForComplain(id, data) {
      return createNote(id, data, TYPES.COMPLAIN);
    }

    function getNotesForGeneralRequest(id) {
      return getNotes(id, TYPES.GENERAL_REQUEST);
    }

    function createNoteForGeneralRequest(id, data) {
      return createNote(id, data, TYPES.GENERAL_REQUEST);
    }

  }
})();