(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.AllJobController
     *
     * @description
     * Contoller - AllJobController
     */
    angular
        .module("locksmithManager")
        .controller('AllJobController', AllJobController);

    AllJobController.$inject = ['$scope', 'JobsModel', 'DTOptionsBuilder', 'DTColumnBuilder'];

    function AllJobController($scope, JobsModel, DTOptionsBuilder, DTColumnBuilder) {
        var allJobCtrl = this;

        allJobCtrl.statistics = JobsModel.statistics;

        init();

        allJobCtrl.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: '/api/jobs',
                type: 'GET',
                beforeSend: $scope.jobCtrl.authHeader
            })
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('bFilter', false)
            .withOption('createdRow', $scope.jobCtrl.createdRow)
            .withPaginationType('simple_numbers')
            //.withBootstrap()
            .withOption('order', [3, 'desc'])
            .withLanguage({
                "sProcessing": $scope.mainCtr.processingHtml
            });

        allJobCtrl.dtColumns = [
            DTColumnBuilder.newColumn('record_number').withTitle('Record number')
            .notSortable().withClass('record-number'),

            DTColumnBuilder.newColumn('name').withTitle('Name'),

            DTColumnBuilder.newColumn('phone').withTitle('Phone')
            .renderWith($scope.jobCtrl.phoneFilter).notSortable().withClass('phone'),

            DTColumnBuilder.newColumn('created_at').withTitle('Created')
            .renderWith($scope.jobCtrl.amCalendarFilter),

            DTColumnBuilder.newColumn('status').withTitle('Status')
            .renderWith($scope.jobCtrl.statusHtml).withClass('status'),

            DTColumnBuilder.newColumn(null).withTitle('')
            .notSortable().renderWith($scope.jobCtrl.actionsHtml).withClass('actions')
        ];
        /////////////////////

        function init() {
            JobsModel.getStatistics();
        }
    }
})();
