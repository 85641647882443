(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.SaleReportViewController
     *
     * @description
     * Contoller - SaleReportViewController
     */
    angular
        .module("locksmithManager")
        .controller("SaleReportViewController", [
            "$scope", "$stateParams", 'ReportsModel', 'toaster', 'Spinner',
            function($scope, $stateParams, ReportsModel, toaster, Spinner) {

                var self = this;

                self.filter = {
                    user_id: $stateParams.user_id || null,
                    start_date: $stateParams.start_date || null
                };
                self.report = {
                    creation_date: null,
                    jobs_closed: {},
                    timesheet: [],
                    totals: {},
                    user: {}
                };

                init();

                function init() {
                    getReport();
                }

                function getReport() {
                    Spinner.start();
                    var params = angular.copy(self.filter);
                    ReportsModel.get(params).then(function(reports) {
                        angular.extend(self.report, reports.data);
                        Spinner.stop();
                    }, throwError);
                }

                function throwError(errorResponse) {
                    toaster.pop('error', "Error", errorResponse);
                    Spinner.stop();
                }
            }
        ]);
})();
