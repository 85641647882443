(function () {
  'use strict';
  /**
   * @ngdoc Controller
   * @name locksmithManager.Controller.PhoneSettingsController
   *
   * @description
   * Contoller - PhoneSettingsController
   */
  angular
    .module("locksmithManager")
    .controller('PhoneSettingsController', [
      '$q',
      '$scope',
      '$stateParams',
      'PhoneSettingModel',
      'PhoneService',
      '$state',
      '$timeout',
      'phoneKeyMap',
      function ($q, $scope, $stateParams, PhoneSettingModel, PhoneService, $state, $timeout, phoneKeyMap) {

        var phoneSetCtr = this;

        $scope.$on('$stateChangeSuccess',
          function (event, toState, toParams, fromState, fromParams) {
            if (toState.name != "settings.phone" && angular.isUndefined(phoneKeyMap[toParams.group_id])) {
              phoneSetCtr.loadingBaseState = true;
              PhoneSettingModel.resetPhoneGroup();
              //$state.go('settings.phone');
            }
            else {
              phoneSetCtr.loadingBaseState = false;
            }
          });


        phoneSetCtr.init = function () {
          phoneSetCtr.loadingBaseState = false;
          phoneSetCtr.isPhoneEnabled = PhoneSettingModel.isPhoneEnabled;

          $timeout(function () {
            $scope.mainCtr.promises.checkPhoneEnabled.then(function () {
              if (!phoneSetCtr.isPhoneEnabled.data) {
                $state.go('settings.phone.view', {group_id: 'basic'});
              }
            });
          }, 10);

          PhoneSettingModel.resetPhoneGroup();

          // if ($state.current.name != "settings.phone"){
          //   phoneSetCtr.loadingBaseState = true;
          //   $state.go('settings.phone');
          // }
        };

        phoneSetCtr.togglePhoneEnabled = function (bool) {
          phoneSetCtr.isPhoneEnabled = bool;
        };

        phoneSetCtr.setPhoneGroup = function (group_id) {
          var deffered = $q.defer();

          if (phoneSetCtr.loadingBaseState) {
            deffered.resolve(false);
            return deffered.promise;
          }

          PhoneSettingModel.resetPhoneGroup();

          PhoneSettingModel.getPhoneGroup(group_id).then(function (data) {
            $timeout(function () {
              PhoneSettingModel.setPhoneGroup(data);
              phoneSetCtr.activePhoneGroup = PhoneSettingModel.activePhoneGroup;
              deffered.resolve(true);
            }, 10);
          }, function () {
            phoneSetCtr.init();
          });

          return deffered.promise;
        };

        phoneSetCtr.init();
      }
    ]);
})();