(function() {
    'use strict';
    /**
     * @ngdoc Controller
     * @name locksmithManager.Controller.ConfirmController
     *
     * @description
     * Contoller - ConfirmController
     * 
     * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
     */
    angular
        .module("locksmithManager")
        .controller('ConfirmController', ConfirmController);
    /**
     * $injector
     * lets inject dependecies
     * @type {Array}
     */
    ConfirmController.$injector = ['$modalInstance', 'Options'];
    /**
     * ConfirmController
     */
    function ConfirmController($modalInstance, Options) {
        /**
         * self Holds current instance of Class
         * @private {[Object]}
         */
        var self = this;
        self.options = angular.copy(Options);
        self.confirm = confirmModal;
        self.cancel = cancelModal;

        function confirmModal() {
            $modalInstance.close();
        }

        function cancelModal() {
            $modalInstance.dismiss('cancel');
        }

    }


})();
