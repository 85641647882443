(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name locksmithManager.Service.PhoneService
   *
   * @description
   */
  angular
    .module("locksmithManager")
    .service('LeadsModel', [
      '$http', 'toaster',
      function ($http, toaster) {

        var model = this,
          URLS = {
            FETCH: '/api/leads',
            EDIT: '/api/leads',
            DELETE: '',
            POST: ''
          },
          leads;

        model.leads = {
          data: []
        };
        model.activeLead = {
          id: null,
          lead_title: null,
          lead_description: null
        };


        function extract(result) {
          return result.data;
        }

        function cacheLeads(result) {
          model.leads.data = extract(result);

          return model.leads;
        }

        function throwError(message) {
          toaster.pop('error', "Error", message);
        }

        model.setActiveLead = function (data) {
          model.activeLead.id = data.id;
          model.activeLead.lead_title = data.lead_title;
          model.activeLead.lead_description = data.lead_description;

        };

        model.resetActiveLead = function () {
          model.activeLead.id = null;
          model.activeLead.lead_title = null;
          model.activeLead.lead_description = null;
        };

        model.updateLead = function (editLead) {

          var lead_id = editLead.id;
          return $http.put(URLS.FETCH + '/' + lead_id, editLead).then(function (data) {
            model.getLeads();

            toaster.pop('success', "Success", "Lead updated", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to update the lead at this time, please try again later');

          });
        };

        model.deleteLead = function (lead_id) {
          return $http.delete(URLS.FETCH + '/' + lead_id).then(function (data) {

            model.getLeads();

            toaster.pop('success', "Success", "Lead deleted", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to delete the lead at this time, please try again later');

          });
        };

        model.createLead = function (lead) {

          return $http.post(URLS.FETCH, lead).then(function (data) {
            model.getLeads();

            toaster.pop('success', "Success", "Lead Created", 2000);

          }, function error(err, data) {

            // Throw error;

            throwError('Unable to create a new lead at this time, please try again later');

          });
        };

        model.getLeads = function () {

          return $http.get(URLS.FETCH).then(cacheLeads);
        };

        model.getLead = function (lead_id) {
          return $http.get(URLS.FETCH + '/' + lead_id).then(extract);
        };


      }
    ]);
})();